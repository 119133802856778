import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConsolidationCountriesService } from 'src/app/Services/consolidation-countries.service';
import { AppSettings } from '../App-Settings';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],

})
export class HeaderComponent implements OnInit {

  removeClass:boolean;
  ishome = false;
  innerWidth: any;

  customerUrl = AppSettings.APP_CUSTOMER_URL;
  constructor(private router: Router, private consolidationCountriesService: ConsolidationCountriesService) {
  }

  ngOnInit() {
    this.GetFranchiseeList();
    this.innerWidth = window.innerWidth;
    $.getScript('./assets/js/scripts.js');
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event: NavigationEnd) => {          
      if(event.url == "/" || event.urlAfterRedirects == "/"){
        this.ishome = true;
        if(this.ishome && this.innerWidth <= 991){
          this.removeClass=false;
        }else{
          this.removeClass = true;
        }  
      }else{
        //$("header").addClass("uv-header-white");
        this.ishome = false;
       this.removeClass=false;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

  this.innerWidth = window.innerWidth;
  if(this.ishome && this.innerWidth <= 991){
    this.removeClass=false;
  }else if(!this.ishome){
    this.removeClass = false;
  }else{
    this.removeClass = true;
  }
  
  }

  franchiseeList: any;
  GetFranchiseeList() {
    
    this.consolidationCountriesService.GetFranchiseeList().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != []) {

        this.franchiseeList = response["result"];
        this.franchiseeList.sort((a, b) => a.franchiseName.localeCompare(b.franchiseName)); 
      }
    }, (error) => {
    
      console.error(error);
    })
  }
  getFranchiseDetail(countryName: any){
    this.router.navigate(['/country', countryName]);

  }

}
