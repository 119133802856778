<section class="uv-freedom">
    <div class="container">
      <div [innerHtml]="pageContent?.pageContent"></div>      
      <div class="row">        
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="uv-freedom-box orange-bg">
              <div class="uv-freedom-icon">
                <img src="./assets/images/freedom-1.png" alt="freedom-1">
              </div>
              <div class="uv-freedom-line"> </div>
              <div class="uv-freedom-text"> <h4>{{happyCustomers | number}}</h4><p>Happy Customers </p> </div>
          </div>
        </div>
        <!-- <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="uv-freedom-box blue-bg">
              <div class="uv-freedom-icon">
                <img src="./assets/images/freedom-2.png" alt="freedom-2">
              </div>
              <div class="uv-freedom-line"> </div>
              <div class="uv-freedom-text"> <h4>{{totalPackages | number}}</h4><p>Daily Packages</p> </div>
          </div>
        </div> -->
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="uv-freedom-box yellow-bg">
              <div class="uv-freedom-icon">
                <img src="./assets/images/freedom-4.png" alt="freedom-3">
              </div>
              <div class="uv-freedom-line"> </div>
              <div class="uv-freedom-text"> <h4>{{shippingPartners | number}}</h4><p>Franchisee Partners</p> </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="uv-freedom-box green-bg">
              <div class="uv-freedom-icon">
                <img src="./assets/images/freedom-3.png" alt="freedom-3">
              </div>
              <div class="uv-freedom-line"> </div>
              <div class="uv-freedom-text"> <h4>{{directServices | number}}</h4><p>Countries</p> </div>
          </div>
        </div>
      </div>
    </div>
  </section>