<div class="container">
  <div class="row">
    <div id="Smallchat">
      <div class="Layout Layout-open Layout-expand Layout-right"
        style="background-color: #0229a8;color: rgb(255, 255, 255);opacity: 5;border-radius: 10px;">
        <div class="Messenger_messenger">
          <div class="Messenger_header" style="background-color: #0229a8; color: rgb(255, 255, 255);">
            <img src="./assets/images/logo.png" style="height: 45px;" />
            <h4 class="Messenger_prompt">Kango Support</h4> <span class="chat_close_icon" (click)="closeAlert()"><i
                class="fa fa-close"></i></span>
          </div>
          <div class="Messenger_content" id="layout">
            <div class="Messages" id="msg_list">

              <div class="Messages_list" >
                <ng-container *ngFor="let item of feed | async">
                  <div class="bot_message" *ngIf="item.author.id == 0 && item.text" [innerHTML]="item.text">
                  </div>

                  <div class="bot_message" *ngIf="item.author.id == 0 && item.continue">
                    Has your question been answered?
                  </div>

                  <div class="user_message" *ngIf="item.author.id == 1">{{item.text}}</div>

                  <div class="suggestedActions-container">
                    <div class="suggestedActions" *ngFor="let action of item.suggestedActions"
                      (click)="sendMessage(action.value);item.suggestedActions = []">
                      {{action.value}}
                    </div>
                  </div>
                </ng-container>

                <div class="bot_message" *ngIf="isTyping">
                  Typing...
                </div>

              </div>
            </div>
            <div class="Input Input-blank">
              <input type="text" id="msg_input" class="Input_field" placeholder="Send a message..."
                style="height: 20px;" #msg (keyup.enter)="sendMessage(msg.value);msg.value = ''" />
              <button class="Input_button Input_button-send" (click)="sendMessage(msg.value); msg.value = ''">
                <div class="Icon" style="width: 18px; height: 18px;">
                  <svg width="1em" height="1em" viewBox="0 0 24 24">
                    <defs>
                      <path
                        d="M21.158.222L2.568 11.815c-.8.498-.749 1.215.113 1.591l4.406 1.923 10.395-9.903c1.377-1.296 1.569-1.119.426.395L8.254 18.215v4.927c0 .953.5 1.141 1.113.418l4.473-5.282 5.055 2.207c.861.376 1.643-.092 1.736-1.04l1.81-18.404c.094-.949-.484-1.317-1.283-.819"
                        id="send__a"></path>
                    </defs>
                    <use xlink:href="#send__a" fill-rule="evenodd"></use>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--===============CHAT ON BUTTON STRART===============-->
      <div class="chat_on chat_on_icon">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="68" height="54"
          viewBox="0 0 39 37" class="conversations-visitor-open-icon">
          <defs>
            <path id="conversations-visitor-open-icon-path-10.4330644552498626"
              d="M31.4824242 24.6256121L31.4824242 0.501369697 0.476266667 0.501369697 0.476266667 24.6256121z"></path>
          </defs>
          <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
            <g transform="translate(-1432 -977) translate(1415.723 959.455)">
              <g transform="translate(17 17)">
                <g transform="translate(6.333 .075)">
                  <mask id="conversations-visitor-open-icon-mask-20.4330644552498626" fill="#ffffff">
                    <use xlink:href="#conversations-visitor-open-icon-path-10.4330644552498626"></use>
                  </mask>
                  <path fill="#ffffff"
                    d="M30.594 4.773c-.314-1.943-1.486-3.113-3.374-3.38C27.174 1.382 22.576.5 15.36.5c-7.214 0-11.812.882-11.843.889-1.477.21-2.507.967-3.042 2.192a83.103 83.103 0 019.312-.503c6.994 0 11.647.804 12.33.93 3.079.462 5.22 2.598 5.738 5.728.224 1.02.932 4.606.932 8.887 0 2.292-.206 4.395-.428 6.002 1.22-.516 1.988-1.55 2.23-3.044.008-.037.893-3.814.893-8.415 0-4.6-.885-8.377-.89-8.394"
                    mask="url(#conversations-visitor-open-icon-mask-20.4330644552498626)"></path>
                </g>
                <g fill="#ffffff" transform="translate(0 5.832)">
                  <path
                    d="M31.354 4.473c-.314-1.944-1.487-3.114-3.374-3.382-.046-.01-4.644-.89-11.859-.89-7.214 0-11.813.88-11.843.888-1.903.27-3.075 1.44-3.384 3.363C.884 4.489 0 8.266 0 12.867c0 4.6.884 8.377.889 8.393.314 1.944 1.486 3.114 3.374 3.382.037.007 3.02.578 7.933.801l2.928 5.072a1.151 1.151 0 001.994 0l2.929-5.071c4.913-.224 7.893-.794 7.918-.8 1.902-.27 3.075-1.44 3.384-3.363.01-.037.893-3.814.893-8.414 0-4.601-.884-8.378-.888-8.394">
                  </path>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <!--===============CHAT ON BUTTON END===============-->
      </div>
    </div>
  </div>