import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SitePagesService } from '../Services/site-pages.service';
declare var $: any;
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {
  pageContent: any;
  pageName = "";

  constructor(private sitePagesService: SitePagesService, private router : ActivatedRoute, private meta : Meta, private title : Title) { 
    this.meta.updateTag({ name: 'keywords', content: 'Terms & Conditions, Terms and Codition Kango Express'},'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'We provide one of the best terms and conditions for all.'},'name="description"'); 
  }

  ngOnInit(): void {

    this.getSitePage();
  }

  ngAfterViewInit() {
    
    this.router.fragment.subscribe((fragment: string) => {
      setTimeout(() => {
        if (fragment == "Claim-Policy") {  
          this.title.setTitle("Claim Policy - Kango Express");
          this.meta.updateTag({ name: 'keywords', content: 'Claim Policy, Kango Express, Kango Express Policy, Customer Service, Parcle Policy'},'name="keywords"');
          this.meta.updateTag({ name: 'description', content: 'Our goal is to give the complete shipping service for you, but from time to time, issues occur pending transportation. We have conditions and regulations in place when barriers disturb your package arriving at its destination.'},'name="description"');         
          var el = document.getElementById(fragment);
          var _y = 0;
          while( el && !isNaN( el.offsetTop ) ) {
            _y += el.offsetTop - el.scrollTop;            
            el = el.offsetParent as HTMLElement;
        }
          var headerOffset = 45; 
          var offsetPosition = _y - headerOffset;          
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
      },1000);
      
    })

  }

  getSitePage() {
    this.sitePagesService.getSitePage("Terms-Conditions").subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.pageContent = response["result"];
      }
    }, (error) => {
      console.error(error);
    });
  }
}
