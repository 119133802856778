<div *ngIf="loading" class="wrapperLoader">
    <div class="loader">
    </div>
</div>

<section class="kg-header-block"
    style="background-image: url(./assets/images/banner-3.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-header-block-text">
                    <h1>CUSTOMER FEEDBACK</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Customer Feedback</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="kg-cf-title">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="kg-cf-title-text">
                    <h2>Customer Feedback </h2>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <select class="form-control" (change)="getFeedbacks($event.target.value)">
                        <option value="-1">--All Countries--</option>
                        <option *ngFor="let country of countryList" value="{{country.countryId}}">
                            {{country.countryName}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="kg-customer-feedback">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="row">
                    <ng-container *ngIf="feedbackList.length > 0">
                        <div class="col-md-6" *ngFor="let feedback of feedbackList">
                            <div class="kg-cf-item">
                                <div class="kg-cf-item-hd"> <img src="{{imagesPath+feedback?.country?.flagUrl}}" alt="">
                                    <h4>{{feedback.firstName}},<span>{{feedback.createdDate}}</span> </h4>
                                </div>
                                <div class="kg-cf-item-details">
                                    <p>{{feedback.feedback}}</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>                    
                    <ng-container *ngIf="feedbackList.length == 0">
                        <div class="col-md-12 alert alert-warning">No any feedback in this country.</div>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-5">
                <div class="kg-feedback-feedback">
                    <div class="kg-af-hd">
                        <h2>Submit Your feedback</h2>
                    </div>
                    <div class="kg-af-con">
                        
                        <form [formGroup]="feedbackForm" (ngSubmit)="onFormSubmit(feedbackForm.value)">
                            
                            <div class="form-group">
                                <label>First Name <span>*</span> </label>
                                <input type="text" class="form-control" formControlName="FirstName">
                                <span
                                    *ngIf="feedbackForm?.get('FirstName')?.invalid && (feedbackForm?.get('FirstName')?.touched || feedbackForm?.get('FirstName')?.dirty)"
                                    class="text-danger">
                                    <span *ngIf="feedbackForm?.get('FirstName')?.errors?.required">This field is
                                        required</span>
                                </span>
                            </div>
                            
                            <div class="form-group">
                                <label> Email <span>*</span> </label>
                                <input type="email" class="form-control" formControlName="Email">
                                <span
                                    *ngIf="feedbackForm?.get('Email')?.invalid && (feedbackForm?.get('Email')?.touched || feedbackForm?.get('Email')?.dirty)"
                                    class="text-danger">
                                    <span *ngIf="feedbackForm?.get('Email')?.errors?.required">This field is
                                        required</span>
                                    <span *ngIf="feedbackForm?.get('Email')?.errors?.pattern">Invalid email
                                        address</span>
                                </span>
                            </div>
                            
                            <div class="form-group">
                                <label>Country <span>*</span> </label>
                                <select class="form-control" formControlName="CountryId">
                                    <option value="">Select Country</option>
                                    
                                    <option *ngFor="let country of countryList" value="{{country.countryId}}">
                                        {{country.countryName}}
                                    </option>
                                </select>
                                <span
                                    *ngIf="feedbackForm?.get('CountryId')?.invalid && (feedbackForm?.get('CountryId')?.touched || feedbackForm?.get('CountryId')?.dirty)"
                                    class="text-danger">
                                    <span *ngIf="feedbackForm?.get('CountryId')?.errors?.required">select country</span>
                                </span>
                            </div>
                            
                            <div class="form-group">
                                <label>Customer ID<span> *</span></label>
                                <input type="text" class="form-control" formControlName="AccountNumber">
                                <span
                                    *ngIf="feedbackForm?.get('AccountNumber')?.invalid && (feedbackForm?.get('AccountNumber')?.touched || feedbackForm?.get('AccountNumber')?.dirty)"
                                    class="text-danger">
                                    <span *ngIf="feedbackForm?.get('AccountNumber')?.errors?.required">Enter Customer ID</span>
                                </span>
                            </div>

                            <div class="form-group">
                                <label>Feedback <span>* </span></label>
                                <textarea class="form-control" rows="2" formControlName="Feedback"></textarea>
                                <span
                                    *ngIf="feedbackForm?.get('Feedback')?.invalid && (feedbackForm?.get('Feedback')?.touched || feedbackForm?.get('Feedback')?.dirty)"
                                    class="text-danger">
                                    <span *ngIf="feedbackForm?.get('Feedback')?.errors?.required">This field is
                                        required</span>
                                </span>
                            </div>

                            <div class="form-group text-center">
                                <button class="btn btn-kg-orange" type="submit">Submit Feedback</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>