<div *ngIf="loading" class="wrapperLoader">
    <div class="loader">
    </div>
</div>

<section class="kg-header-block"
    style="background-image: url(./assets/images/sellers-hd-banner.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-header-block-text">
                    <h1>Sellers</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Sellers</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12" [innerHtml]="pageContent?.pageContent | safeHtml">
                <div class="kg-heading-title">
                    <h1>Sellers</h1>
                    <p>Popular online stores</p>
                    <div class="kg-pre">
                        <p>These stores are the most popular among Kango customers. Huge selection, reasonable prices
                            with many deals and sales - everything you need for convenient shopping. Please, note all
                            data provided on this page is for informational purposes only. You can also make orders with
                            the other USA sellers!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-space bg-white" *ngIf="retailersList.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="countries-filter-bg-white">
                    <button class="btn bg-gry active" (click)="allList()">All</button>
                    <ul>
                        <li *ngFor="let alpha of alphaList">
                            <a href="#{{alpha.toLowerCase()}}_section" (click)="filterList(alpha)">{{alpha}}</a>
                        </li>
                    </ul>
                    <button class="btn bg-gry mt-3" id="btn_goto_last" (click)="allList()">Go to Last</button>
                </div>
            </div>

            <div class="col-lg-9">

                <div class="countries-filter-body" *ngIf="isAll" id="list_all">
                    <ng-container *ngFor="let alpha of alphaList">
                        <ng-container *ngIf="allList(alpha).length > 0">
                            <div id="{{alpha.toLowerCase()}}_section" class="section">
                                <div class="countries-head">
                                    <p><span class="first-latter">{{alpha}}</span>{{filteredList.length}} Sellers
                                    </p>
                                </div>
                                <div class="countries-body-retailer">
                                    <ul>
                                        <li *ngFor="let retailer of filteredList" class="col-md-3"><a
                                                href="{{retailer.retailerLink}}"
                                                target="_blank">{{retailer.retailerName}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <div class="countries-filter-body" *ngIf="!isAll">
                    <div id="all_section">
                        <div class="countries-head">
                            <p><span class="first-latter">{{selectedAlpha}}</span>{{filteredList?.length}} Sellers
                            </p>
                        </div>
                        <div class="countries-body-retailer">
                            <ul>
                                <li *ngFor="let retailer of filteredList"><a href="{{retailer.retailerLink}}"
                                        target="_blank">{{retailer.retailerName}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<section class="section-space bg-white" *ngIf="retailersList.length == 0">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-warning text-center">Sellers not found!</div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="section-space bg-sky important-service">
    <div class="container">
        <div class="row my-auto">
            <div class="col-lg-4 ">
                <div class="kg-restricted-img">
                    <img src="./assets/images/basic_info.png" alt="">
                </div>
            </div>
            <div class="col-lg-8">
                <section id="tabs">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-md-12 col-lg-12 ">
                                <nav>
                                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab"
                                            href="#nav-home" role="tab" aria-controls="nav-home"
                                            aria-selected="true">Direct / DHL</a>
                                        <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab"
                                            href="#nav-profile" role="tab" aria-controls="nav-profile"
                                            aria-selected="false">Consolidation / Kango</a>
                                    </div>
                                </nav>
                                <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
                                        aria-labelledby="nav-home-tab">
                                        <div class="announcements-body">
                                            <p>Kango Express offers a Direct Service shipping option to over 200
                                                countries and territories throughout the world. &nbsp;Here are some of
                                                the key features of this service:</p>
                                            <ul>
                                                <li>Packages are sent via courier service (such as DHL) to your home or
                                                    office location.</li>
                                                <li>Transit times vary by country but typically take from 2-5 days.</li>
                                                <li>Pricing varies by country and weight/dimensions. &nbsp;You will need
                                                    to use our cost calculator to estimate the cost of your shipping
                                                    charges.</li>
                                                <li>Once you are ready to ship your packages you would select the
                                                    packages in your account Inbox and complete the Check Out process
                                                    which includes making payment for your shipment. &nbsp;We typically
                                                    ship the packages within 24 hours (excluding weekends) after payment
                                                    has been completed.</li>
                                                <li>Although we offer resizing on Direct Service packages, we may not be
                                                    able to resize the packages as much as we do with consolidation
                                                    packages to ensure they are protected from damage.</li>
                                                <li>You can save money by shipping multiple packages at the same time.
                                                </li>
                                                <li>We provide free coverage for the first $100 of value of your
                                                    packages. &nbsp;You have the option to purchase additional
                                                    protection coverage for your package during the check out process.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="nav-profile" role="tabpanel"
                                        aria-labelledby="nav-profile-tab">
                                        <div class="announcements-body">
                                            <p>Kango Express offers its Consolidation Service in select countries.
                                                &nbsp;Below are some of the key features of this service:</p>
                                            <ul>
                                                <li>We combine the packages of all customers shipping to that country
                                                    into
                                                    single shipments that travel via airfreight. &nbsp;By combining all
                                                    the
                                                    packages, we are able to offer more competitive rates over the
                                                    standard
                                                    Direct service.</li>
                                                <li>Your shipping charges are based on the combined weight of all your
                                                    packages in the shipment.</li>
                                                <li>Shipments are sent on specific days of the week (which do not
                                                    change)
                                                    and may occur 1-2 times per week, depending on the specific country
                                                    and
                                                    volume of packages we have going to that destination.</li>
                                                <li>You must schedule your packages to ship prior to the “Close” time
                                                    indicated on our website to be included in the consolidation
                                                    shipment.
                                                </li>
                                                <li>Once a shipment is made, you will receive an invoice to be paid.
                                                    &nbsp;Your packages will not be available for final delivery or pick
                                                    up
                                                    until payment is received.</li>
                                                <li>Kango offers their consolidation service through either local
                                                    partners
                                                    or Kango franchises in the destination country. &nbsp;This allows us
                                                    to
                                                    offer the added benefit of local customer service and a local office
                                                    location.</li>
                                                <li>Depending on the country, consolidation shipments may be picked up
                                                    at
                                                    designated pick up locations (such as our local office in the
                                                    country)
                                                    or limited local delivery may be offered.</li>
                                                <li>Transit times with our Consolidation service vary by country but
                                                    typically shipments arrive in the local country within 7 working
                                                    days
                                                    from leaving the US Warehouse. &nbsp;The shipment must then clear
                                                    customs (2-3 working days) and then is either available for pick up
                                                    or
                                                    our local office/partners will begin arranging for local delivery.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section> -->

<app-got-question></app-got-question>