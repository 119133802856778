import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";
//const apiLisUrl = "https://localhost:44311/" + "api/";

@Injectable({
  providedIn: 'root'
})
export class FaqsService {

  constructor(private http: HttpClient) { }

  getCategoriesWithFaq(){
    
    let url = `${apiLisUrl}CategoryCollectionWithFaqs?IsActive=true&Type=1&FaqFor=Website`;
    return this.http.get(url);
  }
}
