import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";
//const apiLisUrl = "https://localhost:44311/" + "api/";

@Injectable({
  providedIn: 'root'
})
export class ConsolidationCountriesService {
  
  constructor(private http: HttpClient) { }

  GetFranchiseeList(){
    let url = `${apiLisUrl}GetFranchiseeListFront?IsActive=true`;    
    return this.http.get(url);    
  } 

  GetFranchiseeByCountryId(countryId){
    let url = `${apiLisUrl}GetFranchiseeList?IsActive=true&CountryId=${countryId}`;    
    return this.http.get(url);    
  }
}