import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";

@Injectable({
  providedIn: 'root'
})
export class FreedomService {

  constructor(private http: HttpClient) { }

  getCounts(){
    let url = `${apiLisUrl}Dashboard/GetDashboardCount`;
    return this.http.get(url);
  }
}
