import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from '../Services/country.service';
import { CustomerService } from '../Services/customer.service';
import { FeedbackService } from '../Services/feedback.service';
import { AppSettings } from '../Shared/App-Settings';

@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.css']
})
export class CustomerFeedbackComponent implements OnInit {

  feedbackForm: FormGroup;
  countryList: any;
  feedbackList: any;
  loading: boolean = false;
  imagesPath = AppSettings.API_BASE_URL + "images/country/";

  constructor(private formBuilder: FormBuilder, private countryService: CountryService, private feedbackService: FeedbackService, private tostrService: ToastrService, private customerService: CustomerService, private meta: Meta) {
    this.meta.updateTag({ name: 'keywords', content: 'Customers Feedback, Happy Customers, Custmoers Testimonials, Our Happy Customers, Best Testimonials, Kango Express' }, 'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'At Kango Express, happy customers are important to our success. Special, thankfully we have lots of them, several of whom provided us with a few great testimonials.' }, 'name="description"');
  }

  ngOnInit(): void {
    this.feedbackList = new Array();
    this.getFeedbacks();

    this.getCountryList();

    this.feedbackForm = this.formBuilder.group({
      FirstName: ['', [Validators.required]],
      Email: ['', [Validators.required, Validators.pattern("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")]],
      CountryId: ['', [Validators.required]],
      AccountNumber: ['', [Validators.required]],
      Feedback: ['', [Validators.required]]
    })
  }

  getCountryList() {
    this.countryService.getCountries().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"]) {
        this.countryList = response["result"];
      }
    }, (error: any) => {
      console.error(error);
    })
  }

  onFormSubmit(data) {
    console.log(data);

    this.feedbackForm.markAllAsTouched();
    this.feedbackForm.controls
    if (this.feedbackForm.valid) {
      data.Feedback = data.Feedback.trim();

      this.customerService.getCustomerByCode(data.AccountNumber).subscribe((response: any) => {

        if (response["statusCode"] == 200 && response["result"] && response["result"]["values"].length > 0) {

          this.feedbackService.addFeedback(data).subscribe((response: any) => {
            if (response["statusCode"] == 200) {
              this.tostrService.success(response["message"]);
              this.feedbackForm.reset();
            }
          }, (error) => {
            console.error(error);
          })

        } else {
          this.tostrService.error("Customer Id not found.");
        }
      }, (error) => {
        console.error(error);

      })

      // this.feedbackService.addFeedback(data).subscribe((response:any)=>{
      //   if(response["statusCode"] == 200){
      //     this.tostrService.success(response["message"]);
      //     this.feedbackForm.reset();
      //   }
      // },(error)=>{
      //   console.error(error);    
      // })
    }
  }

  getFeedbacks(countryId: number = -1) {
    this.loading = true;
    this.feedbackService.getFeedbacks(countryId).subscribe((response: any) => {
      if (response["statusCode"] == 200) {
        this.feedbackList = response["result"]["values"];
        this.loading = false;
      }
    }, (error) => {
      console.error(error);
      this.loading = false;
    });
  }

}
