import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './Shared/header/header.component';
import { FooterComponent } from './Shared/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { HttpClientModule } from '@angular/common/http'
import { TestComponent } from './test/test.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ContactUSComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ProhibitedItemsComponent } from './prohibited-items/prohibited-items.component';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { CommonModule } from '@angular/common';
import { RetailersComponent } from './retailers/retailers.component';
import { FaqsComponent } from './faqs/faqs.component';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CostCalculatorComponent } from './cost-calculator/cost-calculator.component';
import { CustomerFeedbackComponent } from './customer-feedback/customer-feedback.component';
import { ServiceRatesComponent } from './service-rates/service-rates.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConsolidationComponent } from './consolidation/consolidation.component';

import { GotQuestionComponent } from './got-question/got-question.component';
import { DirectComponent } from './direct/direct.component';
import { NgxLoadingModule } from 'ngx-loading';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { SafePipe } from './Helpers/safe.pipe';
import { HtmldecoderPipe } from './Helpers/htmldecoder';
import { ReadMoreComponent } from './Helpers/read-more/read-more.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SafeHtmlPipe } from './Helpers/safe-html.pipe';
import { SafeHtmlHomePipe } from './Helpers/safe-html-home.pipe';
import { WhereWeShipComponent } from './where-we-ship/where-we-ship.component';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';


const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    expiryDays : 60,
    domain: 'kangoexpress.com', // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    name : 'Kango-Express',
    secure : true,
    path : 'kangoexpress.com'
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    },    
  },
  content:{
    href : "/privacy-policy"
  },
  theme: 'edgeless',
  type: 'info'
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    SignupComponent,
    TestComponent,
    ContactUSComponent,
    AboutUsComponent,
    ProhibitedItemsComponent,
    ChatBotComponent,
    RetailersComponent,
    FaqsComponent,
    TutorialsComponent,
    CostCalculatorComponent,
    CustomerFeedbackComponent,
    ServiceRatesComponent,
    TermsConditionsComponent,
    ConsolidationComponent,
    GotQuestionComponent,
    DirectComponent,
    PrivacyPolicyComponent,
    SitemapComponent,
    SafePipe,
    HtmldecoderPipe,
    ReadMoreComponent,
    SafeHtmlPipe,
    WhereWeShipComponent    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAlkkTlQ2AbdzJz6Xet8odFuub27NaSpMU",
      libraries: ["places"]
    }),
    InfiniteScrollModule,
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true,
    }),
    NgxIntlTelInputModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  exports : [GotQuestionComponent],
  providers: [  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
