import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConsolidationCountriesService } from '../Services/consolidation-countries.service';
import { CountryService } from '../Services/country.service';
import { SitePagesService } from '../Services/site-pages.service';
import { AppSettings } from '../Shared/App-Settings';
declare var $: any;

@Component({
  selector: 'app-where-we-ship',
  templateUrl: './where-we-ship.component.html',
  styleUrls: ['./where-we-ship.component.css']
})
export class WhereWeShipComponent implements OnInit {

  imagesPath = AppSettings.API_BASE_URL+"images/country/";
  
  public loading = false;
  alphaList = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  selectedAlpha: any = "All";
  isAll :boolean =true;
  directShipmentCountriesList: any;
  filteredList: any;
  base_url = AppSettings.API_BASE_URL;
  pageName: string = "directshipment";
  pageContent: any;

  pageName1: string = "basicInformation";  
  basicInfoContent: any;

  // For Consolidation

  firstCountryId: number;
  firstFranchisee: number;
  franchiseContactDetails: any;
  franchiseeList: any;
  franchise: any;
  isScroll: boolean = false;


  constructor(private countryService: CountryService,private sitePagesService: SitePagesService, private consolidationCountriesService: ConsolidationCountriesService, private sanitizer: DomSanitizer, private route:Router, private meta:Meta) {     
    this.meta.updateTag({ name: 'keywords', content: 'Multi-Country Consolidation Services Provider, Consolidation Services, New Items, Transport Agents & Bulk Carriers, DHL Courier Services, Azerbaijan, Cambodia, Kango Express Kenya, Malaysia, Mongolia, Philippines, & Thailand, Kango Express Services, Kango Express, Direct Shipment, Direct Service Shipping, Direct Shipping Services, Direct Service Package, global e-commerce, API technology, Express e-Commerce, Direct Shipment from DHL' }, 'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'Kango Express offers consolidation service available in many countries. Get more information by visiting our website. We provide a Direct Service Shipment option to over 200 countries and territories throughout the world.' }, 'name="description"');
  }

  ngOnInit(): void {
    this.getSitePage();
    this.getSitePageBasicInfo();
    this.directShipmentCountriesList = new Array();
    this.getDirecShipmentCountries();
    this.GetFranchiseeList();
  }

  getDirecShipmentCountries() {

    this.loading = true;
    this.countryService.getDirectShipmentCountries().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"]["values"] != []) {
        this.directShipmentCountriesList = response["result"]["values"];
        this.directShipmentCountriesList.sort((a,b)=> (a.countryName > b.countryName) ? 1 : -1);
        $.getScript('./assets/js/scripts.js');       

      }
    },(error)=>{
      this.loading = false;
      console.error(error);      
    })
  }

  filterList(alpha: any) {
    this.isAll = false;
    this.selectedAlpha = alpha;
      this.filteredList = this.directShipmentCountriesList.filter((country) => {
        return country.countryName.charAt(0).toLowerCase() == alpha.toLowerCase();
      });
  }

  allList(alpha = 'all'){
    this.isAll = true;
      this.filteredList =  this.directShipmentCountriesList.filter((country)=>{
        return country.countryName.charAt(0).toLowerCase() == alpha.toLowerCase();
      });
      return this.filteredList;
    }

    getSitePage() {    
      this.sitePagesService.getSitePage(this.pageName).subscribe((response: any) => {
        if (response["statusCode"] == 200 && response["result"] != null) {
          this.pageContent = response["result"];
                  
        }
      }, (error) => {      
        console.error(error);
  
      });
    }

    // Get Consolidation
    GetFranchiseeList() {
      this.loading = true;
      this.consolidationCountriesService.GetFranchiseeList().subscribe((response: any) => {
        if (response["statusCode"] == 200 && response["result"] != []) {
  
          this.franchiseeList = response["result"];
          this.franchiseeList.sort((a, b) => a.franchiseName.localeCompare(b.franchiseName));

          setTimeout(() => { this.loading = false; }, 2000);
        }
      }, (error) => {
        this.loading = false;
        console.error(error);
      })
    }
    getFranchiseDetail(countryName: any){
      console.log(countryName);
      
      this.route.navigate(['/country', countryName]);

    }

    getSitePageBasicInfo() {    
      this.sitePagesService.getSitePage(this.pageName1).subscribe((response: any) => {
        if (response["statusCode"] == 200 && response["result"] != null) {
          this.basicInfoContent = response["result"];
                  
        }
      }, (error) => {      
        console.error(error);
  
      });
    }

}

