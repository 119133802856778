<div *ngIf="loading" class="wrapperLoader">
    <div class="loader">
    </div>
</div>

<section class="kg-header-block"
    style="background-image: url(./assets/images/banner-3.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-header-block-text">
                    <h1>Tutorial</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Tutorial</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="kg-cf-title">
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <div class="kg-cf-title-text">
                    <h2>Tutorial</h2>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <select class="form-control" (change)="getTutorialByCountry($event.target.value)">
                        <option value="-1">--All Countries--</option>
                        <option *ngFor="let country of countryList" value="{{country.countryId}}">
                            {{country.countryName}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="kg-tutorial">
    <div class="container">
        <div class="row">
            <div class="col-md-6" *ngFor="let tutorial of tutorialList">
                <div class="kg-tutorial-item">
                    <div class="kg-tutorial-hd">
                        <h4>{{tutorial.title}}</h4>
                        <div class="kg-tutorial-date">
                            <ul>
                                <li> <svg id="Capa_1" enable-background="new 0 0 512 512" height="512"
                                        viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path
                                                d="m512 169c0-33.41 0-56.783 0-59 0-24.813-20.187-45-45-45h-6v55c0 8.284-6.716 15-15 15s-15-6.716-15-15c0-16.839 0-63.232 0-80 0-8.284-6.716-15-15-15s-15 6.716-15 15v25h-100v55c0 8.284-6.716 15-15 15s-15-6.716-15-15c0-16.839 0-63.232 0-80 0-8.284-6.716-15-15-15s-15 6.716-15 15v25h-100v55c0 8.284-6.716 15-15 15s-15-6.716-15-15c0-16.839 0-63.232 0-80 0-8.284-6.716-15-15-15s-15 6.716-15 15v25h-36c-24.813 0-45 20.187-45 45v59z" />
                                            <path
                                                d="m0 199v243c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 0-6.425 0-146.812 0-243-9.335 0-506.836 0-512 0zm144 208h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm128 128h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm128 128h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
                                        </g>
                                    </svg> {{tutorial.createdDate}} </li>
                                <!-- <li> <svg viewBox="-42 0 512 512.002" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="m210.351562 246.632812c33.882813 0 63.222657-12.152343 87.195313-36.128906 23.972656-23.972656 36.125-53.304687 36.125-87.191406 0-33.875-12.152344-63.210938-36.128906-87.191406-23.976563-23.96875-53.3125-36.121094-87.191407-36.121094-33.886718 0-63.21875 12.152344-87.191406 36.125s-36.128906 53.308594-36.128906 87.1875c0 33.886719 12.15625 63.222656 36.132812 87.195312 23.976563 23.96875 53.3125 36.125 87.1875 36.125zm0 0" />
                                        <path
                                            d="m426.128906 393.703125c-.691406-9.976563-2.089844-20.859375-4.148437-32.351563-2.078125-11.578124-4.753907-22.523437-7.957031-32.527343-3.308594-10.339844-7.808594-20.550781-13.371094-30.335938-5.773438-10.15625-12.554688-19-20.164063-26.277343-7.957031-7.613282-17.699219-13.734376-28.964843-18.199219-11.226563-4.441407-23.667969-6.691407-36.976563-6.691407-5.226563 0-10.28125 2.144532-20.042969 8.5-6.007812 3.917969-13.035156 8.449219-20.878906 13.460938-6.707031 4.273438-15.792969 8.277344-27.015625 11.902344-10.949219 3.542968-22.066406 5.339844-33.039063 5.339844-10.972656 0-22.085937-1.796876-33.046874-5.339844-11.210938-3.621094-20.296876-7.625-26.996094-11.898438-7.769532-4.964844-14.800782-9.496094-20.898438-13.46875-9.75-6.355468-14.808594-8.5-20.035156-8.5-13.3125 0-25.75 2.253906-36.972656 6.699219-11.257813 4.457031-21.003906 10.578125-28.96875 18.199219-7.605469 7.28125-14.390625 16.121094-20.15625 26.273437-5.558594 9.785157-10.058594 19.992188-13.371094 30.339844-3.199219 10.003906-5.875 20.945313-7.953125 32.523437-2.058594 11.476563-3.457031 22.363282-4.148437 32.363282-.679688 9.796875-1.023438 19.964844-1.023438 30.234375 0 26.726562 8.496094 48.363281 25.25 64.320312 16.546875 15.746094 38.441406 23.734375 65.066406 23.734375h246.53125c26.625 0 48.511719-7.984375 65.0625-23.734375 16.757813-15.945312 25.253906-37.585937 25.253906-64.324219-.003906-10.316406-.351562-20.492187-1.035156-30.242187zm0 0" />
                                    </svg> {{tutorial.createdBy}} </li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="kg-tutorial-details">
                        <p [innerHtml]="tutorial.description"></p>
                        <!-- <app-read-more  [content]="tutorial.description" [limit]="150" [completeWords]="false">
                        </app-read-more> -->

                    </div>
                    <div class="kg-tutorial-footer">
                        <ul class="tut-img">
                            <ng-container *ngFor="let document of tutorial.tutorialDocuments">
                                <li *ngIf="document.mediaType == mediaType.Pdf">
                                    <a href="{{document.url}}" target="_blank">
                                        <img src="./assets/images/pdf-file.png">
                                    </a>
                                </li>
                                <li *ngIf="document.mediaType == mediaType.Video">
                                    <a href="{{document.url}}" target="_blank">
                                        <img src="./assets/images/video-marketing.png">
                                    </a>
                                </li>
                                <li *ngIf="document.mediaType == mediaType.Image">
                                    <a href="{{document.url}}" target="_blank">
                                        <img src="./assets/images/image.png">
                                    </a>
                                </li>
                                <li *ngIf="document.mediaType == mediaType.Other">
                                    <a href="{{document.url}}" target="_blank">
                                        <img src="./assets/images/other.png">
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>