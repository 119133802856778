import { AfterViewChecked, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, Subject, merge, from } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import { ChatService } from '../Services/chat.service';
import swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.css'],

})
export class ChatBotComponent implements OnInit, AfterViewChecked {

  isChatted: boolean = false;
  
  isTyping: boolean = false;
  clear = false;
  msg: any;
  public feed: Observable<any[]>;

  public readonly user: any = {
    id: 1
  };

  public readonly bot: any = {
    id: 0
  };

  private local: Subject<any> = new Subject<any>();
  hello: any;

  constructor(private chatService: ChatService) {
  }

  sendMessage(message: string): void {
    if (message.trim().length > 0) {

      this.local.next({
        text: message,
        author: this.user
      });

      this.chatService.submit(message);

      this.local.next({
        author: this.bot,
        typing: true
      });
      this.isTyping = true;
    }
  }

  ngOnInit(): void {
    this.isChatted = false;
    $.getScript('./assets/js/scripts.js');
    this.msg = document.getElementById("msg_list");
    this.hello = {
      author: this.bot,
      suggestedActions: this.chatService.categoryList,
      text: "What topic is your question regarding ?"
    };

    let hello1 = {
      author: this.bot,
      text: "Do you have any questions? We would like to support you!"
    };

    this.feed = merge(
      from([hello1]),
      from([this.hello]),
      this.local,
      this.chatService.responses.pipe(
        map((response: any) => (
          this.isChatted = true,
          this.isTyping = response.typing,
          {
            author: this.bot,
            text: response.text,
            suggestedActions: response.suggestedActions,
            continue: response.continue,
            timestamp: response.timestamp
          }))
      )
    ).pipe(
      scan((acc: any[], x: any) => [...acc, x], [])
    )
  }

  closeAlert() {
    if (this.isChatted) {
      swal.fire({
        title: "Confirm Exit?",
        text: "On exiting, all chat history will be cleared",
        showConfirmButton: true,
        confirmButtonText: 'Confirm',
        showCancelButton: false,
        showCloseButton: true,
        target: document.getElementById('layout'),
        backdrop: false,
        icon: 'question',

      }).then((result) => {
        if (result.value) {
          this.ngOnInit();
          $(".Layout").hide();
          $(".chat_on").show(300);
        }
      });
    }else{
          $(".Layout").hide();
          $(".chat_on").show(300);
    }
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.msg.scrollTop = this.msg.scrollHeight;
  }
}