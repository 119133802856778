import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ProhibitedItemsService } from '../Services/prohibited-items.service';
import { SitePagesService } from '../Services/site-pages.service';

@Component({
  selector: 'app-prohibited-items',
  templateUrl: './prohibited-items.component.html',
  styleUrls: ['./prohibited-items.component.css']
})
export class ProhibitedItemsComponent implements OnInit {

  prohibitedItemsList : any;
  public loading = false;
  categories : any;
  selectedCategory : any;

  page1Content: any;
  page2Content: any;
  page3Content: any;
  page4Content: any;
  page1Name: string = "ProhibitedItemsTitle";
  page2Name: string = "ProhibitedItemsContent";
  page3Name: string = "ProhibitedItemsMongolia";  
  page4Name: string = "RestrictedItemsAtOurWarehouse";  
  
  constructor(private prohibitedItemsService: ProhibitedItemsService, private sitePagesService : SitePagesService, private meta : Meta) { 
    this.meta.updateTag({ name: 'keywords', content: 'Prohibited items, restricted items, legal restrictions, restricted in order, Restricted items EN, Restricted items AZ, Restricted items RU, Restricted items Mongolia'},'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'Kango Express can not deliver items that have legal restrictions or that are restricted for safety reasons. Prohibited and restricted items for international shipping based on import and export locations.'},'name="description"'); 
  }

  ngOnInit(): void {
    this.getSitePage1();
    this.getSitePage2();
    this.getSitePage3();
    this.getSitePage4();
    this.getProhibitedItemsCategories();
  }

  getProhibitedItems(category: any){
    
    this.selectedCategory = category.categoryName;
    this.prohibitedItemsList = [];
    this.prohibitedItemsService.getProhibitedItems(category.categoryId).subscribe((response: any)=>{
      
      if(response["statusCode"] == 200 && response["result"]){              
        this.prohibitedItemsList = response["result"]["value"];                      
      }
    }, (error: any)=>{
      this.loading = false;      
    })
  }
  
  getProhibitedItemsCategories(){
    this.loading = true;
    this.prohibitedItemsService.getProhibitedItemCategories().subscribe((response: any)=>{
      if(response["statusCode"] == 200){
        this.categories = response["result"]["values"];        
        setTimeout(()=>{this.loading = false}, 2000);
      }
    }, (error: any)=>{
      console.error(error);      
      this.loading = false;      
    })    
  }

  getSitePage1() {
    this.sitePagesService.getSitePage(this.page1Name).subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.page1Content = response["result"];
      }
    }, (error) => {
      console.error(error);
    });
  }

  getSitePage2() {
    this.sitePagesService.getSitePage(this.page2Name).subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.page2Content = response["result"];
      }
    }, (error) => {
      console.error(error);
    });
  }

  getSitePage3() {
    this.sitePagesService.getSitePage(this.page3Name).subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.page3Content = response["result"];
      }
    }, (error) => {
      console.error(error);
    });
  }

  getSitePage4() {
    this.sitePagesService.getSitePage(this.page4Name).subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.page4Content = response["result"];
      }
    }, (error) => {
      console.error(error);
    });
  }

}