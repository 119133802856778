import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";
//const apiLisUrl = "https://localhost:44311/" + "api/";

@Injectable({
  providedIn: 'root'
})
export class SitePagesService {

  constructor(private http: HttpClient) { }

  getSitePage(pageNameNormalised : string){
    let url = `${apiLisUrl}SitePages/${pageNameNormalised}`;
    return this.http.get(url);
  }
}