import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.css']
})
export class ReadMoreComponent implements OnInit {

  @Input() content: string;
  @Input() limit: number;
  @Input() completeWords: boolean;

  isContentToggled: boolean;
  nonEditedContent: string;
  isMore : boolean = false;

  constructor() { }

  ngOnInit(): void {
    
    this.nonEditedContent = this.content;
    this.content = this.formatContent(this.content);
  }

  // ngOnInit() {
  
  //   this.nonEditedContent = this.content;
  //   this.content = this.formatContent(this.content);
  // }

  toggleContent() {
    this.isContentToggled = !this.isContentToggled;
    this.content = this.isContentToggled ? this.nonEditedContent : this.formatContent(this.content);
  }

  formatContent(content: string) {
    if(content.length > this.limit){
      this.isMore = true;
    }
    if (this.completeWords) {
      this.limit = content.substr(0, this.limit).lastIndexOf(' ');
    }
    
    
    
    return `${content.substr(0, this.limit)}`;
  }

}
