  <!-- Login modal -->
  <div class="sign-up-modal modal fade" id="user_login_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Log In </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="signup-modal-con">
            <form method="POST" action="" name="user_login" id="user_login" aria-label="Login" novalidate="novalidate">
                <input type="hidden" name="_token" value="">
                <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                      <input type="email" name="email" id="email" class="form-control" placeholder="Email Address">
                      <span class="text-danger" id="error-email"></span>
                    </div>
                  </div>
                <div class="col-md-12">
                    <div class="form-group">
                      <input type="password" name="password" id="password" class="form-control" placeholder="Password">
                        <span class="text-danger" id="error-password"></span>
                    </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <button type="submit" class="login-btn btn-lg btn-block">Log In</button>
                  </div>
                </div>	
              </div>    
            </form>
            <div class="row">
              <div class="col-md-6">
                <label class="oepcheckbox">
                  Remember Me
                  <input type="checkbox" name="remember" id="remember">
                  <span class="checkmark"></span>
                </label>
              
              </div>
              <!-- <div class="col-md-6 pull-right">
                <div class="signupmodal-Policytext">
                 
                  <a href="javascript:void(0)" style="float:right; font-size: 14px;" data-toggle="modal" data-target="#Forget-Password" onclick="OpenForgetPasswordModalFunction()"> Forget Password</a>
                </div>
              </div> -->
            </div>
            <!-- <div class="row">
              <div class="col-md-12">
                <div class="or-line"> 
                  <h3>or</h3>
                </div>
              </div>
            </div> -->
            <!-- <div class="row clearfix">
              <div class="col-md-12">
                <div class="signupmodal-Policytext">
                  <h3>Don`t have an account?<a href="javascript:void(0)" data-toggle="modal" data-target="#user_sign_modal" onclick="hideLoginModalFunction()"> Sign Up </a></h3>
                </div>
              </div>
            </div> -->
          </div>    
        </div>
      </div>
    </div>
  </div>