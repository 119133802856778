import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RetailersService } from '../Services/retailers.service';
import { SitePagesService } from '../Services/site-pages.service';
declare var $: any;

@Component({
  selector: 'app-retailers',
  templateUrl: './retailers.component.html',
  styleUrls: ['./retailers.component.css']
})
export class RetailersComponent implements OnInit {

  public loading = false;
  retailersList: any;

  alphaList = ["#","A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  filteredList: any;
  selectedAlpha: any = "All";
  
  isAll :boolean =true;
  pageContent: any;
  pageName: string = "sellers";

  constructor(private retailersService: RetailersService, private sitePagesService: SitePagesService, private meta : Meta) {
    this.meta.updateTag({ name: 'keywords', content: ''},'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'Shop from the well known US retailer shops online.'},'name="description"'); 
  }

  ngOnInit(): void {
    this.getSitePage();
    this.retailersList = new Array();
    this.getRetailers();
    
  }

  getRetailers() {
    this.loading = true;
    this.retailersService.getRetailers().subscribe((response: any) => {
      if (response["statusCode"] == 200) {
        this.retailersList = response["result"]["values"];
        this.retailersList.sort((a,b)=> (a.retailerName > b.retailerName) ? 1 : -1);
        $.getScript('./assets/js/scripts.js');
        setTimeout(() => { this.loading = false; }, 2000);
      }
    }, (error) => {
      this.loading = false;
      console.error(error);
    })
  }

  filterList(alpha: any) {
    this.isAll = false;
    this.selectedAlpha = alpha;
    this.filteredList = null;
      this.filteredList = this.retailersList.filter((retailer) => {
        if(alpha == '#'){
          return !isNaN(retailer.retailerName.charAt(0));
        }        
        return retailer.retailerName.charAt(0).toLowerCase() == alpha.toLowerCase();
      });
  }

  allList(alpha = 'all'){
    this.isAll = true;
    if(alpha == "#"){
      this.filteredList =  this.retailersList.filter((retailer)=>{
        return !isNaN(retailer.retailerName.charAt(0));
      })
    }else{
      this.filteredList =  this.retailersList.filter((retailer)=>{
        return retailer.retailerName.charAt(0).toLowerCase() == alpha.toLowerCase();
      })
    }
    return this.filteredList;
  }

  getSitePage() {
    this.sitePagesService.getSitePage(this.pageName).subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.pageContent = response["result"];
      }
    }, (error) => {
      console.error(error);
    });
  }
}