import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TutorialMediaType } from '../core/Enum';

import { CountryService } from '../Services/country.service';
import { TutorialService } from '../Services/tutorial.service';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.css']
})

export class TutorialsComponent implements OnInit {

  countryList: any;
  tutorialList: any;
  loading = false;
  mediaType = TutorialMediaType;
  constructor(private tutorialService: TutorialService, private countryService: CountryService, private meta : Meta) { 
    this.meta.updateTag({ name: 'keywords', content: 'Tutorials, Services Tutorial, Registration Process Tutorial, Prohibited Items Tutorial, Using Knago Account Tutorial, Ship Packages Tutorial. Kango Express Direct Service Tutorial, Kango Express Consolidation Tutorial, Package Receiving Pre-Alert Tutorial, Refer-A-Friend Program Tutorial, Package Return, Forward or Discard Tutorial'},'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'You need to review all the videos and document tutorials that Kango Express has available to guide you by using our service and maximizing its features and benefits.'},'name="description"'); 
  }

  ngOnInit(): void {
    this.getCountryList();
    this.getAllTutorials();
  }

  getCountryList() {
    this.countryService.getCountries().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"]) {

        this.countryList = response["result"];
      }
    }, (error: any) => {
    })
  }

  getAllTutorials() {
    this.loading = true;
    this.tutorialService.getTutorials().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"]["values"] != []) {
        this.tutorialList = response["result"]["values"];
        setTimeout(() => { this.loading = false }, 2000);
      }
      
    }, (error) => {
      this.loading = false;
      console.error(error);
    })
  }

  getTutorialByCountry(countryId: string) {
    this.loading = true;
    this.tutorialService.getTutorialsByCountry(countryId).subscribe((response) => {
      if (response["statusCode"] == 200 && response["result"]["values"] != []) {
        this.tutorialList = response["result"]["values"];
        setTimeout(() => { this.loading = false; }, 2000);
      }
    }, (error) => {
      this.loading = false;
      console.error(error);  
    })
  }
}
