<div *ngIf="loading" class="wrapperLoader">
    <div class="loader">
    </div>
</div>

<section class="kg-header-block"
    style="background-image: url(./assets/images/contact-header-bg.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-header-block-text">
                    <h1>contact us</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-heading-title">
                    <h1>Contact Us</h1>
                    <!-- <p>Lorem Ipsum is simply dummy text</p> -->
                </div>
            </div>
        </div>
    </div>
</section>
<section class="kg-contact  section-space">
    <div class="container">
        <div class="row d-flex">
            <div class="col-lg-3 col-md-3">
                <div class="kg-contact-info" [innerHtml]="pageContent?.pageContent | safeHtml">
                    <!-- <ul>
                        <li>
                            <div class="d-flex">
                                <span>
                                    <svg data-cwssvg-init="" class="023-truck-3.svg" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        x="0px" y="0px" viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;"
                                        xml:space="preserve">
                                        <path class="cwssvgi_0"
                                            d="M392,387.81c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S405.255,387.81,392,387.81z M392,419.81 c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S396.418,419.81,392,419.81z">
                                        </path>
                                        <circle class="cwssvgi_1" cx="208" cy="419.81" r="16"></circle>
                                        <circle class="cwssvgi_2" cx="112" cy="419.81" r="16"></circle>
                                        <path class="cwssvgi_3"
                                            d="M479.752,282.586c0.016-0.28,0.016-0.56,0-0.84l-29.416-110.32c4.194-1.225,6.602-5.618,5.377-9.813 c-0.032-0.111-0.067-0.222-0.105-0.331l-8-24c-1.09-3.274-4.157-5.48-7.608-5.472H336v-48c0-4.418-3.582-8-8-8h-32 c-4.418,0-8,3.582-8,8s3.582,8,8,8h24v248H32v-248h40c4.418,0,8-3.582,8-8s-3.582-8-8-8H24c-4.418,0-8,3.582-8,8v256H8 c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h24v24c0,4.418,3.582,8,8,8h24c-0.104,26.615,21.388,48.276,48.003,48.38 c25.863,0.101,47.042-20.191,48.315-45.76c0.238,4.548,1.125,9.137,2.739,13.645c8.91,24.892,36.312,37.849,61.204,28.939 c19.082-6.83,31.795-24.937,31.739-45.204h80.64c4.391,30.574,32.736,51.8,63.311,47.409c24.574-3.529,43.88-22.836,47.409-47.409 H472c4.418,0,8-3.582,8-8v-128C479.954,283.395,479.871,282.986,479.752,282.586z M32,371.81H16v-16h16V371.81z M461.6,275.81 h-57.32L392,257.41v-85.6h41.848L461.6,275.81z M112,451.81c-17.673,0-32-14.327-32-32c0-17.673,14.327-32,32-32s32,14.327,32,32 C144,437.483,129.673,451.81,112,451.81z M208,451.81c-17.673,0-32-14.327-32-32c0-17.673,14.327-32,32-32s32,14.327,32,32 C240,437.483,225.673,451.81,208,451.81z M392,451.81c-22.091,0-40-17.909-40-40c0-22.091,17.909-40,40-40 c22.091,0,40,17.909,40,40C431.974,433.89,414.08,451.783,392,451.81z M464,339.81h-24v-24h24V339.81z M464,299.81h-32 c-4.418,0-8,3.582-8,8v40c0,4.418,3.582,8,8,8h32v48h-16.64c-4.391-30.575-32.736-51.8-63.311-47.409 c-24.574,3.529-43.88,22.835-47.409,47.409H253.2c-8.91-24.893-36.312-37.849-61.204-28.939 c-18.735,6.706-30.704,23.887-31.683,42.589c-1.315-25.352-22.232-45.553-47.932-45.653c-20.443-0.08-38.713,12.747-45.58,32.003 H48v-48h280c4.418,0,8-3.582,8-8v-200h98.232l2.664,8H384c-4.418,0-8,3.582-8,8v96c0.003,1.58,0.47,3.124,1.344,4.44l16,24 c1.484,2.225,3.982,3.56,6.656,3.56h64V299.81z">
                                        </path>
                                        <path class="cwssvgi_4"
                                            d="M184,11.81c-53.019,0-96,42.981-96,96c0,4.056,1.024,100.336,90.344,189.656c3.124,3.123,8.188,3.123,11.312,0 C278.976,208.146,280,111.866,280,107.81C280,54.79,237.019,11.81,184,11.81z M184,280.354c-78.784-82.776-80-171.576-80-172.544 c0-44.183,35.817-80,80-80s80,35.817,80,80C264,108.714,263.072,197.298,184,280.354z">
                                        </path>
                                        <path class="cwssvgi_5"
                                            d="M184,59.81c-26.499,0.026-47.974,21.501-48,48c0,26.51,21.49,48,48,48c26.51,0,48-21.49,48-48S210.51,59.81,184,59.81z M184,139.81c-17.673,0-32-14.327-32-32s14.327-32,32-32s32,14.327,32,32S201.673,139.81,184,139.81z">
                                        </path>
                                    </svg>
                                </span>
                                <div class="cnt-deatil">
                                    <h6>Address</h6>
                                    <p>1202 Some St., <br> Some Country, 12345</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="d-flex">
                                <span>
                                    <svg data-cwssvg-init="" class="046-24-hours.svg" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        x="0px" y="0px" viewBox="0 0 512.004 512.004"
                                        style="enable-background:new 0 0 512.004 512.004;" xml:space="preserve">
                                        <path class="cwssvgi_0"
                                            d="M204.041,269.475l33.557-22.374c11.738-7.774,18.726-20.819,18.692-34.881c0-23.126-18.812-41.946-41.946-41.946h-10.081 c-23.126,0-41.946,18.812-41.946,41.946h17.086c0-13.703,11.157-24.86,24.86-24.86h10.081c13.703,0,24.86,11.157,24.86,24.886 c0.017,8.321-4.109,16.027-11.063,20.64l-33.574,22.383c-20.179,13.455-32.241,35.983-32.25,60.245 c0,2.264,0.897,4.442,2.503,6.04c1.598,1.606,3.776,2.503,6.04,2.503h85.43v-17.095h-76.229 C182.428,291.773,191.022,278.164,204.041,269.475z">
                                        </path>
                                        <path class="cwssvgi_1"
                                            d="M283.346,272.79l15.565-101.218l-16.89-2.597l-17.086,111.059c-0.384,2.469,0.333,4.972,1.956,6.869 s3.99,2.982,6.484,2.982h102.516V272.79H283.346z">
                                        </path>
                                        <rect class="cwssvgi_2" x="333.178" y="170.282" width="17.086" height="153.774">
                                        </rect>
                                        <rect class="cwssvgi_3" x="64.07" y="126.386"
                                            transform="matrix(0.7372 -0.6757 0.6757 0.7372 -113.2996 100.555)"
                                            width="17.086" height="139.074"></rect>
                                        <rect class="cwssvgi_4" x="303.27" y="371.639"
                                            transform="matrix(0.6727 -0.7399 0.7399 0.6727 -219.9085 373.1305)"
                                            width="17.086" height="126.999"></rect>
                                        <path class="cwssvgi_5"
                                            d="M403.358,450.613c-0.367-0.547-0.794-1.042-1.281-1.486l-97.305-88.677c-2.905-2.657-7.236-2.956-10.499-0.794 l-53.573,35.71c-10.141,6.775-23.741,5.425-32.344-3.187l-88.48-88.899c-8.423-8.534-9.773-22.007-3.246-31.977l35.71-53.573 c2.178-3.272,1.845-7.612-0.812-10.508L57.554,104.706c-1.683-1.845-4.143-2.896-6.587-2.768 c-2.495,0.085-4.827,1.256-6.382,3.204C14.164,143.167,0,191.016,0,255.713c0,51.506,15.283,101.269,44.193,143.89 C93.7,472.577,174.491,511.926,256.666,511.926c49.515,0,99.526-14.284,143.506-44.116c0.06-0.034,0.12-0.077,0.179-0.12 C405.802,463.795,407.143,456.132,403.358,450.613z M58.34,390.009c-26.987-39.785-41.254-86.225-41.254-134.296 c0-56.059,11.106-98.296,34.821-131.879l82.5,90.001l-32.036,48.055c-10.935,16.719-8.697,39.178,5.365,53.419l88.523,88.941 c14.352,14.369,37.034,16.608,53.924,5.339l48.012-32.002l87.233,79.501C276.777,526.808,131.229,497.437,58.34,390.009z">
                                        </path>
                                        <rect class="cwssvgi_6" x="247.747" y="67.766" width="17.086" height="34.172">
                                        </rect>
                                        <rect class="cwssvgi_7" x="162.318" y="90.691"
                                            transform="matrix(0.8658 -0.5004 0.5004 0.8658 -30.9927 99.9603)"
                                            width="17.086" height="34.138"></rect>
                                        <rect class="cwssvgi_8" x="410.065" y="247.17" width="34.172" height="17.086">
                                        </rect>
                                        <rect class="cwssvgi_9" x="387.148" y="161.752"
                                            transform="matrix(0.866 -0.5001 0.5001 0.866 -30.9825 224.9874)"
                                            width="34.136" height="17.077"></rect>
                                        <rect class="cwssvgi_10" x="324.666" y="99.209"
                                            transform="matrix(0.5003 -0.8659 0.8659 0.5003 77.4738 349.7425)"
                                            width="34.139" height="17.087"></rect>
                                        <path class="cwssvgi_11"
                                            d="M500.672,385.592l-16.693-12.695C546.804,250.066,500.672,98.222,378.9,31.51C277.477-24.054,150.75-4.55,70.719,78.941 c-3.212,3.357-3.161,8.663,0.128,11.952l25.629,25.629c1.632,1.64,3.725,2.64,6.185,2.503c2.315-0.034,4.511-1.017,6.091-2.708 c38.085-40.75,91.914-64.679,147.538-65.636c113.05,0,205.032,91.974,205.032,205.032c0,28.32-12.644,66.14-19.35,84.208 l-18.12-14.07c-2.469-1.914-5.775-2.332-8.646-1.085c-2.862,1.239-4.827,3.955-5.109,7.057l-8.543,93.973 c-0.273,2.964,1.017,5.852,3.4,7.629c1.504,1.119,3.298,1.692,5.109,1.692c1.068,0,2.144-0.196,3.178-0.615l85.43-34.172 c2.862-1.145,4.878-3.742,5.297-6.783C504.38,390.487,503.115,387.454,500.672,385.592z M419.829,413.468l5.869-64.542 l14.848,11.516c2.093,1.632,4.827,2.204,7.407,1.512c2.571-0.666,4.682-2.503,5.707-4.955 c1.008-2.401,24.758-59.459,24.758-101.286c0-122.473-99.637-222.118-222.264-222.118 c-57.426,0.991-112.956,24.467-153.714,64.722L88.95,84.827c74.905-72.898,189.527-88.847,281.732-38.324 c115.681,63.372,158.225,209.039,94.853,324.711c-2.042,3.708-1.051,8.338,2.315,10.901l10.465,7.962L419.829,413.468z">
                                        </path>
                                    </svg>
                                </span>
                                <div class="cnt-deatil">
                                    <h6>Call Us:</h6>
                                    <p>+8 (123) 985 788</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="d-flex">
                                <span>
                                    <svg data-cwssvg-init="" class="170-postbox.svg" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        x="0px" y="0px" viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;"
                                        xml:space="preserve">
                                        <rect class="cwssvgi_0" x="48" y="208" width="16" height="16"></rect>
                                        <rect class="cwssvgi_1" x="48" y="240" width="16" height="16"></rect>
                                        <rect class="cwssvgi_2" x="32" y="224" width="16" height="16"></rect>
                                        <rect class="cwssvgi_3" x="32" y="256" width="16" height="16"></rect>
                                        <rect class="cwssvgi_4" y="240" width="16" height="16"></rect>
                                        <rect class="cwssvgi_5" x="8" y="208" width="16" height="16"></rect>
                                        <path class="cwssvgi_6"
                                            d="M477.648,146.336L416,84.688V59.312l45.656-45.656c3.124-3.125,3.123-8.19-0.002-11.314C460.154,0.843,458.121,0,456,0 h-80c-4.418,0-8,3.582-8,8v72H224c-1.817-0.001-3.581,0.617-5,1.752l-75.304,60.24c-9.881-8.735-24.972-7.806-33.708,2.075 c-3.885,4.395-6.017,10.067-5.988,15.933h16c0-4.418,3.582-8,8-8s8,3.582,8,8v16H88c-4.418,0-8,3.582-8,8v40H64v16h48v-16H96v-32 h40v72c-0.001,3.235,1.947,6.153,4.936,7.392c0.971,0.405,2.012,0.611,3.064,0.608c2.122,0,4.156-0.844,5.656-2.344L168,251.312 l18.344,18.344c3.124,3.123,8.188,3.123,11.312,0l24-24c1.5-1.5,2.344-3.534,2.344-5.656v-48h16v144H96v-64h24v-16H64v16h16v72 c0,4.418,3.582,8,8,8h48v20.688l-53.656,53.656c-3.124,3.125-3.123,8.19,0.002,11.314C83.846,439.157,85.879,440,88,440h160 c2.122,0,4.156-0.844,5.656-2.344L264,427.312V472c0,4.418,3.582,8,8,8h64c4.418,0,8-3.582,8-8v-88h128c4.418,0,8-3.582,8-8V152 C480,149.875,479.153,147.837,477.648,146.336z M416,16h20.688L416,36.688V16z M416,107.312L452.688,144H416V107.312z M380,195.352c2.47-1.426,3.994-4.06,4-6.912V16h16v172.44c0.006,2.852,1.53,5.486,4,6.912c7.422,4.224,12.004,12.108,12,20.648 c0,13.255-10.745,24-24,24s-24-10.745-24-24C367.996,207.46,372.578,199.576,380,195.352z M368,96v48h-61.184l-60-48H368z M208,236.688l-16,16l-18.344-18.344c-3.124-3.123-8.188-3.123-11.312,0L152,244.688V160c-0.025-1.289-0.156-2.573-0.392-3.84 L176,136.648V152h23.712c4.576,0.004,8.284,3.712,8.288,8.288V236.688z M152,352h96c4.418,0,8-3.582,8-8V184c0-4.418-3.582-8-8-8 h-24v-15.712c-0.018-13.407-10.881-24.27-24.288-24.288H176.8L224,98.24l72,57.6V368H152V352z M244.688,424H107.312l40-40h137.376 L244.688,424z M328,464h-48v-52.688L307.312,384H328V464z M464,368H312V160h56v24.168c-17.673,13.255-21.255,38.327-8,56 c13.255,17.673,38.327,21.255,56,8c17.673-13.255,21.255-38.327,8-56c-2.274-3.032-4.968-5.726-8-8V160h48V368z">
                                        </path>
                                    </svg>
                                </span>
                                <div class="cnt-deatil">
                                    <h6>Mail Us:</h6>
                                    <p>info@your-site.com</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="d-flex">
                                <span>
                                    <svg data-cwssvg-init="" class="096-loader-1.svg" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        x="0px" y="0px" viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;"
                                        xml:space="preserve">
                                        <path class="cwssvgi_0"
                                            d="M135.996,0h-33.44c-25.215-0.106-47.198,17.128-53.112,41.64L5.66,54.992c-4.224,1.296-6.597,5.771-5.301,9.995 c1.03,3.356,4.127,5.648,7.637,5.653h40V168c0,4.418,3.582,8,8,8h69.84c36.235,0.244,65.83-28.886,66.16-65.12V56 C191.961,25.087,166.909,0.036,135.996,0z M175.996,110.88c-0.316,27.403-22.756,49.378-50.16,49.12h-61.84V72h112V110.88z M63.996,56v-2.32c-0.028-1.416,0.065-2.832,0.28-4.232c0-0.128,0-0.256,0.056-0.384C66.93,29.99,83.307,15.824,102.556,16h33.44 c22.08,0.026,39.974,17.92,40,40H63.996z">
                                        </path>
                                        <path class="cwssvgi_1"
                                            d="M471.996,152h-232c-4.418,0-8,3.582-8,8v163.44l-40-39.496V264c-0.044-39.746-32.254-71.956-72-72h-64 c-4.418,0-8,3.582-8,8v272c0,4.418,3.582,8,8,8h128c4.418,0,8-3.582,8-8v-90.752c20,18.672,20.616,18.752,24,18.752h124.688 l29.656,29.656c3.124,3.123,8.188,3.123,11.312,0l40-40c3.123-3.124,3.123-8.188,0-11.312L395.308,352h76.688c4.418,0,8-3.582,8-8 V160C479.996,155.582,476.414,152,471.996,152z M335.996,168h40v168h-40V168z M247.996,168h72v168h-72V168z M63.996,208h56 c30.913,0.035,55.965,25.087,56,56v4.144l-42.4-41.84c-1.49-1.477-3.503-2.305-5.6-2.304h-64V208z M63.996,240h44.688 L82.34,266.344c-3.125,3.124-3.126,8.189-0.002,11.314c0.048,0.048,0.097,0.095,0.146,0.142c0,0,30.352,28.888,61.512,58.304 v27.616l-80,53.336V240z M175.996,464h-16v-48h16V464z M175.996,400h-24c-4.418,0-8,3.582-8,8v56h-80v-27.72l92.44-61.6 c2.232-1.489,3.568-3.997,3.56-6.68v-16.8c5.488,5.168,10.88,10.232,15.92,14.968l0.08,0.08V400z M404.684,384l-28.688,28.688 l-26.344-26.344c-1.5-1.5-3.534-2.344-5.656-2.344H218.764c-2.856-2.48-10.232-9.168-29.288-27.032l-2.608-2.456 c-8.608-8.08-18.12-17.008-27.704-26.088c-24.216-22.848-48.744-46.144-59.712-56.568l28.544-28.584L232.476,346.4 c1.009,3.312,4.057,5.582,7.52,5.6h132.688L404.684,384z M463.996,336h-72V168h72V336z">
                                        </path>
                                        <rect class="cwssvgi_2" x="407.996" y="184" width="40" height="16"></rect>
                                        <rect class="cwssvgi_3" x="263.996" y="304" width="16" height="16"></rect>
                                    </svg>
                                </span>
                                <div class="cnt-deatil">
                                    <h6>Monday - Friday:</h6>
                                    <p>08:00 – 5:00 pm</p>
                                </div>
                            </div>
                        </li>
                    </ul> -->
                    <!-- <a class="btn-kg-blue" [routerLink]="['/']" routerLinkActive="router-link-active">Create Support
                        Ticket</a> -->
                </div>
            </div>

            <div class="col-lg-8 col-md-8">
                <div class="kg-contact-form">

                    <form [formGroup]="contactForm" (ngSubmit)="onFormSubmit(contactForm.value)">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group mt-2">
                                    <label>First Name<span>*</span></label>
                                    <input type="text" class="form-control" formControlName="FirstName">
                                    <span
                                        *ngIf="contactForm.get('FirstName').invalid && ( contactForm.get('FirstName').touched || contactForm.get('FirstName').dirty)"
                                        class="text-danger">
                                        <span *ngIf="contactForm.get('FirstName').errors.required">First name is
                                            required</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group mt-2">
                                    <label>Last Name<span>*</span></label>
                                    <input type="text" class="form-control" formControlName="LastName">
                                    <span
                                        *ngIf="contactForm.get('LastName').invalid && ( contactForm.get('LastName').touched || contactForm.get('LastName').dirty)"
                                        class="text-danger">
                                        <span *ngIf="contactForm.get('LastName').errors.required">Last name is
                                            required</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group mt-2">
                                    <label>Email Address<span>*</span></label>
                                    <input type="text" class="form-control" formControlName="Email"
                                        (keydown.space)="$event.preventDefault();">
                                    <span
                                        *ngIf="contactForm.get('Email').invalid && ( contactForm.get('Email').touched || contactForm.get('Email').dirty)"
                                        class="text-danger">
                                        <span *ngIf="contactForm.get('Email').errors.pattern">Invalid email
                                            address</span>
                                        <span *ngIf="contactForm.get('Email').errors.required">Email address is
                                            required</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group mt-2">
                                    <label>Mobile Number<span>*</span></label>
                                    <br>
                                    <ngx-intl-tel-input id="phone" [enablePlaceholder]="true"
                                        [cssClass]="'form-control'" [enableAutoCountrySelect]="true"
                                        [phoneValidation]="true" [searchCountryFlag]="true" [selectFirstCountry]="true"
                                        formControlName="MobileNumber" [maxLength]="'15'" style="display: grid;width:100%">
                                    </ngx-intl-tel-input>


                                    <span *ngIf="contactForm.get('MobileNumber')?.touched" class="text-danger">
                                        <span *ngIf="contactForm.get('MobileNumber')?.errors?.required">Mobile number is
                                            required</span>
                                        <span
                                            *ngIf="contactForm.get('MobileNumber')?.errors?.validatePhoneNumber">Invalid
                                            Mobile number</span>
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group mt-2">
                                    <label>Message<span>*</span></label>
                                    <textarea name="" id="" class="form-control" rows="2"
                                        formControlName="Message"></textarea>
                                    <span
                                        *ngIf="contactForm.get('Message').invalid && ( contactForm.get('Message').touched || contactForm.get('Message').dirty)"
                                        class="text-danger">
                                        <span *ngIf="contactForm.get('Message').errors.required">Message is
                                            required</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mt-3 text-center">
                                    <button class="btn btn-kg-orange" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>

        </div>
        <!-- <div class="row">
      <div class="col-md-12">
        <div class="kg-address-map">
          <div class="kg-address-list" id="county-address">
            <div class="kg-count-add-list">
              <div class="kg-count-add-list-item">
                <h3>Azerbaijan </h3>
                <p>Sabit Orujov Street, 75cBaku, AZ1025</p>
              </div>
              <div class="kg-count-add-list-item">
                <h3>Cambodia </h3>
                  <p>Linehaul Express (Cambodia) Co. Ltd.
                    #21 St. 608, Sangkat Boengkak 2,
                    Khan Toul Kok, Phnom Pehn, 12152</p>
              </div>
              <div class="kg-count-add-list-item">
                <h3>Kenya </h3>
                  <p>Lavington Mall Lavington Nairobi, 00100</p>
              </div>
              <div class="kg-count-add-list-item">
                <h3>Malaysia </h3>
                  <p>No 1 Lingkaran Sultan Mohamed 1A,Taman Perindustrian Bandar Sultan Suleiman, Port Klang,Port Klang, Selangor, 42000</p>
              </div>
              <div class="kg-count-add-list-item">
                <h3>Mongolia</h3>
                  <p>Vista Office Building 6th floor Chinggis Avenue, Sukhbaatar District Ulaanbaatar, 14251</p>
              </div>
            </div>
          </div>
          <div class="kg-contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.6703197677107!2d70.78268691534558!3d22.29047494888356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959ca222b739795%3A0xdc560e40393b582d!2sBrevity%20Software%20Solutions%20%7C%20Web%20and%20mobile%20app%20development%20company!5e0!3m2!1sen!2sin!4v1611060555984!5m2!1sen!2sin" width="1920" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          </div>
        </div>
      </div>
    </div> -->
    </div>
</section>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-heading-title">
                    <h1>Franchise Partner</h1>
                    <!-- <p>Lorem Ipsum is simply dummy text</p> -->
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-space bg-white">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="mapsticky" >

                    <div style="width: 100%" *ngIf="mapAddress != null" class="ch-map" [innerHtml]="mapAddress | safeHtml"> </div> 
                    
                    <img src="/assets/images/nomap.png" width="1920" height="800" *ngIf="mapAddress == ''" /> 
                </div>
            </div>


            <div class="col-lg-4">
                <div class="kg-multi-add kg-contact-info">
                    <ul>
                        <ng-container *ngFor="let franchisee of franchiseeList">
                            <li>
                                <div class="d-flex" style="cursor: pointer;">
                                    <span>
                                        <img src="{{franchisee?.flagUrl}}" />
                                    </span>
                                    <ng-container *ngFor="let franchiseeDetail of franchisee?.franchiseDetails">
                                        <ng-container *ngIf="franchiseeDetail?.addressType == 1">
                                            <div class="cnt-deatil" (click)="changMap(franchisee.franchiseId)">
                                                <h6>{{franchiseeDetail?.country}}</h6>
                                                <p>
                                                    {{franchiseeDetail?.address1}}
                                                    <br> {{franchiseeDetail?.address2}} {{franchiseeDetail?.zipCode}}
                                                    <br>
                                                </p>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>