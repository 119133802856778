import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";
//const apiLisUrl = "https://localhost:44311/" + "api/";

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private http: HttpClient) { }

  AddContactUs(data: any){
    let url = `${apiLisUrl}ContactUs`; 
    return this.http.post(url,data);
  } 
}
