<div *ngIf="loading" class="wrapperLoader">
    <div class="loader">
    </div>
</div>
<section class="kg-header-block" style="background-image: url(./assets/images/faqs-header-bg.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-header-block-text">
                    <h1>faqs</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Faqs</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-heading-title" [innerHtml]="pageContent?.pageContent | safeHtml">
                    <h1>Flick ask questions</h1>
                    <p>Lorem Ipsum is simply dummy text</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="kg-faq">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-3 col-6" *ngFor="let category of categoryList">
                <div class="kg-faq-box faqCategory" (click)="getFaqsByCategory(category)">
                    <div class="kg-faq-icon f-1"></div>
                    <p>{{category.categoryName}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="bg-white section-space">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="" id="faqDescription">
                    <div class="kg-faq-tabs-title">
                        <h3>{{categoryName}} FAQ(s)</h3>
                    </div>

                    <div *ngIf="faqList.length == 0" class="alert alert-warning">No FAQ(s) Found.</div>

                    <div class="kg-faq-tabs-con" *ngIf="faqList.length > 0">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                                    <ng-container *ngIf="currentFragment != undefined">
                                        <a *ngFor="let faq of faqList; let i=index" class="nav-link" [ngClass]="{active: currentFragment === 'faq'+faq.faqId}" data-toggle="pill" href="#faq{{faq.faqId}}" role="tab" aria-controls="v-pills-home" aria-selected="true">{{faq.title}}</a>
                                    </ng-container>

                                    <ng-container *ngIf="currentFragment == undefined">
                                        <a *ngFor="let faq of faqList; let i=index" class="nav-link" [ngClass]="{active: i == 0}" data-toggle="pill" href="#faq{{faq.faqId}}" role="tab" aria-controls="v-pills-home" aria-selected="true">{{faq.title}}</a>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="tab-content" id="v-pills-tabContent">

                                    <ng-container  *ngIf="currentFragment != undefined">
                                        <div class="tab-pane fade show" *ngFor="let faq of faqList; let i=index" id="faq{{faq.faqId}}" [ngClass]="{active: currentFragment === 'faq'+faq.faqId}" role="tabpanel" aria-labelledby="faq{{faq.faqId}}">
                                            <div class="kg-faq-tabs-details" [innerHtml]="faq.description">
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container  *ngIf="currentFragment == undefined">
                                        <div class="tab-pane fade show" *ngFor="let faq of faqList; let i=index" id="faq{{faq.faqId}}" [ngClass]="{active: i==0}" role="tabpanel" aria-labelledby="faq{{faq.faqId}}">
                                            <div class="kg-faq-tabs-details" [innerHtml]="faq.description">
                                            </div>
                                        </div>
                                    </ng-container>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>