<div *ngIf="loading" class="wrapperLoader">
  <div class="loader">
  </div>
</div>
<section class="kg-header-block"
  style="background-image: url(./assets/images/prohibited-items-hd-banner.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="kg-header-block-text">
          <h1>Prohibited Items</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Prohibited Items</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="kg-heading-title" [innerHtml]="page1Content?.pageContent">
          <h1>Prohibited Items</h1>
          <p>Lorem Ipsum is simply dummy text</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="kg-brohibited-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12" [innerHtml]="page2Content?.pageContent">
        <div class="kg-brohibited-block">
          <h2>Download our Prohibited Items Reference Guide <a href="https://kangoexpress.com/file/2020/06/Prohibited-Items-Quick-Guide.pdf" target="_blank">here.</a> </h2>
          <p class="text-center">Kango Express cannot deliver items that have legal restrictions or that are restricted
            for safety reasons. It is important that you familiarize yourself with the reasons why items may not be able
            to ship as well as the most common types of products that are prohibited or restricted in order to avoid
            buying any of these items. Below are links to this information in other languages:</p>
            <ul>
              <li>
                <a href="https://kangoexpress.com/file/2015/05/Prohibited-items-list-AZ1.pdf" target="_blank" rel="noopener">Azeri Language</a>
              </li>
              <li>
                <a href="https://kangoexpress.com/file/2015/05/Prohibited-items-list-RUS1.pdf" target="_blank" rel="noopener">Russian Language</a>
              </li>
              </ul>
        </div>
        <div class="kg-brohibited-block">
          <h2>Restricted & Prohibited Items</h2>
          <p class="text-center">CLICK ON EACH CATEGORY BELOW to see some specific examples of restricted and prohibited
            goods. Although we have tried to make the list as complete as possible, it would be impossible to list every
            possible item that is restricted, especially in the categories of dangerous/hazardous items. Please also use
            our Quick Guide to see additional examples of Dangerous/Hazardous items. Additionally, this list is general
            and may not include specific products that cannot be imported into your particular country. Be sure to check
            with your local Customs office as well as our country-specific pages (Prohibited Items section) for
            additional items that may be restricted.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="kg-brohibited-item-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="kg-brohibited-item-heading" [innerHtml]="page3Content?.pageContent">
          <h2>Click <a href="https://kangoexpress.com/file/2020/06/Prohibited-items-for-import-in-Mongolia.pdf" target="_blank">HERE</a> for Prohibited Items for import in Mongolia.</h2>
          <p>We no longer ship Apple Watches.</p>
          <p>All items containing batteries are restricted for Direct shipping type.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6" *ngFor="let category of categories">
        <a href="" data-toggle="modal" data-target="#exampleModalCenter" (click)="getProhibitedItems(category)">
          <div class="kg-pro-item-box">
            <div class="kg-pro-icon"><img src="./assets/images/prohibited_item.png" alt=""> </div>
            <span>{{category.categoryName}}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
<section class="kg-restricted-sec">
  <div class="container">
    <div class="row" [innerHtml]="page4Content?.pageContent">
      
    </div>
  </div>
</section>

<div class=" kg-brohibited-item-modal modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title" id="exampleModalLongTitle">{{selectedCategory}}</h5>
        <button type="button" class="" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul>
          <li *ngFor="let item of prohibitedItemsList">
            {{item.title}}
            <ul>
              <li style="font-size: smaller;">
                {{item.description}}
              </li>
            </ul>
            
          </li>
          
        </ul>
      </div>        
    </div>
  </div>
</div>