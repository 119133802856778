import { Component, OnInit } from '@angular/core';
import { FreedomService } from '../Services/freedom.service';
import { HomePageService } from '../Services/home-page.service';
import { SitePagesService } from '../Services/site-pages.service';

@Component({
  selector: 'app-got-question',
  templateUrl: './got-question.component.html',
  styleUrls: ['./got-question.component.css']
})
export class GotQuestionComponent implements OnInit {

  happyCustomers : number = 0;
  totalPackages : number = 0;
  shippingPartners : number = 0;
  directServices : number = 0;
  pageName: string = "ecommercebusinesses";
  pageContent: any;
  data: any;

  constructor(private homePageService: HomePageService,private sitePagesService: SitePagesService, private api : FreedomService) { }

  ngOnInit(): void {
    this.getCounts();
    this.getSitePage();
  }

  getCounts(){
    this.api.getCounts().subscribe(res=>{
      if(res["statusCode"] == 200){
        this.data = res["result"];
        this.happyCustomers = this.data.customerCount;
        this.totalPackages = this.data.packageCount;
        this.shippingPartners = this.data.franchiseeCount;
        this.directServices = this.data.countriesCount;

      }
    })
  }

  getSitePage() {
    
    this.sitePagesService.getSitePage(this.pageName).subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.pageContent = response["result"];
      }
    }, (error) => {
      console.error(error);
      
    });
  }

}
