import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { CountryService } from '../Services/country.service';
import { SitePagesService } from '../Services/site-pages.service';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  feedbackForm: FormGroup;
  countryList: any;
  pageContent: any;
  public loading = false;
  pageName: string = "AboutUs";
  constructor(private formBuilder: FormBuilder, private countryService: CountryService, private sitePagesService: SitePagesService, private meta : Meta) {
    this.meta.updateTag({ name: 'keywords', content: 'About US, Kango Express About US, USA Shipping Services, UK Shipping Services'},'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'Kango Express offers a new alternative for consumers to shop in the U.S. and U.K. and ship their packages to over 220 countries.'},'name="description"'); 
  }

  ngOnInit(): void {

    this.getCountryList();

    this.getSitePage();

    this.feedbackForm = this.formBuilder.group({
      FirstName: ['', [Validators.required]],
      Email: ['', [Validators.required, Validators.pattern("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")]],
      CountryId: ['', [Validators.required]],
      AccountNumber: ['', []],
      Feedback: ['', [Validators.required]]
    })
  }

  getSitePage() {
    this.loading = true;
    this.sitePagesService.getSitePage(this.pageName).subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.pageContent = response["result"];
        setTimeout(() => { this.loading = false }, 2000);
      }
    }, (error) => {
      this.loading = false;
      console.error(error);
    });
  }

  getCountryList() {
    this.countryService.getCountries().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"]) {
        this.countryList = response["result"];
      }
    }, (error: any) => {
      console.error(error);
    })
  }

  onFormSubmit(feedback) {
    this.feedbackForm.markAllAsTouched();
    this.feedbackForm.controls

    if (this.feedbackForm.valid) {
      
      
    }
  }

}