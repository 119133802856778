<div *ngIf="loading" class="wrapperLoader">
    <div class="loader">
    </div>
</div>

<section class="kg-header-block"
    style="background-image: url(./assets/images/banner-3.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-header-block-text">
                    <h1>about Us</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="kg-about">
    <div class="container">
        <div class="row">
            <div class="col-md-7" [innerHtml]="pageContent?.pageContent">                
                <div class="kg-about-box">
                    <div class="kg-about-text">
                        <h1> <span>Become</span> a Franchise Partner </h1>
                        <p>Kango Express is looking for franchise partners to open local offices and manage
                            consolidation service shipments into their country. International online shopping is
                            expected to continue to grow, and affordable shipping services
                            like Kango Express fulfill a growing need of these consumers. Kango Express provides you
                            with all the tools and support you need to have a successful local operation. For more
                            information, please contact us at <a
                                href="mailto:info@kangoexpress.com">info@kangoexpress.com</a>.</p>
                    </div>
                    <div class="kg-about-img text-center">
                        <img src="./assets/images/about-2.png" alt="">
                    </div>
                </div>
                <div class="kg-about-box">
                    <div class="kg-about-text">
                        <h1> <span>Become </span> a Retail Partner </h1>
                        <p>Are you a small or mid-size retailer that would like to offer your products to international
                            consumers but are concerned about the costs of shipping and advertising? If yes, Kango
                            Express is a great option for your business to
                            expand globally at little or no cost. As a Kango retail partner we will advertise your
                            business to our database of customers. Kango Express will also handle all international
                            shipping for you so you don’t need to deal with
                            the headaches of customs or commercial invoices. All you need to do is promote Kango Express
                            as your partner on your check out page and instruct customers to sign up for a Kango Express
    AccountNumber. As a retail partner of Kango
                            Express, your products can go Global! For more information about partnering with Kango,
                            please contact us at <a href="mailto:info@kangoexpress.com">info@kangoexpress.com</a>. </p>

                    </div>
                    <div class="kg-about-img text-center">
                        <img src="./assets/images/about-3.png" alt="">
                    </div>
                </div>                
            </div>
            <div class="col-md-5">
                <div class="kg-about-feedback">
                    <div class="kg-af-hd">
                        <h2>Become a Partner</h2>
                    </div>
                    <div class="kg-af-con">
                        <form [formGroup]="feedbackForm" (ngSubmit)="onFormSubmit(feedbackForm.value)">
                            <div class="form-group">
                                <label>First Name <span>*</span> </label>
                                <input type="text" class="form-control" formControlName="FirstName">
                                <span
                                    *ngIf="feedbackForm?.get('FirstName')?.invalid && (feedbackForm?.get('FirstName')?.touched || feedbackForm?.get('FirstName')?.dirty)"
                                    class="text-danger">
                                    <span *ngIf="feedbackForm?.get('FirstName')?.errors?.required">This field is
                                        required</span>
                                </span>
                            </div>
                            
                            <div class="form-group">
                                <label> Email <span>*</span> </label>
                                <input type="email" class="form-control" formControlName="Email">
                                <span
                                    *ngIf="feedbackForm?.get('Email')?.invalid && (feedbackForm?.get('Email')?.touched || feedbackForm?.get('Email')?.dirty)"
                                    class="text-danger">
                                    <span *ngIf="feedbackForm?.get('Email')?.errors?.required">This field is
                                        required</span>
                                    <span *ngIf="feedbackForm?.get('Email')?.errors?.pattern">Invalid email
                                        address</span>
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Country <span>*</span> </label>
                                <select class="form-control" formControlName="CountryId">
                                    <option value="" selected>Select Country</option>
                                    <option *ngFor="let country of countryList" value="{{country.countryId}}">
                                        {{country.countryName}}</option>

                                </select>
                                <span
                                    *ngIf="feedbackForm?.get('CountryId')?.invalid && (feedbackForm?.get('CountryId')?.touched || feedbackForm?.get('CountryId')?.dirty)"
                                    class="text-danger">
                                    <span *ngIf="feedbackForm?.get('CountryId')?.errors?.required">select country</span>
                                </span>
                            </div>
                            <!-- <div class="form-group">
                                <label>Account #</label>
                                <input type="text" class="form-control" formControlName="AccountNumber">
                            </div> -->
                            <div class="form-group">
                                <label>Feedback <span>* </span></label>
                                <textarea class="form-control" rows="2" formControlName="Feedback"></textarea>
                                <span
                                    *ngIf="feedbackForm?.get('Feedback')?.invalid && (feedbackForm?.get('Feedback')?.touched || feedbackForm?.get('Feedback')?.dirty)"
                                    class="text-danger">
                                    <span *ngIf="feedbackForm?.get('Feedback')?.errors?.required">This field is
                                        required</span>
                                </span>
                            </div>
                            <div class="form-group text-center">
                                <button type="submit" class="btn btn-kg-orange">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>