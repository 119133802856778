import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private http: HttpClient) {
  }

  getWarehouseList() {
    let url = `${apiLisUrl}WarehousesCollection/GetAll`;
    return this.http.get(url);
  }

  getCourierServices(id:Number){
    let url = `${apiLisUrl}CourierServiceCollection/GetCourierServices/${id}`;
    return this.http.get(url);
  }
}