import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AddressType } from '../core/Enum';
import { ConsolidationCountriesService } from '../Services/consolidation-countries.service';

import { ContactUsService } from '../Services/contact-us.service';
import { SitePagesService } from '../Services/site-pages.service';
declare var $: any;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUSComponent implements OnInit {

  contactForm: FormGroup;
  loading = false;
  franchiseeList: any;
  franchiseeDetails: any;
  mapAddress: any = "";
  pageContent: any;
  pageName: string = "ContactUs";

  constructor(private formBuilder: FormBuilder,
    private contactUsService: ContactUsService,
    private el: ElementRef,
    private toastrService: ToastrService,
    private sitePagesService: SitePagesService,
    private consolidationCountriesService: ConsolidationCountriesService,
    private meta: Meta
  ) {
    this.meta.updateTag({ name: 'keywords', content: 'Contact Us, Ask Question, Fill Contact Form' }, 'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'If you have any questions or comments about our solutions and services providers, Please fill free to fill contact us form on our website.' }, 'name="description"');
  }

  ngOnInit(): void {

    this.getSitePage();

    this.contactForm = this.formBuilder.group({
      FirstName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      Email: ['', [Validators.required, Validators.pattern("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")]],
      MobileNumber: ['', [Validators.required, Validators.minLength(10)]],
      Message: ['', [Validators.required]]
    });

    this.franchiseeList = new Array();
    this.GetFranchiseeList();

  }

  onFormSubmit(contact) {

    this.contactForm.patchValue({
      FirstName: this.contactForm.get('FirstName').value.trim(),
      LastName: this.contactForm.get('LastName').value.trim(),
      Email: this.contactForm.get('Email').value.trim(),
      Message: this.contactForm.get('Message').value.trim(),
    });
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      this.loading = true;
      if (contact.MobileNumber.number) {
        contact.MobileNumber = contact.MobileNumber.number;
      }

      this.contactUsService.AddContactUs(contact).subscribe((response: any) => {
        if (response["statusCode"] == 200) {
          this.loading = false;
          this.contactForm.reset();
          this.toastrService.success(response["message"]);
        }
      }, (error) => {
        this.loading = false;
        console.error(error);
      });
    }
  }

  //for get address of franchisee
  GetFranchiseeList() {
    this.loading = true;
    this.consolidationCountriesService.GetFranchiseeList().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != []) {
        this.franchiseeList = response["result"];
        if (this.franchiseeList.length > 0) {
          this.changMap(this.franchiseeList[0].franchiseId);
        }

        setTimeout(() => { this.loading = false; }, 2000);
      }
    }, (error) => {
      this.loading = false;
      console.error(error);
    })
  }

  changMap(franchiseId: any) {
    debugger;
    this.franchiseeList.filter((franchisee) => {
      if (franchisee.franchiseId == franchiseId) {
        this.mapAddress = franchisee.mapAddress;
      }
    })
  }

  filterFranchiseeList() {
    let franchiseeDetails = this.franchiseeList.filter((franchisee) => {
      return franchisee.franchiseDetails.addressType == AddressType.OfficeAddress;
    });

    if (franchiseeDetails.length > 0) {
      this.franchiseeDetails = franchiseeDetails[0];


    } else {
      this.franchiseeDetails = null;
    }
  }

  getSitePage() {
    this.sitePagesService.getSitePage(this.pageName).subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.pageContent = response["result"];
      }
    }, (error) => {
      console.error(error);
    });
  }


}
