import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  getCustomerByCode(customerCode : string){    
    let code = btoa(customerCode);
    let url = `${apiLisUrl}CustomersCollection?CustomerCode=${code}`;
    return this.http.get(url);

    // let url = `${apiLisUrl}CustomersCollection?CustomerCode=${code}&CountryId=${countryId}`;
  }
  
}
