<section class="kg-header-block"
    style="background-image: url(./assets/images/prohibited-items-header-bg.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-header-block-text">
                    <h1>Privacy Policy</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-heading-title">
                    <h1>Privacy-Policy</h1>
                    <p>Last Updated: March 30, 2021</p>
                    <div class="kg-pre">
                        <p>
                            Kango Express, Inc. ("us", "we", or "our") operates the www.kangoexpress.com website (the
                            "Service").  This page informs you of our policies regarding the collection, use and
                            disclosure of Personal Information when you use our Service.  We will not use or share your
                            information with anyone except as described in this Privacy Policy.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-space  pp-space">
    <div class="container">
        <div class="col-lg-12" [innerHtml]="pageContent?.pageContent">
            <div class="row">
                <div class="notice-sedction-heading">
                    <p>
                        We use your Personal Information for providing and improving the Service. By registering and
                        using the Service, you agree to the collection and use of information in accordance with this
                        policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have
                        the same meanings as in our Terms and Conditions, accessible at
                        <a href="www.kangoexpress.com">www.kangoexpress.com</a>.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div ></div>
                    <div class="notice notice-info">
                        Information Collection and Use
                    </div>
                    <p>
                        While registering or using our Service, we may ask you to provide us with certain personally
                        identifiable information that can be used to contact or identify you. Personally, identifiable
                        information may include, but is not limited to, your email address, name, your billing and
                        delivery addresses, phone numbers, date of birth, gender, age, and other information ("Personal
                        Information").
                    </p>
                    <p>
                        We collect this information for the purpose of providing our Service, identifying and
                        communicating with you, responding to your requests/inquiries, requesting your feedback and
                        improving our services.
                    </p>
                    <p>
                        All the information you may provide will be treated in a confidential manner.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Log Data
                    </div>
                    <p>
                        We collect information that your browser sends whenever you visit our Service ("Log Data"). This
                        Log Data may include information such as your computer's Internet Protocol ("IP") address,
                        browser type, browser version, the pages of our Service that you visit, the time and date of
                        your visit, the time spent on those pages and other statistics.
                    </p>
                    <p>
                        In addition, we may use third party services such as Google Analytics that collect, monitor and
                        analyze this type of information in order to increase our Service's functionality. These third
                        party service providers have their own privacy policies addressing how they use such
                        information.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Cookies
                    </div>
                    <p>
                        Cookies are files with a small amount of data, which may include an anonymous unique identifier.
                        Cookies are sent to your browser from a web site and transferred to your device. We use cookies
                        to collect information in order to improve our services for you.
                    </p>
                    <p>
                        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                        The Help feature on most browsers provide information on how to accept cookies, disable cookies
                        or to notify you when receiving a new cookie.
                    </p>
                    <p>
                        If you do not accept cookies, you may not be able to use some features of our Service and we
                        recommend that you leave them turned on.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Do Not Track Disclosure
                    </div>
                    <p>
                        We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web
                        browser to inform websites that you do not want to be tracked.
                    </p>
                    <p>
                        You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web
                        browser.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Service Providers
                    </div>
                    <p>
                        We may employ third party companies and individuals to facilitate our Service, to provide the
                        Service on our behalf, to perform Service-related services and/or to assist us in analyzing how
                        our Service is used.
                    </p>
                    <p>
                        These third parties have access to your Personal Information only to perform specific tasks on
                        our behalf and are obligated not to disclose or use your information for any other purpose.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Communications
                    </div>
                    <p>
                        We may use your Personal Information to contact you with newsletters, marketing or promotional
                        materials and other information that may be of interest to you. You may opt out of receiving
                        any, or all, of these communications from us by following the unsubscribe link or instructions
                        provided in any email we send.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Compliance with Laws
                    </div>
                    <p>
                        We will disclose your Personal Information where required to do so by law or subpoena or if we
                        believe that such action is necessary to comply with the law and the reasonable requests of law
                        enforcement or to protect the security or integrity of our Service.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Security
                    </div>
                    <p>
                        The security of your Personal Information is important to us, and we strive to implement and
                        maintain reasonable, commercially acceptable security procedures and practices appropriate to
                        the nature of the information we store, in order to protect it from unauthorized access,
                        destruction, use, modification, or disclosure.
                    </p>
                    <p>
                        Your personal information is contained behind secured networks and is only accessible by a
                        limited number of persons who have special access rights to such systems, and are required to
                        keep the information confidential. In addition, all sensitive/credit information you supply is
                        encrypted via Secure Socket Layer (SSL) technology, and we use regular Malware scanning.
                        Despite our security vigilance, please be aware that no method of transmission over the
                        internet, or method of electronic storage is 100% secure and we are unable to guarantee the
                        absolute security of the Personal Information we have collected from you.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Credit Card Information
                    </div>
                    <p>
                        We do not store any of your credit card or payment information.  Payment information is
                        transmitted, stored and processed on our behalf by the secure servers of our payment processing
                        partner Braintree.  For more information on Braintree, please visit
                        <a href="https://www.braintreepayments.com">https://www.braintreepayments.com</a>.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        International Transfer
                    </div>
                    <p>
                        Your information, including Personal Information, may be transferred to — and maintained on —
                        computers located outside of your state, province, country or other governmental jurisdiction
                        where the data protection laws may differ than those from your jurisdiction.
                    </p>
                    <p>
                        If you are located outside United States and choose to provide information to us, please note
                        that we transfer the information, including Personal Information, to United States and process
                        it there.
                    </p>
                    <p>
                        Your consent to this Privacy Policy followed by your submission of such information represents
                        your agreement to that transfer.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Links to Other Sites
                    </div>
                    <p>
                        Our Service may contain links to other sites that are not operated by us. If you click on a
                        third-party link, you will be directed to that third party's site. We strongly advise you to
                        review the Privacy Policy of every site you visit.
                    </p>
                    <p>
                        We have no control over, and assume no responsibility for the content, privacy policies or
                        practices of any third-party sites or services.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Children's Privacy
                    </div>
                    <p>
                        Only persons age 18 or older have permission to access our Service. Our Service does not address
                        anyone under the age of 13 ("Children").
                    </p>
                    <p>
                        We do not knowingly collect personally identifiable information from children under 13. If you
                        are a parent or guardian and you learn that your Children have provided us with Personal
                        Information, please contact us. If we become aware that we have collected Personal Information
                        from a children under age 13 without verification of parental consent, we take steps to remove
                        that information from our servers.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Changes to this Privacy Policy
                    </div>
                    <p>
                        This Privacy Policy is effective as of May 02, 2015 and will remain in effect except with
                        respect to any changes in its provisions in the future, which will be in effect immediately
                        after being posted on this page.
                    </p>
                    <p>
                        We reserve the right to update or change our Privacy Policy at any time and you should check
                        this Privacy Policy periodically. Your continued use of the Service after we post any
                        modifications to the Privacy Policy on this page will constitute your acknowledgment of the
                        modifications and your consent to abide and be bound by the modified Privacy Policy.
                    </p>
                    <p>
                        If we make any material changes to this Privacy Policy, we will notify you either through the
                        email address you have provided us, or by placing a prominent notice on our website.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Updating Your Information
                    </div>
                    <p>
                        Your Personal Information can be updated or changed by logging into your Kango Express account. 
                        You may also send us a formal written request to confirm the Personal Information we have
                        regarding your account.
                    </p>
                    <p>
                        Upon a request to terminate or close your account, some of your Personal Information may be
                        deleted.  However, we will retain all Personal Information required to meet our legal and
                        business obligations.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Contact Us
                    </div>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@kangoexpress.com">info@kangoexpress.com</a>
                        or our Help Desk.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>