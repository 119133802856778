import { APP_BASE_HREF } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CountryService } from '../Services/country.service';
import { SitePagesService } from '../Services/site-pages.service';
import { AppSettings } from '../Shared/App-Settings';
declare var $: any;
@Component({
  selector: 'app-direct',
  templateUrl: './direct.component.html',
  styleUrls: ['./direct.component.css']
})
export class DirectComponent implements OnInit {

  imagesPath = AppSettings.API_BASE_URL+"images/country/";
  
  public loading = false;
  alphaList = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  selectedAlpha: any = "All";
  isAll :boolean =true;
  directShipmentCountriesList: any;
  filteredList: any;
  base_url = AppSettings.API_BASE_URL;
  pageName: string = "directshipment";
  pageName1: string = "basicInformation";
  pageContent: any;
  basicInfoContent: any;

  constructor(private countryService: CountryService,private sitePagesService: SitePagesService) { }

  ngOnInit(): void {

    this.getSitePage();
    this.getSitePageBasicInfo();
    this.directShipmentCountriesList = new Array();
    this.getDirecShipmentCountries();
    
  }

  getDirecShipmentCountries() {

    this.loading = true;
    this.countryService.getDirectShipmentCountries().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"]["values"] != []) {
        this.directShipmentCountriesList = response["result"]["values"];
        this.directShipmentCountriesList.sort((a,b)=> (a.countryName > b.countryName) ? 1 : -1);
        $.getScript('./assets/js/scripts.js');
        setTimeout(() => { this.loading = false; }, 2000);

      }
    },(error)=>{
      this.loading = false;
      console.error(error);      
    })
  }

  filterList(alpha: any) {
    this.isAll = false;
    this.selectedAlpha = alpha;
      this.filteredList = this.directShipmentCountriesList.filter((country) => {
        return country.countryName.charAt(0).toLowerCase() == alpha.toLowerCase();
      });
  }

  allList(alpha = 'all'){
    this.isAll = true;
      this.filteredList =  this.directShipmentCountriesList.filter((country)=>{
        return country.countryName.charAt(0).toLowerCase() == alpha.toLowerCase();
      });
      return this.filteredList;
    }

    getSitePage() {    
      this.sitePagesService.getSitePage(this.pageName).subscribe((response: any) => {
        if (response["statusCode"] == 200 && response["result"] != null) {
          this.pageContent = response["result"];
                  
        }
      }, (error) => {      
        console.error(error);
  
      });
    }

    getSitePageBasicInfo() {    
      this.sitePagesService.getSitePage(this.pageName1).subscribe((response: any) => {
        if (response["statusCode"] == 200 && response["result"] != null) {
          this.basicInfoContent = response["result"];
                  
        }
      }, (error) => {      
        console.error(error);
  
      });
    }

}
