import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";
//const apiLisUrl = "https://localhost:44311/" + "api/";


@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }

  addFeedback(data: any){
    let url = `${apiLisUrl}CustomerFeedbacks`;
    return this.http.post(url, data);
  }

  getFeedbacks(countryId: number){
    let url = `${apiLisUrl}CustomerFeedbackCollection?Status=2&Sort="CreatedDate"`;
    if(countryId != -1){
      url = `${apiLisUrl}CustomerFeedbackCollection?CountryId=${countryId}&Status=2`;      
    }
    return this.http.get(url);
  }

  getFeedbackCollection(countryId: number, pageNo : number, pageSize : number ){

    let url = `${apiLisUrl}CustomerFeedbackCollection?Status=2&Sort="CreatedDate desc"`;  
    url= url+"&PageNo="+pageNo+"&PageSize="+pageSize;

    if(countryId != -1){
      url = `${apiLisUrl}CustomerFeedbackCollection?CountryId=${countryId}&Status=2&Sort="CreatedDate desc"`;      
      url= url+"&PageNo="+pageNo+"&PageSize="+pageSize;
    }
    return this.http.get(url);
  }
}
