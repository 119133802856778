import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";

@Injectable({
  providedIn: 'root'
})
export class ServiceRatesService {

  constructor(private http: HttpClient) { }


  getServiceRates(){
  
    let url = `${apiLisUrl}ServicesRatesCollection/GetList?IsActive=true`;
    return this.http.get(url);
  }
}
