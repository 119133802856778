import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
 
  constructor() { 
    
  }
  private noOfItemsToShowInitially: number = 5;
  private itemsToLoad: number = 5; 
  list = ['hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello','hello'];
  listToShow = this.list.slice(0, this.noOfItemsToShowInitially);

  retailers = [{id: 1, title: 'Aaa'},{id: 2, title: 'Caa'},{id:3, title: 'Daa'},{id: 4, title: 'Baa'},{id: 5, title: 'Baa'},{id: 6, title: 'Baa'},{id: 7, title: 'Baa'}];
  
  ngOnInit(): void {    
    this.retailers.sort((a,b)=> (a.title > b.title) ? 1: ((b.title > a.title) ? -1 : 0));
  }
  
  onScroll(){
    if (this.noOfItemsToShowInitially <= this.list.length) {
      this.noOfItemsToShowInitially += this.itemsToLoad;
            this.listToShow = this.list.slice(0, this.noOfItemsToShowInitially);
    }
  }


  currentAlphabet;

  checkIfNew(title: string) {
    if(this.currentAlphabet === title.charAt(0).toLowerCase()) {
      return false;
    } else {
      this.currentAlphabet = title.charAt(0).toLowerCase();
      return true;
    }
  }
}