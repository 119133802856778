import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private http: HttpClient) {
  }

  getCountries() {
    let url = `${apiLisUrl}CountryCollection/GetList`;
    return this.http.get(url);
  }

  getDirectShipmentCountries(){
    let url = `${apiLisUrl}CountryCollection?IsDirectServiceAvailable=true`;
    return this.http.get(url);
  }
}
