import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";
//const apiLisUrl = "https://localhost:44311/" + "api/";

@Injectable({
  providedIn: 'root'
})
export class RetailersService {

  constructor(private http: HttpClient) { }

  getRetailers(){ 
        
    let url = `${apiLisUrl}RetailersCollection?IsActive=true`
    return this.http.get(url);
  }
}
