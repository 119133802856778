import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";
//const apiLisUrl = "https://localhost:44311/" + "api/";

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  constructor(private http: HttpClient) { }
  
  getTutorials(){
    let url = `${apiLisUrl}TutorialCollection?IsActive=true&TutorialFor=Website`;
    return this.http.get(url);     
  }

  getTutorialsByCountry(countryId : string){
    let url = `${apiLisUrl}TutorialCollection?IsActive=true&TutorialFor=Website&CountryId=${countryId}`;
    return this.http.get(url);
  }
}
