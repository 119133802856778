<section class="kg-header-block"
    style="background-image: url(./assets/images/prohibited-items-header-bg.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-header-block-text">
                    <h1>Terms & conditions</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Terms & Conditions</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-heading-title">
                    <h1>Terms & Conditions</h1>
                    <p>Last Updated: March 2021</p>
                    <div class="kg-pre">
                        <p>Please read these Terms of Service (“Terms”, “Terms of Service”) carefully before using the
                            www.kangoexpress.com website (the “Service”) operated by Kango Express, Inc. (“us”, “we”,
                            “our”, or “Kango Express”), and any sub-domains.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-space ">
    <div class="container">
        <div class="col-lg-12">
            
            <div [innerHtml]="pageContent?.pageContent | safeHtml"></div>

            <!-- <div class="row">
                <div class="notice-sedction">
                    <div class="notice notice-info">
                        Terms and Conditions
                    </div>
                    <p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with
                        all of these Terms. These Terms apply to all visitors, users, and others who wish to access or
                        use the Service. The term “you” and “your”
                        refer to you personally as an individual or the entity you represent (if your registration on
                        behalf of your business or a business you represent or work for).</p>
                    <p>By accessing, registering, or using the Service you agree to be bound by these Terms, and any
                        operating rules or policies that may be published by Kango Express at www.kangoexpress.com. If
                        you disagree with any part of the terms then
                        you do not have permission to access the Service.</p>
                    <p>The Terms of Service comprises the entire agreement between you and Kango Express and supersedes
                        all prior agreements between the parties regarding the subject matter contained herein.</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        User Conduct
                    </div>
                    <p>Kango Express reserves the right, but does not assume the responsibility, to monitor or review
                        your conduct on the Kango Express Site. Your use of the Kango Express Service is subject to all
                        applicable local, state, national and international
                        laws and regulations including, but not limited to, U.S. Postal regulations.</p>
                    <p>In using the Kango Express Service you agree not to:</p>
                    <ul>
                        <li>Use the Kango Express Service for illegal purposes or to ship hazardous or dangerous
                            materials.</li>
                        <li>Upload files that contain software or other material protected by intellectual property laws
                            (or by rights of privacy of publicity) unless you own or control the rights thereto or have
                            received all necessary consents.</li>
                        <li>Interfere with another User’s use and enjoyment of the Kango Express Service.</li>
                        <li>Upload files that contain viruses, corrupted files, or any other similar software or
                            programs that may damage the operation of another’s computer.</li>
                        <li>Conduct or forward surveys, contests, pyramid schemes or chain letters.</li>
                        <li>Download any file or information published, posted, distributed, disseminated, or uploaded
                            by another user that you know, or reasonably should know, cannot be legally distributed in
                            such manner.</li>
                        <li>Falsify, modify, or delete any author attributions, legal or other proper notices or
                            proprietary designations or labels in any information that is published, posted, advertised,
                            distributed, disseminated, or uploaded.</li>
                        <li>Interfere or disrupt networks connected to the Kango Express Service or violate the
                            regulations, policies or procedures of such networks.</li>
                        <li>Publish, post, upload, distribute or disseminate material or information that is unlawful,
                            harassing, defamatory, abusive, threatening, harmful, vulgar, obscene, sexually explicit, or
                            otherwise objectionable of any kind or nature.</li>
                        <li>Defame, abuse, harass, stalk, threaten or violate the legal rights (such as rights of
                            privacy and publicity) of any other user.</li>
                        <li>Transmit or upload any material that contains viruses, trojan horses, worms, time bombs,
                            cancelbots, or any other harmful or deleterious programs.</li>
                        <li>Attempt to gain unauthorized access to the Service, other accounts, computer systems or
                            networks connected to the Service, through password mining or any other means.</li>
                    </ul>
                    <p>Failure to comply with the above may result in the cancellation of your account and access to the
                        Service without notice. You will be responsible for any losses incurred by Kango Express as a
                        result of your improper use of the Service
                        and hereby agree to indemnify and hold Kango Express harmless from and against all manner of
                        loss or damage arising from your use of the service in violation of these Terms of Service.</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Accounts
                    </div>
                    <p>To set up an account with Kango Express, you must be above the age of 18 and provide us with a
                        valid email address (disposable emails are not acceptable), your correct name, home address, and
                        contact phone numbers. This information
                        is required to provide you with our Service and to comply with export/import and other
                        government regulations and laws. Kango Express reserves the right to request supporting
                        documentation at any time to validate your identification
                        and account information. It is your responsibility to keep this personal information up-to-date.
                        We reserve the right to cancel your account if we find that the information provided is
                        inaccurate, false or if we determine the account
                        is being used for fraudulent activities.</p>
                    <p>You are responsible for maintaining the confidentiality of your account and password, including
                        but not limited to the restriction of access to your computer and/or account. Your password can
                        be changed at any time in the My Account
                        section of the website once you are logged in. You agree to accept responsibility for any and
                        all activities or actions that occur under your account and/or password. You must notify us
                        immediately upon becoming aware of any breach
                        of security or unauthorized use of your account. We will de-activate or close your account and
                        assist with opening a new account for you.</p>
                    <p>You may not transfer your account or your password/login to another person or party. Each person
                        of legal age using the service should open their own account with the Service.</p>
                    <p>We reserve the right to refuse the Service, terminate accounts, remove or edit content in our
                        sole discretion.</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Posting of Personal Information on the Website
                    </div>
                    <p>Users should not post any personally identifiable information on publicly accessible areas of the
                        Website such as the customer feedback section. You agree that Kango Express has the right to
                        use, reproduce, publish, modify, copy, promote,
                        or distribute any such information on a public basis provided that doing so complies with Kango
                        Express’s Privacy Policy.</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Termination
                    </div>
                    <p>These Terms and Conditions, and any revisions to said Terms, will remain in full force and effect
                        while you are registered for the Service. You may terminate or close your account at any time,
                        for any reason by simply discontinuing
                        the use of your account or by advising us in writing you would like your account closed. You
                        understand and agree that the termination of your account is your sole right and remedy with
                        respect to any dispute or dissatisfaction
                        with the Service.</p>
                    <p>Kango Express may terminate or suspend your account at any time, for any or no reason, with or
                        without prior notice or explanation, and without liability. Even after an account is terminated,
                        these Terms and Conditions will remain
                        in effect.</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Shipping and Other Charges
                    </div>
                    <p>Shipping costs can be estimated by using the Cost Estimator on our website. Rates for
                        consolidation shipments are also available on the country-specific pages offering these shipment
                        types. Once your package is received and registered,
                        estimated charges will also be available in your packages page in My Account. Other charges that
                        may be assessed can be viewed on our Rates & Services page on our website.</p>
                    <p>Kango Express will advise customers via email and via our website whenever changes will be made
                        to costs and charges with advance notification. Kango Express is not liable if you do not
                        receive or review this information.</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Chargeable Weight
                    </div>
                    <p>Shipping costs will be charged based on volume (dimensional) weight or actual weight, whichever
                        is greater (referred to as “chargeable weight”). For shipments made under Kango Express’
                        consolidation shipments, the Chargeable Weight
                        is determined by adding the volume and actual weights of all the individual packages shipping
                        under your account in a single shipment (specific date) to a single delivery address and
                        consignee (recipient).</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Shipping Cost Estimator
                    </div>
                    <p>When you use the Cost Estimator on the Kango Express website, we are providing you with an
                        estimate only based on the information you provide. The final, invoiced amount may vary from the
                        estimate due to several reasons, including
                        but not limited to: changes in rates, the difference between the actual and estimated
                        volume/actual weight of the packages, a change in delivery location (especially if the zone is
                        not accurately entered during the estimation)
                        or the item received requires special packaging (such as with large TV’s). The final shipping
                        costs will be determined once the packages arrive at the Kango Express warehouse, the dimensions
                        and weight recorded, the delivery address
                        confirmed, and the packages are assigned to a shipment. You are responsible for all final
                        shipping charges assessed on your packages, regardless of whether these charges differ from
                        those provided by the Cost Estimator.</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Payment
                    </div>
                    <p>You authorize Kango Express to charge your preferred payment method for all due services and
                        account activity with us. These charges may include, but are not limited to, set up fees,
                        shipping charges, handling charges, storage fees,
                        return shipping, delivery charges, or incoming duty/tax charges. Payment for buying service
                        items and fees, shall be made directly with the local franchise office via cash, bank transfer,
                        or local CC payment. If you do not provide
                        payment for services provided, we have the right to assess additional fees and penalties to your
                        account, suspend your service, terminate your service or return or discard any packages in our
                        possession.</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Unpaid Invoices
                    </div>
                    <p>Any invoice past due for more than thirty (30) days will be assessed a monthly flat fee late
                        charge per our current Services and Rates. We also reserve the right to cancel your account,
                        prevent you from scheduling any further package
                        shipments, and/or discard any packages in our possession if your account becomes past due.</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Package Acceptance
                    </div>
                    <p>Kango Express will not accept on your behalf any packages or mail with duty or tax charges owed,
                        packages delivered COD (Cash on Delivery), or postage due mail, unless prior arrangement has
                        been made and agreed to by an authorized
                        representative of Kango Express at its sole discretion. In those cases where arrangements are
                        made to accept these packages, you will be responsible for paying the amount of the charges plus
                        the applicable handling fee assessed
                        by Kango Express prior to shipment.</p>
                    <p>In some cases (especially with packages delivered via FedEx or DHL) Kango Express will not be
                        advised at the time of delivery that a package has been assessed duty/tax. We will receive this
                        notification after the package may have been
                        shipped and delivered to our customer. In those cases, the customer is still responsible for all
                        charges and handling fees (per our charge of fees). Kango Express reserves the right to hold any
                        future packages until these charges
                        are paid or charge the customers’ payment method on hand.</p>
                    <p>Kango Express will also not accept delivery of any damaged or prohibited goods where such
                        characteristics are outwardly visible at time of drop off by the courier company, or in those
                        cases where damage or restriction is determined
                        during a delivery inspection. These packages will be refused and returned to the sender.</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Inspection
                    </div>
                    <p>Kango Express will exercise its right to open (at time of delivery, during storage and in
                        preparation for shipment) and inspect any package delivered to Kango Express on your behalf for
                        security purposes, to document package contents,
                        and to ensure compliance with export and import regulations.</p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Mail Not Accepted
                    </div>
                    <p>Kango Express will only process packages and envelopes containing items that you have purchased,
                        arrive under a courier tracking number, or are clearly of value with the intention of shipment.
                        All magazines, letters, coupons, advertisements,
                        personal statements, etcetera that we receive under your name or account will be destroyed.</p>
                    <p>Your Kango Express address should only be used for the purpose of shipping merchandise you have
                        purchased or to ship goods to family/friends abroad. It should not be used for any other
                        purposes, including but not limited to, soliciting
                        information, establishing residency in the United States/United Kingdom, or soliciting services.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Storage/Hold Service
                    </div>
                    <p>
                        Kango Express will store your damaged/restricted packages (60) days in storage and discard if
                        you haven’t take any action on hold packages. Kango Express reserves the right to sell
                        (including on the Kango Express website), destroy, donate or otherwise dispose of such packages
                        without incurring any liability to you or any other party whatsoever.
                    </p>
                    <p>
                        If you decide that you do not want to ship any package(s) received under your account, you may
                        request that we dispose of the package(s) prior to the sixty (60) day storage limit at no cost
                        to you.  If, on the other hand, you decide you would like the items returned to the merchant or
                        forwarded to a domestic third party, you will be responsible for any courier costs, handling
                        fees, and pending storage fees applicable.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Resizing Service
                    </div>
                    <p>
                        Kango Express offers you an optional package resizing service free of charge. Kango Express does
                        not guarantee that resizing can be completed on all packages or that a certain amount of space
                        can be removed from each package, regardless of whether you select this
                    </p>
                    <p>
                        preference.  Resizing is dependent on several factors including, but not limited to, the type
                        and size of items, type of original packaging, probability of damage, and shipment service
                    </p>
                    <p>
                        type.  Kango Express will perform the resizing according to its standard resizing processes and
                        policies at its sole discretion.  Requests for credits or refunds on shipping charges will not
                        be honored where it is determined that Kango Express has performed the service according to its
                        policies and procedures.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Unidentified Packages
                    </div>
                    <p>
                        On occasion, we will receive packages that we cannot confidently assign to an account due to an
                        incorrect/inaccurate customer name or missing/incorrect account number referenced on the
                        package.  You should contact our Customer Service and provide details of the package and
                        contents if there is an unusual delay in receiving notification that your package has been
                        received by our warehouse once the delivery courier or retailer shows the package to be
                        delivered.
                    </p>
                    <p>
                        Kango Express reserves the right to assign additional handling fees to packages that have been
                        incorrectly addressed (incorrect address, incorrect name, incorrect account number).  Any
                        packages that are received at our warehouse and cannot be assigned to a customer account within
                        sixty (60) days of receipt (after we have made reasonable efforts to identify the package owner)
                        will be discarded, sold, or donated as Kango Express deems appropriate.
                    </p>
                </div>
            </div>
           
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Promotions and Special Programs
                    </div>
                    <p>
                        Kango Express may offer customers promotions or programs such as the new customer discount
                        promotion.  Customers shall follow all terms and conditions of the particular program.  Abuse of
                        these programs shall result in the termination/cancellation of service.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Unclaimed Packages at Destination Office
                    </div>
                    <p>
                        When using our consolidation shipping service, it is your obligation to pick up your packages
                        within one week of receiving notification of arrival of your package(s) unless other
                        arrangements are made with the local Kango Express franchise management.  If you have not picked
                        up your packages within one week, we reserve the right to assess a $1.00 per day per package
                        hold fee which will be billed and collected at the time of final package collection.  After
                        sixty (60) days, if you have still not picked up your package your items will be considered
                        abandoned and become the property of Kango Express who will either discard, sell or donate the
                        items at their sole discretion.  You will not be entitled to a refund of any of the shipping or
                        other charges for packages that are unclaimed.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Duty and Taxes – Package Values
                    </div>
                    <p>
                        You are solely responsible for declaring the correct value of each package for the purpose of
                        excise, duty and tax payments. You agree to indemnify and hold Kango Express harmless from
                        liability for any incorrect valuations or misrepresentations you might make.  Shipments may be
                        inspected at any time by Customs authorities and incorrect valuations may lead to penalties,
                        fines, or delays in delivery for which you are solely responsible for.
                    </p>
                    <p>
                        You are responsible for completing any customs clearance processes in your destination country,
                        as well as the payment of any assessed charges/fees.  Duty and taxes are not included in the
                        rates of Kango Express.  Although Kango Express may provide tools on its website to help you
                        estimate potential duty and tax charges, you are solely responsible to find out the local
                        regulations for your shipments.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Delivery Guarantees
                    </div>
                    <p>
                        Kango Express will make every reasonable attempt to deliver your shipments to you according to
                        the estimated delivery times we provide on our website or via email.  However, these delivery
                        schedules are not guaranteed and are not part of our Terms of Service.  Kango Express is not
                        liable for any damage or loss caused by courier or airline delays.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Purchases Made with Sellers
                    </div>
                    <p>
                        Even though Kango Express may feature or share links to sellers on its website, we make no
                        guarantee, warranty or representation of the quality of product or service of these or any other
                        sellers you may choose to purchase from.  The purchase of the goods from a retailer is a
                        transaction solely between you and that particular retailer.  Kango Express will be held
                        harmless from any liability regarding the retailer’s ability to fulfill its obligations to you,
                        shipment of your product to our warehouse, or defects found with the product.  It is your
                        responsibility to understand the terms and conditions of each retailer you purchase goods from
                        and to manage communication with said retailer for any issues you may find with their service or
                        product.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Loss or Damage of Packages in Transit to Kango Warehouses
                    </div>
                    <p>
                        Kango Express will not be responsible for any packages that are lost or damaged prior to their
                        delivery at one of the Kango Express warehouses.  We are only responsible for your packages that
                        have a confirmed delivery at our warehouses and are in our custody and control.   Delivery is
                        not considered confirmed if a representative of Kango Express did not sign for the package
                        and/or the package was not acknowledged to be received by a Kango Express warehouse location
                        (through automatic notification to you via email or through your account dashboard).
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Product Returns & Exchanges
                    </div>
                    <p>
                        If you change your mind about a product you have ordered and would like to return it to the
                        merchant or another domestic third party prior to it shipping from one of our Kango Express
                        warehouses, you should:
                    </p>
                    <ul>
                        <li>Fill out a Return Request while creating a Pre-Alert for the incoming package.</li>
                    </ul>
                    <p>
                        You will be responsible for providing us with any return authorization forms and numbers that
                        are required by the retailer to accept a return.  Kango Express has no liability if a retailer
                        does not accept a return provided we have followed the instructions provided by you.  You must
                        pay for all invoiced costs associated with the return along with our standard return handling
                        charge prior to the package being returned.
                    </p>
                    <p>
                        We are unable to fulfill any requests to return a package after we processed and overpacked a
                        package, and the shipment deadline is already passed/closed.  You will be responsible for all
                        invoiced charges for said packages.
                    </p>
                    <p>
                        You are solely responsible for arranging any returns or exchanges with a retailer once the
                        product has been delivered to you in the local country.  In those countries where Kango Express
                        has a local office, our local staff may be able to assist with the return, at your cost.
                    </p>

                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Shipping Insurance
                    </div>
                    <p>
                        Kango Express offers shipping insurance for items with minimum cost of $100 that covers
                        the <strong>declared value of your parcel in case it gets damaged or lost.</strong> Currently it
                        is $2 per $100
                        of declared value. The maximum amount of reimbursement is $1000.
                    </p>
                </div>
            </div>
            <div class="row"  id="Claim-Policy">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Limited Liability for Lost or Damaged International Shipments
                    </div>
                    <p>
                        Not insured packages are shipped on a limited liability basis.  Our liability is limited to
                        direct loss only and subject to the limits of the Warsaw/Montreal Convention which currently is
                        $20.00 per kg or fraction thereof or the declared value of the damaged/lost item, whichever is
                        less.  Kango Express shall not be liable for indirect, incidental, or consequential damages such
                        as loss of profits, income, or future business.  Shipping charges are not part of the limited
                        liability or reimbursable amount. Kango Express is not responsible for damages of TVs shipped
                        from the warehouse without insurance.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Claims for Lost or Damaged Packages
                    </div>
                    <p>
                        Any claims for loss or damage must be submitted in writing to Kango Express within four (4)
                        calendar days from the date the item(s) was delivered.  If claims are not filed within the
                        required timeframe, Kango Express has no liability whatsoever for the loss.  A claim form can be
                        found in your account by pulling up the particular package and should be submitted to
                        <a href="mailto:claims@kangoexpress.com">claims@kangoexpress.com</a> along with copies of your
                        delivery receipt, photographs, and other
                        relevant supporting information.
                    </p>
                    <p>
                        Upon receipt of all the required information, your claim will be reviewed and the final decision
                        communicated to you within a forty-five (45) calendar day period.  Claim awards will be reviewed
                        based on the information received, any insurance you have elected to purchase and/or Kango
                        Express’s limited liability.  Any rewards will be credit back to your original payment method,
                        or applied to your account as an account credit.  In determining claim value, we will use the
                        lesser between the declared value of the item(s) and the order confirmation/packing list for the
                        items from the retailer.  As mentioned in the Limited Liability section, any claim award will be
                        based on the actual weight (at a rate of $20.00 per kilo) of the damaged/lost package or
                        declared value of the damaged/lost item, whichever is less. Insured packages will be reimbursed
                        according to declared value.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Shipping of TVs:
                    </div>
                    <p>
                        Kango Express ships TVs on a pallet (approximate fee of pallet is $20) to avoid damages during
                        transit.
                    </p>
                    <p>
                        The customer could reject shipping of TV on a pallet and its insurance but need to provide a
                        written note "I refuse pallet and I agree that Shipper is not responsible for any damages
                        happened on the way to the consignee".
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Shipping of Fragile / Heavy items:
                    </div>
                    <p>
                        Customers must indicate special instruction for checking the condition of fragile items and
                        asking for additional packing while creating a pre-alert for the incoming package; the special
                        instruction in pre-alert created for fragile heavy items should request shipping on a pallet,
                        otherwise Kango Express is not responsible for damages during transit.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Fraud Protection
                    </div>
                    <p>
                        We will make every effort to protect both sellers and our customers from fraudulent
                        activities.  If a retailer or legal authority contacts us with reasonable proof of fraudulent
                        behavior by one of our customers, we will cooperate with the retailer or legal authority to
                        resolve the situation as quickly as possible which will likely include returning any packages
                        involved in said fraudulent activity to the merchant.  Evidence of fraudulent activity (which
                        may include but is not limited to the use of stolen credit cards or PayPal accounts to pay for
                        purchased items or to file false claims against a retailer) by a Kango Express customer is
                        grounds for immediate termination of the account.  Kango Express will also provide and maintain
                        all information required by laws and regulations.  Civil or criminal penalties may be imposed by
                        the U.S. Government or local governments against customers for making false statements or
                        engaging in fraudulent transactions.
                    </p>
                    <p>
                        As a further means to protect retailers, our franchisees, and our reputation, we require all
                        customers to complete a credit card verification process before they can ship and pay any
                        invoices with a particular credit card.  The verification process is a means for us to validate
                        you have the authorization to use the card for our Services as well as validate you are the true
                        owner of the credit card.  Failure or refusal to complete the verification process is a
                        violation of our Terms and is cause for termination of your account.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Agency
                    </div>
                    <p>
                        You appoint Kango Express, or its subsidiaries, as your legal agent for the receipt of packages,
                        the performance of customs clearance, preparation of shipping documents, with full power of
                        attorney to act as such to the extent allowed by law for the specific purposes enumerated in
                        this paragraph, including but not limited to, the preparation of a Certificate of Origin, an EEI
                        via AES, airway bill or any other document required to export your packages.
                    </p>
                    <p>
                        You are responsible for providing Kango Express with any information and documentation necessary
                        to complete the required shipment and export documents.  Failure to provide requested or
                        required information will result in Kango Express declining to ship the items.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Compliance with Export and Import Law
                    </div>
                    <p>
                        You agree and acknowledge that:
                    </p>
                    <ul>
                        <li>You will comply with all applicable export and import laws and regulations.</li>
                        <li>You do not appear on any of the following lists which would prohibit Kango Express from
                            providing you with the Service, nor will you divert, reship or forward any part of a
                            shipment to an individual or entity appearing on any of these lists: </li>
                        <li>The U.S. Department of Commerce Denied Persons List, the Entity List, or the Unverified
                            List.</li>
                        <li>The U.S. State Department Debarred Parties List or the Proliferation List.</li>
                        <li>The U.S. Department of Treasury’s Office of Foreign Asset Control’s (OFAC) List of Specially
                            Designated Nationals and Blocked Persons.</li>
                        <li>You are not a resident of or will not ship to any of the countries designated as supporting
                            terrorism by the OFAC which includes Cuba, Iran, North Korea, Sudan, and Syria.</li>
                        <li>You will not use the Kango Express Service to ship or receive 1) prohibited and dangerous
                            goods, 2) goods that require an import or export license, or 3) illegal goods.</li>
                    </ul>
                    <p>
                        Kango Express will refuse to ship any package which we know, or suspect is in (or will be) in
                        violation of any and all applicable export and import laws.  We reserve the right to request
                        additional identification or documentation if we suspect that any individual or entity involved
                        in a shipment may appear on any of the above-referenced lists.  Failure to produce such
                        documents or evidence of violation of these Terms are cause for immediate termination of your
                        account.
                    </p>
                    <p>
                        You will be liable and agree to indemnify Kango Express for any penalties, costs, or fines that
                        may be incurred if any of your shipments are found to violate any of these conditions or Terms
                        and for any legal costs that Kango Express incurs related to such violations.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Acts of Terrorism
                    </div>
                    <p>
                        You represent and warrants to Kango Express that neither you nor any person acting on your
                        behalf is a person or entity described by Section 1 of the Executive Order No. 13,224 – Blocking
                        Property and Prohibiting Transactions with Persons Who Commit, Threaten to Commit, or Support
                        Terrorism. This order can be reviewed at 66 Fed reg. 34,777.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Prohibited and Illegal Items
                    </div>
                    <p>
                        You agree not to use Kango Express to receive or ship dangerous or hazardous goods, prohibited
                        or illegal items or controlled substances according to the laws and regulations of the country
                        your items are obtained from as well as the country they are shipping to.
                    </p>
                    <p>
                        Goods cannot ship if 1) they are on Kango Express’s list of Prohibited Items, 2) they are
                        restricted by entities such as, but not limited to, IATA (International Air Transport
                        Association), the partner airlines or courier companies used by Kango Express, export laws (from
                        the U.S. or U.K.), import laws, Customs authorities or other relevant governmental or regulatory
                        body and 3) Kango Express determines we cannot safely or legal transport the items.
                    </p>
                    <p>
                        You will be advised by Kango Express if one of your items has been classified as “Restricted”
                        during our inspection.  At that time, you will behave fourteen (14) calendar days to 1) have us
                        discard your item, 2) have the item returned to the merchant, or 3) have the item sent to
                        another personal address within the U.S. or U.K. (when applicable).  Note:  We will not forward
                        restricted items to other forwarding company.  You will be responsible for all applicable
                        shipping and handling charges to return or forward the item.  If you have not advised us of how
                        to proceed with the restricted items within fourteen (14) calendar days, Kango Express will
                        destroy or donate the item at its sole discretion.
                    </p>
                    <p>
                        Any items that are considered illegal will not be eligible for returns or forwarding, but
                        instead will be immediately handed over to local law enforcement.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Indemnification
                    </div>
                    <p>
                        You agree to protect, defend, indemnify and hold harmless Kango Express, Inc. and its respective
                        affiliates, franchisees, subsidiaries, agents, officers, and employees from and against any and
                        all claims, damages, obligations, losses, liabilities, judgments, costs and causes of action of
                        every type (including but not limited to attorney's fees) resulting from or arising out of a)
                        your use and access of the Service, by you or any person using your account and password, or b)
                        a breach of these Terms.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Force Majeure
                    </div>
                    <P>
                        Kango Express shall be excused from performance of its obligations, in whole or in part, under
                        these Terms of Service if the failure or inability to perform is caused by reason of Force
                        Majeure, as used herein meaning acts of God, fire, flood, or other natural catastrophes,
                        electric, communications or other utility outages, national emergencies, civil disturbances,
                        insurrections, riots, wars (declared or undeclared), epidemics, strikes or labor disputes,
                        actions, ore restrictions of governmental authority, or causes not within the reasonable control
                        of Kango Express.
                    </P>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Disclaimer
                    </div>
                    <p>
                        Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS
                        AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or
                        implied, including, but not limited to, implied warranties of merchantability, fitness for a
                        particular purpose, non-infringement, or course of performance.
                    </p>
                    <p>
                        Kango Express, Inc. its subsidiaries, affiliates, franchises, and its licensors do not warrant
                        that a) the Service will function uninterrupted, secure or available at any particular time or
                        location; b) any errors or defects will be corrected; c) the Service is free of viruses or other
                        harmful components; or d) the results of using the Service will meet your requirements.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Limitation of Liability
                    </div>
                    <p>
                        In no event shall Kango Express, Inc., nor its directors, employees, partners, agents,
                        suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or
                        punitive damages, including without limitation, loss of profits, data, use, goodwill, or other
                        intangible losses, resulting from (i) your access to or use of or inability to access or use the
                        Service; (ii) any conduct or content of any third party on the Service; (iii) any content
                        obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions
                        or content, whether based on warranty, contract, tort (including negligence) or any other legal
                        theory, whether or not we have been informed of the possibility of such damage, and even if a
                        remedy set forth herein is found to have failed of its essential purpose.  Except for the
                        limited liability for international package shipments explained in Limited Liability for Lost or
                        Damaged Shipments section, our maximum liability to you is $100 USD per incident with respect to
                        any errors in our Services provided to you under these Terms.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Changes or Modifications to Terms and Conditions
                    </div>
                    <p>
                        We reserve the right, at our sole discretion, to modify or replace these Terms at any time
                        without notice.  If a revision is material, we will provide at least seven (7) day notice prior
                        to any new terms taking effect. What constitutes a material change will be determined at our
                        sole discretion.
                    </p>
                    <p>
                        By continuing to access or use our Service after any revisions become effective, you agree to be
                        bound by the revised terms. If you do not agree to the new terms, you are no longer authorized
                        to use the Service.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Governing Law
                    </div>
                    <p>
                        These Terms shall be governed and construed in accordance with the laws of the State of
                        Minnesota and with the laws of the United States, without regard to its conflict of law
                        provisions.  Our failure to enforce any right or provision of these Terms will not be considered
                        a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable
                        by a court, the remaining provisions of these Terms will remain in effect. These Terms
                        constitute the entire agreement between us regarding our Service and supersede and replace any
                        prior agreements we might have had between us regarding the Service.
                    </p>

                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Entire Agreement
                    </div>
                    <p>
                        These Terms and Conditions (or as they may be modified from time to time) constitute the entire
                        agreement between us for the use of the Service, use of your account and use of the Kango
                        Express website.  Any other written or oral statement or agreement are superseded and of no
                        effect.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="notice-sedction pt-50">
                    <div class="notice notice-info">
                        Contact Us
                    </div>
                    <p>
                        If you have any questions about these Terms, please contact us via our Help Desk or at
                        <a href="mailto:info@kangoexpress.com">info@kangoexpress.com</a>.
                    </p>
                </div>
            </div> -->
            

        </div>
    </div>
</section>