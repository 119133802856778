import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../core/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm : FormGroup;
  submitted = false;

  constructor(
    // private authenticationService: AuthenticationService,
    // private toastrService: ToastrService,
    // private storageService: LocalStorageService,
    // private formBuilder: FormBuilder,
    // private encryDecryService : EncryDecryService
  ) { }

  ngOnInit(): void {

    //this.encryDecryService.Encrypt('savan'));
  
    // this.loginForm = this.formBuilder.group({
    //   email : ['', [Validators.required]],
    //   password : ['', [Validators.required]]
    // })
  }

  // get fval() { return this.loginForm.controls}

  onFormSubmit(){ 
    // this.submitted = true;
    // if(this.loginForm.invalid){
    //   return;
    // }
    // this.authenticationService.login(this.fval.email.value, this.encryDecryService.Encrypt(this.fval.password.value))
    // .subscribe((response: any)=>{
      
    // })
  }
}
