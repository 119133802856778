<section class="kg-header-block"
  style="background-image: url(./assets/images/contact-header-bg.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="kg-header-block-text">
          <h1>For Test</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="kg-customer-feedback">
  <div class="container">
    <!-- <div class="row scrolling" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()" [scrollWindow]="true">
      <div class="col-md-6 " *ngFor="let item of listToShow">
        <div class="row">
          <div class="col-md-6">
            <div class="kg-cf-item">
              <div class="kg-cf-item-hd"> <img src="./assets/images/county/abkhazia.png" alt="">
                <h4>Aileen, 13-11-2020 </h4>
              </div>
              <div class="kg-cf-item-details">
                <p>My very first item from Kangoexpress was amazing! I'm very happy and satisfied that
                  transactions and
                  delivery are smooth. Keep it up!</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div> -->


    
    <ng-container *ngFor="let retailer of retailers">

      <ng-container *ngIf="checkIfNew(retailer.title)">
        {{ retailer.title.charAt(0).toUpperCase() }}
      </ng-container>
      <ul class="architect-list">
        <li>
          <div [innerHTML]="retailer.title"></div>
        </li>
      </ul>
    </ng-container>
  </div>
</section>