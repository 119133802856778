import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";
//const apiLisUrl = "https://localhost:44311/" + "api/";

@Injectable({
  providedIn: 'root'
})
export class CostCalculatorService {

  constructor(private http: HttpClient) { }

  calculateShippingCost(data: any){
    let url = `${apiLisUrl}Miscellaneous/CostCalculate`;
    return this.http.post(url, data);
  }
} 