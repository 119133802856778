export enum AddressType {
    OfficeAddress = 1
}

export enum TutorialMediaType {
    Pdf = 1,
    Image = 2,
    Other = 3,
    Video = 4
}
