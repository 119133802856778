<header [ngClass]="{'uv-header-white': !removeClass}" class="uv-header" id="myHeader">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" [routerLink]="['/']">
        <div class="kg-logo-white"><img src="./assets/images/logo-colorfull.png"></div>
        <div class="logo-colorfull"><img src="./assets/images/logo-colorfull.png" alt=""></div>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item dropdown active">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false" href="#">Services & Fees
              <span class="sr-only">(current)</span></a>
            <div class="dropdown-menu" aria-labelledby="navbardrop">
              <a class="dropdown-item" [routerLink]="['/cost-calculator']">Cost Calculator</a>
              <a class="dropdown-item" [routerLink]="['/service-rates']" >Service & Fees</a>              
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link"  [routerLink]="['/where-we-ship']" >Where We Ship</a>
          </li>

          <li class="nav-item dropdown active">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false" href="#">Countries</a>
            <div class="dropdown-menu" aria-labelledby="navbardrop">
              <a *ngFor="let franchise1 of franchiseeList; let i=index" class="dropdown-item" [routerLink]="['/country', franchise1?.country?.countryName]" >{{franchise1.franchiseName}}</a>                           
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link"  [routerLink]="['/prohibited-items']" >Prohibited Items</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/sellers']" >Sellers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/contact-us']" >Contact Us</a>
          </li>

        </ul>
        <form class="form-inline my-2 my-lg-0">
          <div class="uv-header-btn">
            <a target="_blank" href="{{customerUrl}}login">Log In</a>
            <a target="_blank" href="{{customerUrl}}sign-up">Sign Up</a>
          </div>
          <!-- <div class="uv-loginuser">
            <div class="uv-userpic"><img src="./images/user.png" alt="user"> </div>
            <a href="#">My Account</a>
           <i><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="419.057px" height="419.057px" viewBox="0 0 419.057 419.057" style="enable-background:new 0 0 419.057 419.057;"
            xml:space="preserve"><g>
           <g>
             <path d="M57.998,192.505l32.726-32.717c6.59-6.617,6.59-17.384-0.039-24.043c-3.215-3.186-7.47-4.944-11.995-4.944
               c-4.527,0-8.782,1.759-11.998,4.969L5.068,197.387c-1.597,1.562-2.87,3.438-3.819,5.72C0.42,205.166,0,207.324,0,209.495
               c0,2.189,0.42,4.339,1.3,6.488c0.876,2.144,2.15,4.035,3.687,5.507l61.705,61.717c3.198,3.213,7.47,4.978,11.998,4.978
               c4.525,0,8.803-1.765,12.034-4.989c6.578-6.611,6.578-17.366-0.012-24.014l-32.714-32.714h56.964v-33.969H57.998V192.505z"/>
             <path d="M362.468,57.469H171.551c-31.213,0-56.589,25.395-56.589,56.602v2.798v75.637h167.479c9.368,0,17,7.62,17,16.996
               c0,9.353-7.626,16.979-17,16.979H114.962v28.607v25.124v24.782c0,31.2,25.376,56.595,56.589,56.595h190.917
               c31.213,0,56.589-25.395,56.589-56.595V114.07C419.057,82.863,393.681,57.469,362.468,57.469z"/></g></g></svg> </i>
          </div> -->
        </form>
      </div>
    </nav>
  </div>
</header>