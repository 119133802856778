import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeService {

  constructor(private http: HttpClient) { }

  getServiceTypes(countryId: number){
    let url = `${apiLisUrl}CustomerSignUp/GetCountryServices/${countryId}`;
    return this.http.get(url);
  }
}
