import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SitePagesService } from '../Services/site-pages.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  pageContent: any;

  constructor(private sitePagesService: SitePagesService, private meta : Meta) { 
    this.meta.updateTag({ name: 'keywords', content: 'Privacy Policy, Kango Express Privacy Policy, '},'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'You need to check out our privacy policy.'},'name="description"'); 
  }

  ngOnInit(): void {    
    this.getSitePage();
  }

  getSitePage() {
    this.sitePagesService.getSitePage("PrivacyPolicy").subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.pageContent = response["result"];
      }
    }, (error) => {
      console.error(error);
    });
  }

}
