<!-- sign-up-modal -->
<div class="sign-up-modal modal fade" id="user_sign_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Sign Up</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="signup-modal-con">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Full Name">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Last Name">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                    <input type="email" class="form-control" placeholder="Email Address ">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Phone No">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="password" class="form-control" placeholder="Password">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="password" class="form-control" placeholder="Confirm Passoword">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="file" class="form-control" id="exampleFormControlFile1">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <button type="button" class="login-btn btn-lg btn-block">Sign in</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
