import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ConsolidationComponent } from './consolidation/consolidation.component';
import { ContactUSComponent } from './contact-us/contact-us.component';
import { CostCalculatorComponent } from './cost-calculator/cost-calculator.component';
import { CustomerFeedbackComponent } from './customer-feedback/customer-feedback.component';
import { DirectComponent } from './direct/direct.component';
import { FaqsComponent } from './faqs/faqs.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProhibitedItemsComponent } from './prohibited-items/prohibited-items.component';
import { RetailersComponent } from './retailers/retailers.component';
import { ServiceRatesComponent } from './service-rates/service-rates.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { TestComponent } from './test/test.component';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { WhereWeShipComponent } from './where-we-ship/where-we-ship.component';


const routes: Routes = [
  { path: '', loadChildren: () => import('../app/Home/home.module').then(m => m.HomeModule), data: { title: 'Home' } },
  { path: 'contact-us', component: ContactUSComponent, data: { title: 'Contact Us' } },
  { path: 'about-us', component: AboutUsComponent, data: { title: 'About Us' } },
  { path: 'prohibited-items', component: ProhibitedItemsComponent, data: { title: 'Prohibited and Restricted Items' } },
  { path: 'sellers', component: RetailersComponent, data: { title: 'Seller' } },
  { path: 'faqs', component: FaqsComponent, data: { title: 'Frequently Asked Questions' } },
  { path: 'tutorials', component: TutorialsComponent, data: { title: 'Tutorials' } },
  { path: 'cost-calculator', component: CostCalculatorComponent, data : {title : 'Shipping Cost Calculator', metatags: {
    'description': `your description here`,    
     keywords: `your keywords here`
  }} },
  { path: 'customer-feedback', component: CustomerFeedbackComponent, data : {title : 'Our Happy Customers | Customers Feedback'} },
  { path: 'service-rates', component: ServiceRatesComponent, data : {title : 'Services and Rates'} },
  { path: 'terms-conditions', component: TermsConditionsComponent, data : {title : 'Terms and Conditions'} },
  { path: 'direct-shipment', component: DirectComponent, data : {title : 'Direct Shipment'} },
  { path: 'country/:name', component: ConsolidationComponent, data : {title : 'Consolidation Countries'} },
  { path: 'where-we-ship', component: WhereWeShipComponent, data : {title : 'Where We Ship'} },
  
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data : {title : 'Privacy Policy'} },
  { path: 'sitemap', component: SitemapComponent },
  { path: 'test', component: TestComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled',
    anchorScrolling : 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
