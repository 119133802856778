import { Component, OnInit } from '@angular/core';

import { ConsolidationCountriesService } from '../Services/consolidation-countries.service';
import { AddressType } from '../core/Enum'
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WarehouseService } from '../Services/warehouse.service';
import { ServiceTypeService } from '../Services/service-type.service';

declare var $: any;
@Component({
  selector: 'app-consolidation',
  templateUrl: './consolidation.component.html',
  styleUrls: ['./consolidation.component.css']
})
export class ConsolidationComponent implements OnInit {

  firstCountryId: number;
  firstFranchisee: number;
  selectedFranchisee: any;
  franchiseContactDetails: any;
  franchiseeList: any;
  franchise: any;
  warehouseList: any[] = [];
  isScroll: boolean = false;
  isDirectAvailable: boolean = true;

  public loading = false;


  constructor(private consolidationCountriesService: ConsolidationCountriesService, private sanitizer: DomSanitizer, private route: ActivatedRoute, private meta: Meta, private title: Title, private warehouseService: WarehouseService,private serviceType: ServiceTypeService) {
    this.franchiseeList = new Array();

  }

  ngOnInit(): void {
    // this.selectedFranchisee = this.route.snapshot.params['name'];    

    this.route.params.subscribe(params => {
      this.selectedFranchisee = params.name;
      this.GetFranchiseeList();
    })

    $.getScript('./assets/js/scripts.js');
    // this.GetFranchiseeList();    
  }

  GetFranchiseeList() {

    this.loading = true;
    this.consolidationCountriesService.GetFranchiseeList().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != []) {

        this.franchiseeList = response["result"];
        this.franchiseeList.sort((a, b) => a.franchiseName.localeCompare(b.franchiseName));
        if (this.franchiseeList.length > 6) {
          this.isScroll = true;
        }
        //this.firstFranchisee = response["result"][0]["franchiseId"];
        this.getFranchiseDetail(this.selectedFranchisee);
        setTimeout(() => { this.loading = false; }, 2000);
      }
    }, (error) => {
      this.loading = false;
      console.error(error);
    })
  }


  // getFranchiseDetail(franchiseId: number) {
  getFranchiseDetail(countryName: any) {
    this.setSEOData();
    $.getScript('./assets/js/scripts.js');
    let franchiseFiltered = this.franchiseeList.filter(function (franchise) {
      // return franchise.franchiseId == franchiseId;
      return franchise.country.countryName == countryName;
    });

    if (franchiseFiltered.length > 0) {
      this.franchise = franchiseFiltered[0];

      let filteredFranchiseDetails = this.franchise.franchiseDetails.filter(function (franchiseDetails) {
        return franchiseDetails.addressType == AddressType.OfficeAddress;
      });

      if (filteredFranchiseDetails.length > 0) {
        this.franchiseContactDetails = filteredFranchiseDetails[0];
      } else {
        this.franchiseContactDetails = null;
      }

      if (this.franchise?.country?.countryRates.length > 0) {
        this.franchise?.country?.countryRates.sort((a, b) => (a.fromInfo > b.fromInfo) ? 1 : ((b.fromInfo > a.fromInfo) ? -1 : 0));
      }

      this.getWarehouses(this.franchise.warehouseId);
      this.getCountryServiceType(this.franchise?.country?.countryId);
    } else {
      this.franchise = null;
    }
  }

  setSEOData() {
    this.title.setTitle(`Shipping Service to ${this.selectedFranchisee} - Kango Express`);
    this.meta.updateTag({ name: 'keywords', content: `Shipping service, Consolidation services, ${this.selectedFranchisee} Shipping Services, Knago Express, Country Shipping Services` }, 'name="keywords"');
    this.meta.updateTag({ name: 'description', content: `${this.selectedFranchisee} is a franchisee partner of Kango Express. ${this.selectedFranchisee} is responsible to deliver packages that arrived through Kango Express Shipment to the customers located in ${this.selectedFranchisee}.` }, 'name="description"');
  }

  getWarehouses(warehouseId) {
    this.warehouseList = [];
    let avlWarehouses = warehouseId.split(",");

    this.warehouseService.getWarehouseList().subscribe(res => {
      if (res["statusCode"] == 200 && res["result"] != null) {
        avlWarehouses.forEach(element => {
          res["result"].filter(el => {
            if (el.warehouseId == element) {
              this.warehouseList.push({ warehouseId: el.warehouseId, warehouseName: el.name });
            }
          })
        });
      }
    })
  }
  getCountryServiceType(countryId) {
    this.serviceType.getServiceTypes(countryId).subscribe(res => {
      if (res["statusCode"] == 200 && res["result"] != null) {
        let countryDetail = res["result"];
        this.isDirectAvailable = countryDetail.isDirectServiceAvailable;
      }
    });
  }
  warehouseChange(selectedWh) {

  }
}