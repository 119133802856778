import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";
//const apiLisUrl = "https://localhost:44311/" + "api/";

@Injectable({
  providedIn: 'root'
})
export class ProhibitedItemsService {

  constructor(private http: HttpClient) { }

  getProhibitedItems(categoryId: number){    
  
    let url = `${apiLisUrl}postTypeList?CategoryId=${categoryId}`;
    return this.http.get(url);
  }
  
  getProhibitedItemCategories(){
    let url = `${apiLisUrl}CategoryCollection?IsActive=true&Type=3`;
    return this.http.get(url);
  }
}