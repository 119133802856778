<div *ngIf="loading" class="wrapperLoader">
    <div class="loader">
    </div>
</div>

<section class="kg-header-block"
    style="background-image: url(./assets/images/prohibited-items-header-bg.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-header-block-text">
                    <h1>DIRECT SHIPMENT</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Direct Shipment</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-heading-title" [innerHtml]="pageContent?.pageContent">
                    <!-- <h1>Direct Shipment - DHL</h1>
                    <p>Shipment to be managed by DHL</p>
                    <div class="kg-pre">
                        <p>Please check below to see which shipping services are available to your country. For more
                            information on our Direct and Consolidation Services, please check our FAQ’s. Services
                            indicated by “UK” are available from our UK Warehouse;
                            services indicated by “US” are available from our US warehouse.</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-space" *ngIf="directShipmentCountriesList.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="countries-filter">
                    <div class="go-to-last-btn">
                        <button class="btn active" (click)="allList()">All</button>
                    </div>
                    <ul>
                        <li *ngFor="let alpha of alphaList">
                            <a href="#{{alpha.toLowerCase()}}_section" (click)="filterList(alpha)">{{alpha}}</a>
                        </li>
                    </ul>
                    <div class="go-to-last-btn">
                    <button class="btn mt-3" id="btn_goto_last" (click)="allList()">Go to Last</button>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">

                <!-- Getting list all character -->
                <div class="countries-filter-body" *ngIf="isAll" id="list_all">
                    <ng-container *ngFor="let alpha of alphaList">
                        <ng-container *ngIf="allList(alpha).length > 0">
                            <div id="{{alpha.toLowerCase()}}_section" class="section">
                                <div class="countries-head">
                                    <p><span class="first-latter">{{alpha}}</span>{{filteredList?.length}}
                                        Countries </p>
                                </div>
                                <div class="countries-body">
                                    <ul>
                                        <li *ngFor="let country of filteredList">
                                            <img src="{{base_url}}images/country/{{country.flagUrl}}">
                                            {{country.countryName}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                
                <!-- Getting list for selected character -->
                <div class="countries-filter-body" *ngIf="!isAll">
                    <div id="all_section">
                        <div class="countries-head">
                            <p><span class="first-latter">{{selectedAlpha}}</span>{{filteredList?.length}} Countries
                            </p>
                        </div>
                        <div class="countries-body">
                            <ul>
                                <li *ngFor="let country of filteredList">
                                    <img src="{{imagesPath+country.flagUrl}}">
                                    {{country.countryName}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="section-space" *ngIf="directShipmentCountriesList.length == 0">
    <div class="alert alert-warning">Direct Shipment Countries Not Found!</div>
</section>
<section class="section-space bg-sky important-service">
    <div class="container">
        <div class="row my-auto">
            <div class="col-md-4 ">
                <div class="kg-restricted-img">
                    <img src="./assets/images/basic_info.png" alt="">
                </div>
            </div>
            <div class="col-md-8">
                <div class="announcements-body" [innerHtml] = "basicInfoContent?.pageContent">
                    <!-- <p>Kango Express offers a Direct Service shipping option to over 200 countries and territories
                        throughout the world. Here are some of the key features of this service:</p>
                    <p>Packages are sent via courier service (such as DHL) to your home or office location.</p>
                    <p>Transit times vary by country but typically take from 2-5 days.</p>
                    <p>Pricing varies by country and weight/dimensions. You will need to use our cost calculator to
                        estimate the cost of your shipping charges.</p>
                    <p>Once you are ready to ship your packages you would select the packages in your account Inbox and
                        complete the Check Out process which includes making payment for your shipment. We typically
                        ship the packages within 24 hours (excluding
                        weekends) after payment has been completed.</p>
                    <p>Although we offer resizing on Direct Service packages, we may not be able to resize the packages
                        as much as we do with consolidation packages to ensure they are protected from damage.</p>
                    <p>You can save money by shipping multiple packages at the same time.</p>
                    <p>We provide free coverage for the first $100 of value of your packages. You have the option to
                        purchase additional protection coverage for your package during the check out process.</p> -->
                </div>
            </div>
        </div>
    </div>
</section>
<app-got-question></app-got-question>