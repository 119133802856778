<div *ngIf="loading" class="wrapperLoader">
  <div class="loader">
  </div>
</div>

<section class="kg-header-block"
  style="background-image: url(./assets/images/cost-calculator-hd-banner.png);background-repeat: no-repeat;background-size: cover;background-position: center;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="kg-header-block-text">
          <h1>Cost Calculator</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Cost Calculator</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="kg-heading-title">
          <h1>Estimate Your Shipping Costs.</h1>
          <p>Calculate the costs of Kango services. All you need is your outgoing package's weight and dimensions.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="kg-cost-calculator section-space">
  <div class="container">
    <form [formGroup]="costCalculationForm" (ngSubmit)="onSubmit(costCalculationForm.value)">
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <div class="kg-cost-fild">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Warehouse<span>*</span> </label>
                  <select class="form-control" formControlName="wareHouseId"
                    (change)="warehouseChange($event.target.value)">
                    <option value="">Select Warehouse</option>
                    <option *ngFor="let warehouse of warehouseList" value="{{warehouse.warehouseId}}">
                      {{warehouse.title}}</option>
                  </select>
                  <span class="text-danger"
                    *ngIf="costCalculationForm.get('wareHouseId').invalid && (costCalculationForm.get('wareHouseId').touched || costCalculationForm.get('wareHouseId').dirty)">
                    <span *ngIf="costCalculationForm.get('wareHouseId').errors.required">Warehouse Location
                      Missing</span>
                  </span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Country<span>*</span> </label>
                  <select class="form-control" formControlName="countryId"
                    (change)="countryChange($event.target.value)">
                    <option value="" selected>Select Country</option>
                    <option *ngFor="let country of countryList" value="{{country.countryId}}">{{country.countryName}}
                    </option>
                  </select>
                  <span class="text-danger"
                    *ngIf="costCalculationForm.get('countryId').invalid && (costCalculationForm.get('countryId').touched || costCalculationForm.get('countryId').dirty)">
                    <span *ngIf="costCalculationForm.get('countryId').errors.required">Destination Country
                      Missing</span>
                  </span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Service Type<span>*</span> </label>
                  <ng-container *ngIf="isServiceTypeLoading">
                    <div>
                      <img src="./assets/images/bar_loader.gif" />
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!isServiceTypeLoading">
                    <select class="form-control" formControlName="serviceType"
                      (change)="serviceChange($event.target.value)">
                      <option value="">Select Service Type</option>
                      <option value="2" *ngIf="isDirectServiceAvailable">Kango Direct Service</option>
                      <option value="1" *ngIf="isConsoServiceAvailable">Kango Consolidation Service</option>
                    </select>
                    <span class="text-danger"
                      *ngIf="costCalculationForm.get('serviceType').invalid && (costCalculationForm.get('serviceType').touched || costCalculationForm.get('serviceType').dirty)">
                      <span *ngIf="costCalculationForm.get('serviceType').errors.required">Service Type Missing</span>
                    </span>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-12" *ngIf="isDirectService">
                <div class="form-group">
                  <label>Services Method<span>*</span> </label>
                  <ng-container *ngIf="isServiceTypeLoading">
                    <div>
                      <img src="./assets/images/bar_loader.gif" />
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!isServiceTypeLoading">
                    <select class="form-control" formControlName="CourierServiceId">
                      <option value="">Select Service Type</option>
                      <option *ngFor="let serviceMethod of serviceMethodList" value="{{serviceMethod.courierServiceId}}">
                        {{serviceMethod.name}}</option>
                    </select>
                    <span class="text-danger"
                      *ngIf="costCalculationForm.get('CourierServiceId').invalid && (costCalculationForm.get('CourierServiceId').touched || costCalculationForm.get('CourierServiceId').dirty)">
                      <span *ngIf="costCalculationForm.get('CourierServiceId').errors.required">Service Method Missing</span>
                    </span>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-12" *ngIf="isService">
                <div class="form-group">
                  <label>Delivery Location</label>
                  <!-- <div class="custom-control custom-radio">
                    <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio1">Toggle this custom radio</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio2">Or toggle this other custom radio</label>
                  </div> -->

                  <ng-container *ngIf="isDirectService">

                    <div class="type1">
                      <input type="radio" id="deliveryLocation01" name="deliveryLocation" value="homeDelivery"
                        formControlName="deliveryLocation" />
                      <label for="deliveryLocation01">Home Delivery</label>
                    </div>

                  </ng-container>

                  <ng-container *ngIf="isConsoService">

                    <div class="type1 mt-1">
                      <input type="radio" id="deliveryLocation02" name="deliveryLocation"
                        (change)="optionChange($event.target.value)" formControlName="deliveryLocation"
                        value="pickUp" /><label for="deliveryLocation02">Pick
                        Up</label>
                    </div>

                    <div class="type1 mt-1">
                      <input type="radio" id="deliveryLocation01" name="deliveryLocation" value="homeDelivery"
                        (change)="optionChange($event.target.value)" formControlName="deliveryLocation" />
                      <label for="deliveryLocation01">Home Delivery</label>
                    </div>

                  </ng-container>

                </div>
              </div>
            </div>
            <div class="row" *ngIf="isZip">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Delivery Zip Code</label>
                  <input type="text" class="form-control" formControlName="deliveryZipCode">
                  <span class="text-danger"
                    *ngIf="costCalculationForm.get('deliveryZipCode').invalid && (costCalculationForm.get('deliveryZipCode').touched || costCalculationForm.get('deliveryZipCode').dirty)">
                    <span *ngIf="costCalculationForm.get('deliveryZipCode').errors.required">Delivery Zip Code
                      Missing</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="!isZip && isConsoService">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Pick Up Location :</label>                
                  <label>{{pickupAddress?.name}}<br>
                    {{pickupAddress?.address1}}<br>
                    {{pickupAddress?.country}}, {{pickupAddress?.city}}<br>
                    {{pickupAddress?.state}}-{{pickupAddress?.zipCode}}<br>
                    <ng-container *ngIf="pickupAddress?.phone1">{{pickupAddress?.phone1}}</ng-container>
                    <ng-container *ngIf="pickupAddress?.phone2">/{{pickupAddress?.phone2}}</ng-container>
                    <ng-container *ngIf="pickupAddress?.phone3">/{{pickupAddress?.phone3}}</ng-container>
                  </label>
                  </div>                  
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-12 offset-lg-1 offset-md-0">
          <div class="calculator-body">
            <h4>Package Details:</h4>
            <div class="row">
              <div class="col-md-6">
                <div class="type1">
                  <p>Shipping Weight Type:</p>
                  <input type="radio" id="weightType01" name="shippingWeightType" formControlName="shippingWeightType"
                    value="Kgs" /><label for="weightType01">Kgs</label>
                  <input type="radio" id="weightType02" name="shippingWeightType" formControlName="shippingWeightType"
                    value="Lbs" /><label for="weightType02">Lbs</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="type1">
                  <p>Shipping Dimension Type:</p>
                  <input type="radio" id="dimensionType01" name="shippingDimensionType"
                    formControlName="shippingDimensionType" value="Cms" /><label for="dimensionType01">Cms</label>
                  <input type="radio" id="dimensionType02" name="shippingDimensionType"
                    formControlName="shippingDimensionType" value="Inch" /><label for="dimensionType02">Inch</label>
                </div>
              </div>
            </div>
          </div>
          <div class="kg-cost-packages">
            <h3>Please enter the details of each of the packages you plan to ship together.</h3>
            <div class="kg-packages-plan bg-gray" formArrayName="packages">

              <div class="row">
                <div class="col-2">
                  <div class="form-group">
                    <label class="label-dekstop" for="">Package</label>
                    <label class="label-mobile" for="">Pkg</label>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label class="label-dekstop" for="">Weight</label>
                    <label class="label-mobile" for="">Wt</label>
                    <label for=""></label>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label class="label-dekstop" for="">Length</label>
                    <label class="label-mobile" for="">Ln</label>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label class="label-dekstop" for="">Width</label>
                    <label class="label-mobile" for="">Wd</label>
                    
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label class="label-dekstop" for="">Height</label>
                    <label class="label-mobile" for="">Ht</label>
                  </div>
                </div>

                <div class="col-1">
                  <div class="form-group">
                    <label for=""></label>
                  </div>
                </div>
              </div>

              <div class="row" *ngFor="let row of rows().controls; let i = index" [formGroupName]="i">
                <div class="col-2">
                  <div class="form-group">
                    <label for="">{{i+1}}</label>
                  </div>
                </div>

                <div class="col-2">
                  <div class="form-group">
                    <input type="number" class="form-control" formControlName="weight" />
                    <span class="text-danger"
                      *ngIf="row.get('weight').invalid && (row.get('weight').touched || row.get('weight').dirty)">
                      <span *ngIf="row.get('weight').errors.required">This is required.</span>
                    </span>
                  </div>
                </div>

                <div class="col-2">
                  <div class="form-group">
                    <input type="number" class="form-control" formControlName="length" />
                    <span class="text-danger"
                      *ngIf="row.get('length').invalid && (row.get('length').touched || row.get('length').dirty)">
                      <span *ngIf="row.get('length').errors.required">This is required</span>
                    </span>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <input type="number" class="form-control" formControlName="width" />
                    <span class="text-danger"
                      *ngIf="row.get('width').invalid && (row.get('width').touched || row.get('width').dirty)">
                      <span *ngIf="row.get('width').errors.required">This is required.</span>
                    </span>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <input type="number" class="form-control" formControlName="height" />
                    <span class="text-danger"
                      *ngIf="row.get('height').invalid && (row.get('height').touched || row.get('height').dirty)">
                      <span *ngIf="row.get('height').errors.required">This is required.</span>
                    </span>
                  </div>
                </div>

                <div class="col-2" *ngIf="i > 0">
                  <div class="form-group">
                    <div class="kg-pp-btn">
                      <button class="btn kg-mins" (click)="removeRow(i)">-</button>
                    </div>
                  </div>
                </div>
                <div class="col-2" *ngIf="i == 0">
                  <div class="form-group">
                    <div class="kg-pp-btn">
                      <button class="btn kg-plus" (click)="addRow()" type="button">+</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="kg-pp-btn text-center">
                  <button class="btn btn-kg-orange" type="submit" [disabled]="!isSubmitAble">Calculate</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>

<section class="bg-blue" *ngIf="calculatedCost">
  <!-- <section class="bg-blue"> -->
  <div class="container">
    <div class="col-md-12">
      <div class="kg-packages-details">
        <h1 class="text-center font-blod mb-5 white">Result</h1>
        <div class="row res-bx">

          <div class="col-md-3">
            <div class="kg-pd-boxs">
              <h2>{{calculatedCost?.serviceMethod}}</h2>
              <p>Service Method</p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="kg-pd-boxs">
              <h2>
                <ng-container *ngIf="calculatedCost?.serviceMethod == 'Direct'">
                  {{calculatedCost?.chargeableWeight}}(LBS)
                </ng-container>
                <ng-container *ngIf="calculatedCost?.serviceMethod == 'Consolidation'">
                  {{calculatedCost?.chargeableWeight}}(KGS)
                </ng-container>
              </h2>
              <p>Total Chargeable Weight</p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="kg-pd-boxs">
              <h2>{{calculatedCost?.shippingCharges | currency}}</h2>
              <p>Estimated Shipping Charges</p>
            </div>
          </div>

          <div class="col-md-3" *ngIf="calculatedCost?.transitTime">
            <div class="kg-pd-boxs">
              <h2>{{calculatedCost?.transitTime || 0}}</h2>
              <p>Transit Time</p>
            </div>
          </div>

          <div class="col-md-3" *ngIf="calculatedCost?.deliverySurcharges">
            <div class="kg-pd-boxs">
              <h2>{{calculatedCost?.deliverySurcharges | currency}}</h2>
              <p>Estimated Beyond Delivery Surcharge</p>
            </div>
          </div>

          <div class="col-md-3" *ngIf="calculatedCost?.packageHandlingFee">
            <div class="kg-pd-boxs">
              <h2>{{calculatedCost?.packageHandlingFee | currency}}</h2>
              <p>Handling Fee Per Package</p>
            </div>
          </div>


        </div>
        <div class="col-md-12">
          <div class="kg-pk-notes">
            <!-- <div class="kg-pk-address">
                <div class="row">
                  <div class="col-md-8">
                    <div class="kg-pk-hd">
                      <h3>Total Chargeable Weight:</h3>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="kg-pk-pre">
                      <p>100.00 Kgs</p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <div class="kg-pk-hd">
                      <h3>Estimated Shipping Charges:</h3>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="kg-pk-pre">
                      <p>$975.00</p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <div class="kg-pk-hd">
                      <h3>Estimated Beyond Delivery Surcharge:</h3>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="kg-pk-pre">
                      <p>100.00 Kgs</p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <div class="kg-pk-hd">
                      <h3>Package Handling Fee:</h3>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="kg-pk-pre">
                      <p>$2.00</p>
                    </div>
                  </div>
                </div>
              </div> -->
            <div class="kg-pk-note-boxs">
              <h3>Notes :</h3>
              <p>This estimate is based on the information you provided. Any variation in package sizes, weights,
                service type, or final delivery address will affect the final cost of your shipment. </p>
              <p>We will charge you for Volume Weight or Actual Weight whichever is greater. </p>
              <p>This estimate does not include any additional or optional services you may request or require such as
                hold fees (beyond the 30 day free period), change fees, requests for pictures, etcetera. </p>
              <p> The shipment estimates are based on our rates at the time. Final shipment prices are subject to change
                and will be based on the rates at the time the packages are processed for shipment.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="cstm-sec calc-blog">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="kg-heading-title">
          <h1>How to Calculate Shipping Weight?</h1>
          <p>Lorem Ipsum is simply dummy text</p>
          <div class="kg-pre">
            <p>Lorem lipsum The shipment estimates are based on our rates at the time. Final shipment prices are subject
              to change and will be based on the rates at the time the packages are processed for shipmen</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="details">
          <img id="myImg" src="./assets/images/calculate-shipping-1.jpg" alt="img">
          <div class="inner-details">
            <h3>
              Understanding Billable or Chargeable Weight
            </h3>
            <p>
              Your final shipping charges will be based on either the volume/dimensional weight or the actual weight,
              whichever is greater. This final weight is referred to as your billable or “chargeable” weight. Actual
              weight is the physical weight of the package when you put it on a scale. Volume or dimensional weight is
              based on the size or density of the package and is related to how much space an item will take up in an
              airplane.
            </p>
            <p>
              In most cases, your packages will have a higher volume weight that actual weight, with the exception of
              particularly heavy objects. Keep in mind that a very large, but light, item may cost more than a very
              small, but heavy, item. As shown in the example below, a teddy bear is much lighter than a bowling ball,
              but will ultimately cost more than a bowling ball to ship because it is much larger and will take up more
              volume space than the bowling ball.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="details">
          <img id="myImg1" src="./assets/images/calculate-shipping-2.jpg" alt="img">
          <div class="inner-details">
            <h3>
              Calculating Volume Weight
            </h3>
            <p>
              The volume or dimensional weight of your packages will be calculated using the formula Length x Width x
              Height (in centimeters)/5000 or 6000 (5000 for Direct shipments/6000 for Consolidation shipments). This
              gives you your volume weight in kilograms.
            </p>
            <p>
              When packages are measured the dimensions recorded are those of the outer most edges of the packages. If
              your box or bag has one side that is protruding out, this will affect the overall volume of the package.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="kf-notes">
          <p>
            <strong>Note* :</strong>Estimate Your Shipping Costs.
            Calculate the costs of Kango services. All you need is your outgoing package's weight and dimensions. Volume or dimensional weight is
            based on the size or density of the package and is related to how much space an item will take up in an
            airplane.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-got-question></app-got-question>