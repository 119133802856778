<section class="kg-header-block" style="background-image: url(./assets/images/prohibited-items-header-bg.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="kg-header-block-text">
            <h1>SiteMap</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']" >Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Sitemap</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>  
  </section>

  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="kg-heading-title">
            <h1>Site Map</h1>
            <p>Lorem lipsume Shipment to be managed by DHL</p>
            <div class="kg-pre">
              <p>Lorem LipsumePlease check below to see which shipping services are available to your country. For more information on our Direct and Consolidation Services, please check our FAQ’s. Services indicated by “UK” are available from our UK Warehouse; services indicated by “US” are available from our US warehouse.</p>
            </div>
          </div>
        </div>
      </div>      
    </div>
  </section>
  
  <section class="section-space bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="map-head">
            <h3>Home</h3>
          </div>
          <div class="map-box">
              <ul>
                <li><a href="">Home</a></li>                
              </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="map-head">
            <h3>Blog</h3>
          </div>
          <div class="map-box">
              <ul>
                <li><a href=""></a></li>
                <li><a href=""></a></li>
              </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="map-head">
            <h3>Country</h3>
          </div>
          <div class="map-box">
              <ul>
                <li><a href="">Country</a></li>
                <li><a href="">Prohibited Items</a></li>
              </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="map-head">
            <h3>Shopping</h3>
          </div>
          <div class="map-box">
              <ul>
                <li><a href="">Go Shopping</a></li>
                <li><a href="">Buying Service</a></li>
                <li><a href="">Deals & Coupons</a></li>
              </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="map-head">
            <h3>About Us</h3>
          </div>
          <div class="map-box">
              <ul>
                <li><a href="">About Us</a></li>
                <li><a href="">Customer Feedback</a></li>
                <li><a href="">Partner with Us</a></li>
              </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="map-head">
            <h3>Rates & Services</h3>
          </div>
          <div class="map-box">
              <ul>
                <li><a href="">Services</a></li>
                <li><a href="">Cost Calculator</a></li>
                <li><a href="">Where we ship</a></li>
              </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="map-head">
            <h3>Help</h3>
          </div>
          <div class="map-box">
              <ul>
                <li><a href="">Contact Us</a></li>
                <li><a href="">Create Support Tickets</a></li>
                <li><a href="">FAQs</a></li>
                <li><a href="">Tutorials</a></li>
                <li><a href="">View Support Tickets</a></li>
                <li><a href="">Privacy Policy</a></li>
                <li><a href="">Terms & Conditions</a></li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-got-question></app-got-question>