import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { AddressType } from '../core/Enum';
import { ConsolidationCountriesService } from '../Services/consolidation-countries.service';
import { CostCalculatorService } from '../Services/cost-calculator.service';

import { CountryService } from '../Services/country.service';
import { ServiceTypeService } from '../Services/service-type.service';
import { WarehouseService } from '../Services/warehouse.service';

export enum addressType {
  pickup = 2
}

@Component({
  selector: 'app-cost-calculator',
  templateUrl: './cost-calculator.component.html',
  styleUrls: ['./cost-calculator.component.css']
})



export class CostCalculatorComponent implements OnInit {

  
  costCalculationForm: FormGroup;
  countryList: any;
  warehouseList: any;
  calculatedCost: any;
  serviceMethodList:any;

  public loading = false;
  isServiceTypeLoading: boolean = false;
  isConsoServiceAvailable: boolean = false;
  isDirectServiceAvailable: boolean = false;
  isDirectService : boolean = false;
  isConsoService : boolean = false;
  isService : boolean = false;
  isZip : boolean = false;
  selectedCountry : number;
  franchiseeAddressList : any;
  pickupAddress : any;
  packageList : FormArray;
  isSubmitAble: boolean = false;
  constructor(private formBuilder: FormBuilder, private countryService: CountryService, private warehouseService: WarehouseService, private serviceTypeService: ServiceTypeService, private changeDetect: ChangeDetectorRef, private costCalculatorService: CostCalculatorService, private consolidationService : ConsolidationCountriesService, private meta: Meta) { 
    this.meta.updateTag({ name: 'keywords', content: 'Cost Calculator, Cost Calculate Shipping Rates, Calculate Shipping Rates, Quick Charges Calculator, Shipping Rates Calculator, Shipping Destination, Free Cost Calculator, Calculate Courier Charges, eCommerce Shipping Charges.'},'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'Calculate the estimated cost of delivering international shipping parcels across the world. Get professional shipping charges (per KG) for delivering goods to your customers.'},'name="description"');
  }

  ngOnInit(): void {
    this.loading = true;
    this.warehouseService.getWarehouseList().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.warehouseList = response["result"];
        setTimeout(() => { this.loading = false }, 2000)
      }
    }, (error: any) => {
      this.loading = false;
      console.error(error);
    })

    this.countryService.getCountries().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.countryList = response["result"];                
      }
    }, (error: any) => {
      this.loading = false;
    })

    this.costCalculationForm = this.formBuilder.group({
      wareHouseId: ['', [Validators.required]],
      countryId: ['', [Validators.required]],
      serviceType: ["", [Validators.required]],
      //directserviceType:["",[Validators.required]],
      CourierServiceId:[""],
      deliveryZipCode: [''],
      shippingWeightType: ['Kgs'],
      shippingDimensionType: ['Cms'],
      deliveryLocation : ['homeDelivery'],
      packages: this.formBuilder.array([]),
    });

    this.rows().push(this.newRow());   

    this.packageList = this.costCalculationForm.get('packages') as FormArray;

    this.costCalculationForm.statusChanges.subscribe(res=>{
      if(this.packageList.length > 1){
        this.isSubmitAble = true;
      }

      if(this.packageList.length == 1 && this.packageList.valid && this.costCalculationForm.valid){
        this.isSubmitAble = true;
      }

      if(this.packageList.length == 1 && this.packageList.invalid && this.costCalculationForm.invalid){
        this.isSubmitAble = false;
      }


      if(this.packageList.length > 1){
        this.packageList.controls.forEach((element, index) => {
          if(index > 0 && element.invalid){
            this.isSubmitAble = false;
          }
        });
      }

    });

  }

  rows(): FormArray {
    return this.costCalculationForm.get('packages') as FormArray;
  }

  newRow(): FormGroup {
    return this.formBuilder.group({
      length: ['', [Validators.required]],
      width: ['', [Validators.required]],
      height: ['', [Validators.required]],
      weight: ['', [Validators.required]]
    })
  }

  addRow() {
    this.rows().markAllAsTouched();
    if (this.rows().valid) {
      this.rows().insert(0,this.newRow());
    }
    if(this.packageList.length > 1){
      this.isSubmitAble = true;
    }
  }

  removeRow(index: number) {
    this.rows().removeAt(index);
  }

  countryChange(countryId: any) {
    this.isServiceTypeLoading = true;
    this.isConsoServiceAvailable = false;
    this.isDirectServiceAvailable = false;

    if (countryId != "") {
      this.selectedCountry = countryId;
      this.getFranchiseDetail();
      this.serviceTypeService.getServiceTypes(countryId).subscribe((response: any) => {
        if (response["statusCode"] == 200 && response["result"] != null) {          
          
          this.isConsoServiceAvailable = response["result"]["isConsoServiceAvailable"];
          this.isDirectServiceAvailable = response["result"]["isDirectServiceAvailable"];
          this.isServiceTypeLoading = false;
        }
      }, (error) => {
        this.isServiceTypeLoading = false;
        console.error(error);
        
      })
    }
    this.costCalculationForm.controls["serviceType"].markAsUntouched();
    this.costCalculationForm.controls["serviceType"].markAsPristine();
    this.costCalculationForm.patchValue({
      serviceType: ""
    })

  }
  warehouseChange(wareHouseId:any){
    if (wareHouseId != "") {
      
      this.warehouseService.getCourierServices(wareHouseId).subscribe((response: any) => {
        if (response["statusCode"] == 200 && response["result"] != null) {          
          this.serviceMethodList = response["result"];
          setTimeout(() => { this.loading = false }, 2000)
          this.isServiceTypeLoading = false;
        }
      }, (error) => {
        this.isServiceTypeLoading = false;
        console.error(error);
        
      })
    }
  }

  serviceChange(serviceId : any){
    if(serviceId > 0){
      this.isService = true;
      if(serviceId == 2){
        this.isZip = true;
        this.isConsoService = false;
        this.isDirectService = true;
        this.costCalculationForm.patchValue({
          deliveryLocation :'homeDelivery',
        })
      }
      if(serviceId == 1){
        this.isZip = false;
        this.isDirectService = false;
        this.isConsoService = true;
        this.costCalculationForm.patchValue({
          deliveryLocation :'pickUp',
        })
      }
    }else{
      this.isService = false;
    }
  }

  optionChange(val: any){
    if(val == 'homeDelivery'){
      this.isZip = true;
    }else{
      this.isZip = false;
    }
    if(!this.isZip && this.isConsoService){

    }
    
  }

  getFranchiseDetail(){
      this.consolidationService.GetFranchiseeByCountryId(this.selectedCountry).subscribe((response : any)=>{
        if(response["statusCode"]==200 && response["result"] != null){
          if(response["result"][0]?.franchiseDetails != null && response["result"][0].franchiseDetails.length > 0){
            this.franchiseeAddressList = response["result"][0].franchiseDetails;
            
            this.franchiseeAddressList.forEach(address => {
                if(address.addressType == addressType.pickup){
                  this.pickupAddress = address;                
                }
            });
            
            
          }
          
        } 
      });
  }

  onSubmit(data: any) {

    if(this.packageList.length > 1 && this.packageList.controls[0].invalid){
      this.packageList.removeAt(0);
      data["packages"] = this.packageList.value;
    }

    this.loading = true;
    this.costCalculationForm.markAllAsTouched();
    if (this.costCalculationForm.valid) {
      
      this.costCalculatorService.calculateShippingCost(data).subscribe((response: any) => {
        if (response["statusCode"] == 200 && response["result"] != null) {
          this.calculatedCost = response["result"];
          
              
          setTimeout(() => { this.loading = false }, 2000);
        }
      }, (error) => {
        this.loading = false;
        console.error(error);

      })
    } else {
      this.loading = false;
    }
  }
}