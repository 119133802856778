<footer>
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-4">
                <div class="footer-head">
                    <h6>BECOME A FRANCHISE OWNER</h6>
                </div>
                <div class="footer-body">
                    <p>Kango Express is looking for franchise partners to open local offices and manage consolidation service shipments into their country. International online shopping is expected to continue to grow, and affordable shipping services like
                        Kango Express fulfill a growing need of these consumers. Kango Express provides you with all the tools and support you need to have a successful local operation.</p>
                    <a class="btn btn-lg" [routerLink]="['/about-us']">More About Franchising</a>
                </div>
            </div> -->
            <div class="col-lg-6">
                <div class="footer-head">
                    <h6>More from Us</h6>
                </div>
                <div class="footer-body">
                    <div class="d-flex">
                        <ul>
                            <li><a href="">Home</a></li>
                            <li><a href="" [routerLink]="['/about-us']">About us</a></li>
                            <li><a [routerLink]="['/direct-shipment']">Direct Service</a></li>
                            <li><a [routerLink]="['/where-we-ship']">Consolidation Service</a></li>                        
                        </ul>
                        <ul>
                            <li><a href="" [routerLink]="['/faqs']">FAQs</a></li>
                            <li><a [routerLink]="['/tutorials']">Tutorials</a></li>
                            <li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
                            <li><a [routerLink]="['/terms-conditions']">Terms & Conditions</a></li>                            
                        </ul>
                        <ul>
                            <li><a [routerLink]="['/terms-conditions']" [fragment]="'Claim-Policy'">Claim Policy</a></li>
                            <li><a [routerLink]="['/customer-feedback']">Customer Feedback</a></li>
                            <li><a [routerLink]="['/contact-us']">Customer Support</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="footer-head">
                    <h6>Payment Gateway</h6>
                </div>
                <div class="footer-body">
                    <div class="footer-payment-card">
                        <img src="./assets/images/payment1.png">
                    </div>
                    
                    <!-- <ul>
            <li><a href=""><img src="images/Payment1.png"></a></li>
            <li><a href=""><img src="images/Payment2.png"></a></li>
          </ul> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="uv-footer-con">
                    <div class="uv-footer-copy">
                        <p>© {{currentDate | date: 'yyyy'}} Kango Express. All rights reserved.</p>
                    </div>
                    <div class="uv-footer-media">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/kangoexpress" target="_blank"><img src="./assets/images/fb.png" alt="fb" /></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/kangoexpressofficial/" target="_blank"><img src="./assets/images/instagram.png" alt="fb" /></a>
                            </li>
                            <li>
                                <a href="https://www.pinterest.com" target="_blank"><img src="./assets/images/pt.png" alt="pt" /></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/kango_express" target="_blank"><img src="./assets/images/tw.png" alt="tw" /></a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UCgUJpSHxzVnukpFkeHcC3ag" target="_blank"><img src="./assets/images/ty.png" alt="ty" /></a>
                            </li>
                            <li>
                                <a href="https://myaccount.google.com/intro/profile" target="_blank"><img src="./assets/images/gp.png" alt="gp" /></a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/kango-express" target="_blank"><img src="./assets/images/in.png" alt="in" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>