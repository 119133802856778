import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FaqsService } from '../Services/faqs.service';
import { SitePagesService } from '../Services/site-pages.service';
declare var $: any;

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {

  categoryList: any;
  faqList: any = [];
  categoryName: any;
  public loading = false;
  currentFragment:any;

  pageContent: any;
  pageName: string = "FAQs";

  constructor(private faqsService: FaqsService, private activatedRoute: ActivatedRoute, private meta : Meta, private sitePagesService: SitePagesService) {
    this.meta.updateTag({ name: 'keywords', content: 'FAQ, Flick Ask Questions, Cost Calculator, Cost Calculate Shipping Rates, Calculate Shipping Rates, courier charges, quick courier charges calculator, Shipping Rates Calculator, Shipping Destination, Free Cost Calculator, Calculate Courier Charges, eCommerce Shipping Charges.'},'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'Frequently Asked Questions (FAQs) about shipping necessary and unnecessary pending the COVID Crisis. Answers to your all queries about Shipping Services.'},'name="description"'); 
  }

  ngOnInit(): void {

    this.activatedRoute.fragment.subscribe(fragment => {
      this.currentFragment = fragment //store somewhere
    });

    
    
    this.getSitePage();
    this.getCategoriesWithFaq();
  }

  getCategoriesWithFaq() {
    this.loading = true;
    this.faqsService.getCategoriesWithFaq().subscribe((response: any) => {
      if (response["statusCode"] == 200) {
        this.categoryList = response["result"];
        this.getAllFaqs();
        $.getScript('./assets/js/scripts.js');
        setTimeout(()=>{this.loading = false}, 2000);        
      }
    }, (error: any)=>{
        this.loading = false;        
    })
  }

  getAllFaqs() {    
    this.categoryList.forEach(category => {
      if (category.faqs.length > 0) {
        category.faqs.forEach(faq => {
          this.faqList.push(faq);
        });

        let uniqList: any = [];
        this.faqList.forEach(item => {
          if (uniqList.filter(x => { return x.title == item.title }).length == 0) {
            uniqList.push(item);
          }
        });
        this.faqList = uniqList;
        
      }
    });
    
  }

  getFaqsByCategory(category: any) {
    this.categoryName = category.categoryName;
    this.faqList = category["faqs"];
  }

  getSitePage() {
    this.sitePagesService.getSitePage(this.pageName).subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.pageContent = response["result"];
      }
    }, (error) => {
      console.error(error);
    });
  }
}