
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppSettings } from '../Shared/App-Settings';

const apiLisUrl = AppSettings.API_BASE_URL + "api/";

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public readonly responses: Subject<any> = new Subject<any>();
  
  categoryList: any = new Array();
  faqList: any = new Array();

  answer = "";
  continue = false;
  clear = false;
  filteredList = new Array();

  constructor(private http: HttpClient) {
    this.getCategoryList();
  }

  message: any = {
    author: {
      id: 0
    },
    suggestedActions: [
      {
        type: "reply",
        value: "Request Notes1"
      },
      {
        type: "reply",
        value: "Raise a Query"
      }
    ],
    timestamp: new Date(),
    text: "How can i help you today ?"
  };

  public submit(message: string): void {
    this.clear = false;
      this.message = {
        author: {
          id: 0
        },
        suggestedActions: this.setResponse(message),
        timestamp: new Date(),
        text: this.answer,
        continue: this.continue,
        clear : this.clear
      }
      setTimeout(() => { this.responses.next(this.message) }, 1000);
  }

  getCategoryList() {
    
    let url = `${apiLisUrl}CategoryCollectionWithFaqs?IsActive=true&Type=1&Faqfor=Website`;
    this.http.get(url).subscribe((response: any) => {
      if (response["statusCode"] == 200) {
        response["result"].filter((category: any) => {
          this.categoryList.push({ "type": "reply", value: category.categoryName, id: category.categoryId, faqs: category.faqs });
          if (category.faqs.length > 0) {
            category.faqs.forEach(faq => {
              this.faqList.push({ "type": "replay", value: faq.title, id: faq.faqId, description: faq.description });
            });
          }
        });
      }
    })
  }

  setResponse(message: string) {

    this.answer = "";
    let list = new Array();
    
    if (message.toLocaleLowerCase() == "topic selection" && this.continue) {
      this.answer = "";
      this.continue = false;
      return this.categoryList;
    }

    if (message.toLocaleLowerCase() == "yes" && this.continue) {
      this.answer = "Glad we could help you out right away. If you have any further questions, please do not hesitate to contact us. See you soon!";
      this.clear = true;
      this.continue = false;
      return [];
    }

    if (message.toLocaleLowerCase() == "no" && this.continue) {
      this.answer = "if you are registered user of kango,you can create support request. you can find this option in your <a href='https://customer.kangoexpress.com' target='_blank'><u>Customer panel</u></a> > Customer Support Request > Create. Thank you for understanding.";
      this.continue = false;
      return '';
    }

    //compare to categories
    this.categoryList.filter((item: any) => {
      if (item.value.toLocaleLowerCase() == message.toLocaleLowerCase()) {
        if (item.faqs.length > 0) {
          list = [];
          item.faqs.forEach(faq => {
            list.push({ "type": "reply", value: faq.title, id: faq.faqId, description: faq.description });
          });
        }
      }
    });

    //return faq list if it have
    if (list.length > 0) {
      this.answer = "";
      this.continue = false;
      return list;

    } else {
      //compare to faq list direct
      this.faqList.filter((faq: any) => {
        if (faq.value.toLocaleLowerCase() == message.toLocaleLowerCase()) {
          this.answer = faq.description;
          this.continue = true;
          list = [{ "type": "reply", value: "yes" }, { "type": "reply", value: "no" }, { "type": "reply", value: "Topic Selection" }];
        }
      })
      
      if (this.answer == "") {
        let regex = new RegExp(message.toLocaleLowerCase().split(" ").join('|'));
        this.answer = "Related Questions";
        this.continue = false;
        this.faqList.forEach(question => {
          if (question.value.toLocaleLowerCase().match(regex)) {
            list.push(question);
          }
        });
        if (list.length == 0 && this.filteredList.length == 0) {
          this.answer = "How can i help you?";
          this.continue = false;
        }
      }

      //remove duplicate items from list
      let uniqList = [];
      list.forEach(item => {
        if (uniqList.filter(x => { return x.value == item.value }).length == 0) {
          uniqList.push(item);
        }
      });
      
      if (uniqList.length > 5) {
        this.filteredList = uniqList;
        uniqList = uniqList.slice(0, 4);
        uniqList.push({ "type": "replay", value: "Read More" });
      }

      if (this.filteredList.length > 5 && message.toLocaleLowerCase() == "read more") {
        this.answer = "";
        return this.filteredList;
      }

      return uniqList;
    }
  }
}