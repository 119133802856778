import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ServiceRatesService } from '../Services/service-rates.service';
import { SitePagesService } from '../Services/site-pages.service';
declare var $ : any;
@Component({
  selector: 'app-service-rates',
  templateUrl: './service-rates.component.html',
  styleUrls: ['./service-rates.component.css']
})

export class ServiceRatesComponent implements OnInit {

  serviceRatesList: any;
  public loading = false;
  pageContent: any;
  pageName: string = "ServicesFees";

  constructor(private serviceRatesService: ServiceRatesService, private sitePagesService: SitePagesService, private meta : Meta) { 
    this.meta.updateTag({ name: 'keywords', content: 'Fess and Services, Courier charges, delivery charges, cheapest courier rates, fred forwarding fees, fred forwarding services'},'name="keywords"');
    this.meta.updateTag({ name: 'description', content: 'Kango Express is committed to not having any hidden fees for our customers. Listed below are the standard services we offer as well as the associated costs.'},'name="description"'); 
  }

  ngOnInit(): void {
    this.getServiceRates();
    this.getSitePage();
  }
  
  getServiceRates() {
    this.loading = true;
    this.serviceRatesService.getServiceRates().subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"]) {
        this.serviceRatesList = response["result"];
        //this.serviceRatesList.sort((a,b)=> (a.createdDate > b.createdDate) ? 1: ((b.createdDate > a.createdDate) ? -1 : 0));
        this.serviceRatesList.sort((a,b)=> (a.rowOrder > b.rowOrder) ? 1: ((b.rowOrder > a.rowOrder) ? -1 : 0));
        
        
        $.getScript('./assets/js/scripts.js');
        
        setTimeout(() => { this.loading = false }, 2000)
      }
    }, (error) => {
      this.loading = false;
      console.error(error);
    });
  }


  getSitePage() {
    this.loading = true;
    this.sitePagesService.getSitePage(this.pageName).subscribe((response: any) => {
      if (response["statusCode"] == 200 && response["result"] != null) {
        this.pageContent = response["result"];
        //this.setMetaTag();        
        setTimeout(() => { this.loading = false }, 2000)
      }
    }, (error) => {
      this.loading = false;
      console.error(error);

    });
  }
}
