<div *ngIf="loading" class="wrapperLoader">
    <div class="loader">
    </div>
</div>

<section class="kg-header-block"
    style="background-image: url(./assets/images/prohibited-items-header-bg.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-header-block-text">
                    <h1>{{selectedFranchisee}}</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/where-we-ship']">Consolidation Countries</a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">{{selectedFranchisee}}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="kg-heading-title">
                    <h1>{{franchise.franchiseNam}}</h1>
                    
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-xs-12 col-lg-12 col-md-12 ">
                <div class="country-tab-section">
                    <!-- <div class="row">
                        <div class="col-xl-2 col-md-3 cc-main" *ngFor="let franchise1 of franchiseeList; let i=index" (click)="getFranchiseDetail(franchise1.franchiseId)">
                            <div class="cc-boxs">
                                <img src="{{franchise1.flagUrl}}" alt=""/>
                                <p> {{franchise1.franchiseName}} </p>
                            </div>
                        </div>                    
                    </div> -->

                    <!-- <div [ngClass]="{cntryScroll: isScroll}">
                        <nav>
                            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a *ngFor="let franchise of franchiseeList; let i=index" [ngClass]="{active: i==0}"
                                    (click)="getFranchiseDetail(franchise.franchiseId)"
                                    class="nav-item nav-link countryTabs" data-toggle="tab"
                                    href="#nav-{{franchise.franchiseId}}" role="tab"
                                    aria-controls="nav-{{franchise.franchiseId}}" aria-selected="false">
                                    <img src="{{franchise.flagUrl}}" class="flag"><br>
                                    <span> {{franchise.franchiseName}}</span>
                                </a>
                            </div>
                        </nav>
                    </div> -->
                    <!-- <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true"> <img src="images/county/azerbaijan.png" class="flag"><br><span> Azerbaijan</span></a>
                    <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false"><img src="images/county/cambodia.png" class="flag"><br><span> Cambodia</span></a>
                    <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false"><img src="images/county/kenya.png" class="flag"><br><span> Kenya</span></a>
                    <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false"><img src="images/county/malaysia.png" class="flag"><br><span> Malaysia</span></a>
                     <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false"><img src="images/county/mongolia.png" class="flag"><br><span> Mongolia</span></a>
                    <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false"><img src="images/county/philippines.png" class="flag"><br><span> Philippines</span></a>
                    <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false"><img src="images/county/thailand.png" class="flag"><br><span> Thailand</span></a>
                    <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false"><img src="images/county/thailand.png" class="flag"><br><span> Thailand</span></a>                     -->

                    <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-{{franchise?.country?.countryName}}"
                            role="tabpanel" aria-labelledby="nav-home-tab">
                            <div class="tab-body-section">
                                <div class="coun-flag-list">
                                    <div>
                                        <h3>Ship your packages from the U.S. to {{franchise?.country?.countryName}}</h3>
                                        <div [innerHtml]="franchise?.country?.welcomeTitle"></div>
                                    </div>
                                    <div class="coun-flag-img">
                                        <img src="assets/images/{{selectedFranchisee}}-flag.png" class="" />
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 px-0">
                                    <div class="vertical-tabs">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12">
                                                <ul class="nav nav-tabs" role="tablist">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" data-toggle="tab" href="#overview-v"
                                                            role="tab" aria-controls="overview">Overview</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" data-toggle="tab" href="#transit-v"
                                                            role="tab" aria-controls="transit">Transit Times</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" data-toggle="tab" href="#shipmentSchedule-v"
                                                            role="tab" aria-controls="shipmentSchedule">Shipment
                                                            Schedule</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" data-toggle="tab" href="#duty-v" role="tab"
                                                            aria-controls="duty">Duty/Tax
                                                            Info</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" data-toggle="tab" href="#delivery-v"
                                                            role="tab" aria-controls="delivery">Delivery Info</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" data-toggle="tab" href="#payment-v"
                                                            role="tab" aria-controls="payment">Payment
                                                            Info</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-md-9 col-sm-12">
                                                <div class="tab-content">

                                                    <div class="tab-pane active" id="overview-v" role="tabpanel">
                                                        <div class="sv-tab-panel"
                                                            [innerHtml]="franchise?.country?.generalOverViewStmt">
                                                        </div>
                                                    </div>

                                                    <div class="tab-pane" id="transit-v" role="tabpanel">
                                                        <div class="sv-tab-panel"
                                                            [innerHtml]="franchise?.country?.transitTimeDesc"></div>
                                                    </div>

                                                    <div class="tab-pane" id="shipmentSchedule-v" role="tabpanel">
                                                        <div class="sv-tab-panel"
                                                            [innerHtml]="franchise?.shipmentScheduleDesc"></div>
                                                    </div>

                                                    <div class="tab-pane" id="duty-v" role="tabpanel">
                                                        <div class="sv-tab-panel"
                                                            [innerHtml]="franchise?.country?.customsDutyInfo"></div>
                                                    </div>

                                                    <div class="tab-pane" id="delivery-v" role="tabpanel">
                                                        <div class="sv-tab-panel"
                                                            [innerHtml]="franchise?.deliveryInfo | htmldecoder"></div>
                                                    </div>

                                                    <div class="tab-pane" id="payment-v" role="tabpanel">
                                                        <div class="sv-tab-panel"
                                                            [innerHtml]="franchise?.countryPaymentInfo | htmldecoder">
                                                            <!-- <ul>
                                                                <li>Cash (local currency paid in office)</li>
                                                                <li>PayPal (via Kango Express website)</li>
                                                                <li>Credit Card (via Kango Express website)</li>
                                                            </ul> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-xs-12 col-lg-12 col-md-12 ">
                <div class="country-tab-section">
                    <div class="cntry-scroll">
                    <nav>
                        <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                            
                            <a *ngFor="let franchise of franchiseeList; let i=index" [ngClass]="{active: i==0}" (click)="getFranchiseDetail(franchise.franchiseId)" class="nav-item nav-link countryTabs" data-toggle="tab" href="#nav-{{franchise.franchiseId}}" role="tab" aria-controls="nav-{{franchise.franchiseId}}"
                                aria-selected="false">
                                <img src="{{franchise.flagUrl}}" class="flag">
                                <span> {{franchise.franchiseName}}</span>
                            </a>
                           
                        </div>
                    </nav>
                </div>
                
                    <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-{{franchise?.country?.countryName}}" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div class="tab-body-section">
                                <h3>Ship your packages from the U.S. to {{franchise?.country?.countryName}}</h3>
                                <div [innerHtml]="franchise?.country?.welcomeTitle"></div>
                                <div class="col-md-12 col-sm-12 px-0">
                                    <div class="vertical-tabs">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12">
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" data-toggle="tab" href="#overview-v" role="tab" aria-controls="overview">Overview</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" data-toggle="tab" href="#transit-v" role="tab" aria-controls="transit">Transit</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" data-toggle="tab" href="#shipmentSchedule-v" role="tab" aria-controls="shipmentSchedule">Shipment Schedule</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" data-toggle="tab" href="#duty-v" role="tab" aria-controls="duty">Duty/Tax
                          Info</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" data-toggle="tab" href="#delivery-v" role="tab" aria-controls="delivery">Delivery Info</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" data-toggle="tab" href="#payment-v" role="tab" aria-controls="payment">Payment
                          Info</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-9 col-sm-12">
                                        <div class="tab-content">

                                            <div class="tab-pane active" id="overview-v" role="tabpanel">
                                                <div class="sv-tab-panel" [innerHtml]="franchise?.country?.generalOverViewStmt">
                                                </div>
                                            </div>

                                            <div class="tab-pane" id="transit-v" role="tabpanel">
                                                <div class="sv-tab-panel" [innerHtml]="franchise?.country?.transitTimeDesc"></div>
                                            </div>

                                            <div class="tab-pane" id="shipmentSchedule-v" role="tabpanel">
                                                <div class="sv-tab-panel" [innerHtml]="franchise?.shipmentScheduleDesc"></div>
                                            </div>

                                            <div class="tab-pane" id="duty-v" role="tabpanel">
                                                <div class="sv-tab-panel" [innerHtml]="franchise?.country?.customsDutyInfo"></div>
                                            </div>

                                            <div class="tab-pane" id="delivery-v" role="tabpanel">
                                                <div class="sv-tab-panel" [innerHtml]="franchise?.deliveryInfo"></div>
                                            </div>

                                            <div class="tab-pane" id="payment-v" role="tabpanel">
                                                <div class="sv-tab-panel" [innerHtml]="franchise?.generalOverViewStmt"></div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>

<section class="section-space bg-white">
    <div class="container">
        <div class="rate-section">
            <div class="row">
                <div class="col-md-5">
                    <div class="form-group">
                        <label>Select Warehouse</label>
                        <select class="form-control" #selectedWh (change)="warehouseChange(selectedWh.value)">
                            <option *ngFor="let warehouse of warehouseList" value="{{warehouse.warehouseId}}">
                                {{warehouse.warehouseName}}</option>
                        </select>
                    </div>
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th>Total Chargeable Weight (kg)</th>
                                <th>Rate in $USD*</th>
                            </tr>
                        </thead>
                        <tbody>

                            <ng-container *ngFor="let shippingRate of franchise?.country?.countryRates">
                                <tr *ngIf="shippingRate.warehouseId == selectedWh.value">
                                    <td>{{shippingRate.fromInfo}} - {{shippingRate.toInfo || 'Above'}}</td>
                                    <td>{{shippingRate.rate | currency}}
                                        <ng-container
                                            *ngIf="shippingRate.toInfo <= 1.00 && shippingRate.toInfo != null">
                                            Flat
                                        </ng-container>
                                        <ng-container *ngIf="shippingRate.fromInfo >= 1.00">
                                            Per Kilo
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-7">
                    <h4>Shipping Rates</h4>
                    <p>Kango Express {{franchise?.country?.countryName}} Consolidation Rates</p>
                    <h6 *ngIf="isDirectAvailable">For Direct Service Rates, please use our<a [routerLink]="['/cost-calculator']"><span
                                class="orange"> Cost Calculator.</span></a></h6>
                    <h6>Here are our consolidation service rates. You may also use the <a
                            [routerLink]="['/cost-calculator']"><span class="orange"> Cost Calculator</span></a> to
                        estimate the cost of your shipment.</h6>
                    <div class="spl-notes">
                        <h5>Special Notes*</h5>
                        <div [innerHtml]="franchise?.country?.specialNotes">
                            <ul>
                                <li>
                                    <div class="warp"> <i class="fa fa-caret-right"></i></div>
                                    <p>Packages with any dimension less than 50 cm will be billed on ACTUAL weight only.
                                        Packages with any dimension over 50 cm will still be billed on volume
                                        weight.<br>
                                        <strong> Please refer to <span class="orange"> our FAQ article </span> for more
                                            information.</strong>
                                    </p>
                                </li>
                                <li>
                                    <div class="warp"> <i class="fa fa-caret-right"></i></div>
                                    <p>These rates are for pick up at our local Baku office. If you require delivery to
                                        your home or office, please contact our local office for rates and service.</p>
                                </li>
                                <li>
                                    <div class="warp"> <i class="fa fa-caret-right"></i></div>
                                    <p>Please contact our local Baku, Azerbaijan office via our Help Desk or (994) - 490
                                        0863 or 489 0519, 489 0521 Help Desk for rates and information on the UK
                                        consolidation service.</p>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="section-space bg-sky important-service">
    <div class="container">
        <div class="row my-auto">
            <div class="col-md-4 ">
                <div class="kg-restricted-img">
                    <img src="./assets/images/announcements.png" alt="">
                </div>
            </div>
            <div class="col-md-8">
                <div class="announcements-body">
                    <p>Please be advised that 07 Jan 2021 consolidation has been shipped actually. Shipping invoices will be generated later due to technical updates.</p>
                    <p>For the fastest and most efficient service, please begin using our new warehouse address immediately.20 Shea Way Suite 205, KX *(your kango account no.) Newark, 19713-3447 Ph: 302-415-4144</p>
                    
                    
                    <p>Due to the busy Holiday Season, coupled with statewide Covid restrictions in our warehouse, there have been delays in processing packages. We are processing packages as quickly as possible, given the current conditions.
                    </p>                    
                </div>
            </div>
        </div>
    </div>
</section> -->

<app-got-question></app-got-question>

<section class="section-space bg-white contact-deatil">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="">
                    <ul>
                        <li>
                            <div class="warp">
                                <i class="fa fa-map-marker"></i>
                            </div>
                            <p>
                                {{franchiseContactDetails?.name}}<br>
                                {{franchiseContactDetails?.address1}}<br>
                                {{franchiseContactDetails?.address2}}
                                {{franchiseContactDetails?.city}}
                                {{franchiseContactDetails?.zipCode}}

                            </p>
                        </li>
                        <li>
                            <div class="warp">
                                <i class="fa fa-tty"></i>
                            </div>
                            <p>
                                {{franchiseContactDetails?.country}} ({{franchise?.country?.phoneCode}})-
                                <ng-container *ngIf="franchiseContactDetails?.phone1 != null">
                                    {{franchiseContactDetails?.phone1}}
                                </ng-container>
                                <ng-container *ngIf="franchiseContactDetails?.phone2 != null">
                                    or <br> {{franchiseContactDetails?.phone2}}
                                </ng-container>
                                <ng-container *ngIf="franchiseContactDetails?.phone3 != null">
                                    , {{franchiseContactDetails?.phone3}}
                                </ng-container>
                            </p>
                        </li>
                        <li>
                            <div class="warp">
                                <i class="fa fa-print"></i>
                            </div>
                            <p>{{franchiseContactDetails?.country}}({{franchise?.country?.phoneCode}}) -
                                {{franchiseContactDetails?.fax1}}</p>
                        </li>
                        <li>
                            <div class="warp">
                                <i class="fa fa-phone"></i>
                            </div>
                            <p>{{franchiseContactDetails?.country}} ({{franchise?.country?.phoneCode}})-
                                <ng-container *ngIf="franchiseContactDetails?.mobile1 != null">
                                    {{franchiseContactDetails?.mobile1}}
                                </ng-container>
                                <ng-container *ngIf="franchiseContactDetails?.mobile2 != null">
                                    or <br> {{franchiseContactDetails?.mobile2}},
                                </ng-container>
                                <ng-container *ngIf="franchiseContactDetails?.mobile3 != null">
                                    , {{franchiseContactDetails?.mobile3}}
                                </ng-container>
                            </p>
                        </li>
                    </ul>

                    <h6>Local Office Hours</h6>
                    <div class="office-hrs">
                        <ul>
                            <li><span>Monday-Thursday</span><span>{{franchise?.monThuHrs}}</span></li>
                            <li><span>Friday</span><span>{{franchise?.fridayHrs}}</span></li>
                            <li><span>Saturday</span><span>{{franchise?.saturdayHrs}}</span></li>
                            <li><span>Sunday</span><span>{{franchise?.sundayHrs}}</span></li>
                        </ul>
                    </div>

                    <h6 *ngIf="franchise?.customerEndPoint != ''">Customer Endpoint</h6>
                    <div [innerHtml]="franchise?.customerEndPoint"></div>

                    <h6
                        *ngIf="(franchise?.facebook != '' && franchise?.facebook != null) || (franchise?.twitter != '' && franchise?.twitter != null) || (franchise?.instagram != '' && franchise?.instagram != null)">
                        Social Media</h6>
                    <div class="uv-footer-media">
                        <ul>
                            <li *ngIf="franchise?.facebook != '' && franchise?.facebook != null">
                                <a [attr.href]="'//'+franchise?.facebook" target="_blank"><img
                                        src="./assets/images/fb.png" alt="fb" /></a>
                            </li>
                            <li *ngIf="franchise?.twitter != '' && franchise?.twitter != null">
                                <a [attr.href]="'//'+franchise?.twitter" target="_blank"><img
                                        src="./assets/images/tw.png" alt="tw" /></a>
                            </li>
                            <li *ngIf="franchise?.instagram != '' && franchise?.instagram != null">
                                <a [attr.href]="'//'+franchise?.instagram" target="_blank"><img
                                        src="./assets/images/instagram.png" alt="fb" /></a>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>
            <div class="col-lg-9">
                <div class="consolidation-shipment-contact">
                    <h3>Kango Office in {{franchise?.country?.countryName}}</h3>
                    <p>You can reach us on following given information</p>
                </div>
                <div style="width: 100%" class="ch-map" [innerHtml]="franchise?.mapAddress | safeHtml">
                    <!-- <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                        [src]="franchise?.mapAddress | safe"></iframe> -->

                    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12157.115401044868!2d49.871718!3d40.380512!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x91dce9213cfbb7e3!2sKango%20Express%20Azerbaijan!5e0!3m2!1sen!2sus!4v1635412131103!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                </div>
            </div>
        </div>
    </div>
</section>