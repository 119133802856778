<div *ngIf="loading" class="wrapperLoader">
    <div class="loader">
    </div>
</div>
<section class="kg-header-block" style="background-image: url(./assets/images/banner-3.jpg);background-repeat: no-repeat;background-size: cover;background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="kg-header-block-text">
                    <h1>SERVICES & FEES</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Services & Fees</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12" [innerHtml]="pageContent?.pageContent | safeHtml">
                <div class="kg-heading-title">
                    <h1>Services & Fees</h1>
                    <!-- <p>Lorem Ipsum is simply dummy text</p> -->
                    <div class="kg-pre">
                        <p>Kango Express is committed to not having any hidden fees for our customers. Listed below are the standard services we offer as well as the associated costs. For additional information, please review our <a [routerLink]="['/terms-conditions']">Terms & Conditions</a>                            , <a [routerLink]="['/faqs']">FAQ’s</a> and our <a [routerLink]="['/cost-calculator']">Cost
                                Calculator</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="kg-services-fees">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <!-- <div class="col-md-12" [innerHtml]="pageContent?.pageContent"> -->
                <div class="table-responsive">
                    <div class="tbl-header">
                        <table cellpadding="0" cellspacing="0" border="0">
                            <thead>
                                <tr>
                                    <th>Service Type</th>
                                    <th>Charges/Fees</th>
                                    <th>Additional Information Links</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="tbl-content">
                        <table cellpadding="0" cellspacing="0" border="0">
                            <tbody>
                                <tr *ngFor="let serviceRate of serviceRatesList">
                                    
                                    <td>{{serviceRate.serviceType}}</td>
                                    <td>
                                        {{serviceRate.chargesFees}} 
                                        <a href="javascript:void(0)" *ngIf="serviceRate.textForQuestionMark != null" data-toggle="tooltip" data-placement="top" [title]="serviceRate?.textForQuestionMark">
                                            <i class="fa fa-question-circle"></i>
                                        </a>
                                    </td>
                                    <td><a target="_blank" [attr.href]="'//'+serviceRate.additionalInformationLinks">{{serviceRate.additionalInformationLinkText}}</a>
                                    <!-- <td><a target="_blank" [attr.href]="serviceRate.additionalInformationLinks">{{serviceRate.additionalInformationLinkText}}</a> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
